import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import MongoDB from '../../homepage/header-banner/MongoDB';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import { Grid } from '@mui/material';
import CardBox from '../../container/Box/Components/CardBox';

function Clustercloudmongodb(){

    return (
        <div className='App'>
            <NavButton />
            {Banner("HANBIRO CLOUD","Kubernetes MongoDB")}
            <Container className='main-body'>
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Kubernetes for MongoDB")}
                        {Contentcloudcard("모든 컨테이너(POD) 고용량 SSD 디스크 제공, 고용량의 메모리 할당")}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>{CardBox(
                                "쿠버네티스 클러스터 기반의 Replica를 지원하는 NoSQL 데이터베이스 엔진", 
                                `Replica SET 에 데이터를 분산해서 저장하는 Sharding 을 지원합니다. <br> 
                                비교적 쉽고 간단하게 운영할 수 있으며, 대용량의 처리량을 요구하는 서비스에 적합니다. <br>
                                일반적인 RDBMS 와 비교시 월등한 퍼포먼스를 구현할 수 있습니다.`
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>{CardBox(
                                "고성능의 Persistant Volume 운영, I/O 최적의 성능구현",
                                `SSD 기반의 PV 볼륨으로 운영되고 있으며, 3개의 replica 컨테이너(POD)가 하나의 서비스단위입니다. <br>
                                쿠버네티스 워커노드의 하드웨어 장애시에도 타 워커노드의 replica 컨테이너(POD)에서 서비스가 진행됩니다.`
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>{CardBox(
                                "하드웨어장애시 타 워커노드에 컨테이너(POD) 자동으로 재배치 무정지시스템구현",
                                `Kubernetes MongoDB 컨테이너(POD)는 단위서비스별 3개의 replica 노드로 구성되어 있습니다. <br> 
                                각각의 replica 컨테이너(POD)는 쿠버네티스의 다수의 워커노드에 분산배치되어 있습니다.`
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>{CardBox(
                                "자동으로 스케일아웃(Autoscaling)하여 컨테이너 확장, 서비스의 부하분산 구현",
                                `CPU 사용률, 메모리 사용률, 또는 다른 커스텀 메트릭 기준으로 컨테이너(POD) 수량을 주기적으로 조정(증감)합니다. <br>
                                서비스 확장을 위한 하드웨어 자원을 확보할 필요없이 서비스를 구동할 수 있습니다. <br>
                                서비스의 부하분산및 비용을 효율적으로 사용할 수 있는 클러스터 솔루션입니다.`
                                )}
                            </Grid>
                            <Grid item xs={12}>{Imagecloudcompute("https://www.hanbiro.com/cloud/library/cluster-cloud-mongodb/mongodb_cluster.svg")}</Grid>
                        </Grid>
                        <MongoDB />
                       
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer />
        </div>
        
    );

};

export default Clustercloudmongodb;