import React from 'react';
import '../Sidebar/sidebar.css';
import { Link } from 'react-router-dom';
import { useHistory, NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function Sidebarmobile() {
  const currentRoute = useHistory().location.pathname.toLowerCase();
  return (
    <Accordion defaultActiveKey="0" className='detailpage'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h5>Server</h5>
        </Accordion.Header>
        <Accordion.Body>
          <ul className='sidebar-mobile'>
              <li className={currentRoute.includes("compute-vps") ? "tab active" : "tab"} active><Link to="/compute-vps">가상서버 (인스턴스)</Link></li>
              {/*
              <li className={currentRoute.includes("compute-bare-metal") ? "tab active" : "tab"}><Link to="/compute-bare-metal">베어메탈 서버 (단독서버)</Link></li>
              */}
              <li className={currentRoute.includes("compute-auto-scale") ? "tab active" : "tab"}><Link to="/compute-auto-scale">오토스케일링</Link></li>
              <li className={currentRoute.includes("compute-load-balance") ? "tab active" : "tab"}><Link to="/compute-load-balance">로드발랜서</Link></li>
              <li className={currentRoute.includes("compute-database") ? "tab active" : "tab"}><Link to="/compute-database">데이터베이스 (인스턴스)</Link></li>
              <li className={currentRoute.includes("compute-ms") ? "tab active" : "tab"}><NavLink to="/compute-ms">MS-SQL / Windows</NavLink></li>
              
          </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <h5>Kubernetes Cluster</h5>
        </Accordion.Header>
        <Accordion.Body>
          <div className='left-menu-box'>
              <h6>Application</h6>
              <ul className='sidebar-mobile'>
                <li className={currentRoute.includes("cluster-cloud-elastic-search") ? "tab active" : "tab"}><Link to="/cluster-cloud-elastic-search">Elastic Search</Link></li>
                <li className={currentRoute.includes("cluster-kafka") ? "tab active" : "tab"}><Link to="/cluster-kafka">Kafka</Link></li>
                {/*<li className={currentRoute.includes("cluster-cloud-rabbitmq") ? "tab active" : "tab"}><Link to="/cluster-cloud-rabbitmq">RabbitMQ</Link></li>*/}
              </ul>
          </div>

          <div className='left-menu-box'>
            <h6>Database</h6>
            <ul className='sidebar-mobile'>
                <li className={currentRoute.includes("cluster-cloud-mysql-db") ? "tab active" : "tab"}><Link to="/cluster-cloud-mysql-db">MySQL</Link></li>
                <li className={currentRoute.includes("cluster-cloud-postgresql-db") ? "tab active" : "tab"}><Link to="/cluster-cloud-postgresql-db">PostgreSQL</Link></li>
                <li className={currentRoute.includes("cluster-cloud-vitess") ? "tab active" : "tab"}><Link to="/cluster-cloud-vitess">Vitess</Link></li>
                <li className={currentRoute.includes("cluster-cloud-mongodb") ? "tab active" : "tab"}><Link to="/cluster-cloud-mongodb">MongoDB</Link></li>
                <li className={currentRoute.includes("cluster-cloud-redis") ? "tab active" : "tab"}><Link to="/cluster-cloud-redis">Redis</Link></li>
                <li className={currentRoute.includes("cluster-cloud-scylladb") ? "tab active" : "tab"}><Link to="/cluster-cloud-scylladb">ScyllaDB</Link></li>
                <li className={currentRoute.includes("cluster-clickhouse") ? "tab active" : "tab"}><Link to="/cluster-clickhouse">ClickHouse</Link></li>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <h5>Serverless Application</h5>
        </Accordion.Header>
        <Accordion.Body>
            <ul className='sidebar-mobile'>
                <li className={currentRoute.includes("msa2") ? "tab active" : "tab"}><Link to="/msa2">마이크로서비스 개발의 필수요소들</Link></li>
                <li className={currentRoute.includes("msa3") ? "tab active" : "tab"}><Link to="/msa3">어플리케이션 통합과 서비스</Link></li>
                <ul id="sub-menu">
                  <li className={currentRoute.includes("iaam") ? "tab active" : "tab"}><Link to="/msa3/iaam">Identity And Access Management</Link></li>
                  <li className={currentRoute.includes("messaging-service") ? "tab active" : "tab"}><Link to="/msa3/messaging-service">Messaging Service</Link></li>
                  <li className={currentRoute.includes("event-sourcing") ? "tab active" : "tab"}><Link to="/msa3/event-sourcing">Event Sourcing</Link></li>
                  <li className={currentRoute.includes("webhook") ? "tab active" : "tab"}><Link to="/msa3/webhook">Webhook</Link></li>
                  <li className={currentRoute.includes("long-running") ? "tab active" : "tab"}><Link to="/msa3/long-running">Long-Running</Link></li>
                  <li className={currentRoute.includes("simple-url-shortener") ? "tab active" : "tab"}><Link to="/msa3/simple-url-shortener">Simple-Url-Shortener</Link></li>
                  <li className={currentRoute.includes("simple-storage-service") ? "tab active" : "tab"}><Link to="/msa3/simple-storage-service">S3 (Simple Storage Serice)</Link></li>
                </ul>
            </ul>
        </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
        <Accordion.Header>
          <h5>Storage</h5>
        </Accordion.Header>
        <Accordion.Body>
            <ul className='sidebar-mobile'>
                <li className={currentRoute.includes("store-object-store") ? "tab active" : "tab"}><Link to="/store-object-store">오브젝트 Storage</Link></li>
                <li className={currentRoute.includes("store-block-store") ? "tab active" : "tab"}><Link to="/store-block-store">불록 Storage</Link></li>
                <li className={currentRoute.includes("store-nas-store") ? "tab active" : "tab"}><Link to="/store-nas-store">공유 Storage</Link></li>
            </ul>
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
  );
}


export default Sidebarmobile