import React from 'react'
import FooterOutsourcing from '../components/footer';
import ImgCompany from '../components/image-company';
import LogoPartner from '../components/partner-logos';
import Banner from './banner';
import OutsourcingIT from '../components/outsourcingIT';
import Itcardsupport from "../components/outsourcing-card-support";
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer';
import hanbiro from '../SVG/logoHanbiro.svg'
// import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
function OutSourcing() {
    return (

        <div>
            <Helmet>
              <title>IT 아웃소싱 서비스</title>
              <meta name="description" content="IT 아웃소싱, 베트남 IT 아웃소싱, 베트남 R&D 센터, 베트남 개발자" />
              <meta name="keyword" content="IT아웃소싱, IT outsourcing, 베트남 IT 아웃소싱, 베트남 개발자, Flutter, Electron, Go, PHP, Swift, React, NodeJS, Java"/>
            </Helmet>
            <div>
                <NavButton option={{
                    logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                    link: "https://hanbiro.com/",
                    color: { backgroundColor: '#A94545' },
                    itemNavClass: "item-nav red",
                    menuListProduct: "menu-list-product-item red",
                    menuListVora: "vora red",
                    menuListGroupware: "groupware red",
                    titleTab: "mb-3 red",
                }} />
                <div className='OutSourcing'>
                    <Banner />
                    <Itcardsupport />
                    <OutsourcingIT />
                    <ImgCompany />
                    <LogoPartner />
                    <FooterOutsourcing />
                    <Footer />
                </div>
            </div>
            

        </div>
    );
}

export default OutSourcing;