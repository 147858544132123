import React from 'react';
import styled from 'styled-components';
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
//import './sidebar.css';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute";
import TableThreeColumns from '../components/TableThreeColumns';
import TableTwoColumns from '../components/TableTwoColumns';
import { useTranslation } from "react-i18next";
import Sidebar from '../../container/Sidebar/Sidebar';


// Titlecloudcompute
function MSA1() {
    const { t } = useTranslation();

    return (
        <div className='App'>
            <NavButton />
            {/* {Banner(t('banner.hanbiro'), t('banner.microsoft'))} */}
            <Container className='main-body'>
                <Row>
                    <Col lg={3} md={12} xs={12}>
                        <Sidebar />
                    </Col>
                    <Col lg={9} md={12} xs={12} className="right-content">
                        {Titlecloudcompute(t('msa_1.title_1'))}
                        <BoxContent>
                            <BoxText >{t('msa_1.line_1')}</BoxText>
                            <BoxText  >{t('msa_1.line_2')}</BoxText>
                            <BoxText  >{t('msa_1.line_3')}</BoxText>
                            <BoxText  >{t('msa_1.line_4')}</BoxText>
                            <BoxText  >{t('msa_1.line_5')}</BoxText>
                            <BoxText  >&nbsp;</BoxText>
                            <TableTwoColumns />
                        </BoxContent>


                        {Titlecloudcompute(t('msa_1.title_2'))}
                        <BoxContent>
                            <BoxText  >{t('msa_1.line_6')}</BoxText>
                            <BoxText  >{t('msa_1.line_7')}</BoxText>
                            <BoxText  >{t('msa_1.line_8')}</BoxText>
                            <BoxText  >{t('msa_1.line_9')}</BoxText>
                            <BoxText  >&nbsp;</BoxText>
                            <TableThreeColumns />
                        </BoxContent>
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer />
        </div>
    );
};



const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`


export default MSA1;