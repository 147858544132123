import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CommonTable from '../../container/Table/Components/CommonTable';


// Titlecloudcompute
function BackOffice() {
    return (
        <div className='App'>
            <NavButton />
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("한비로 백오피스")}
                        {/* {Contentcloudcard("고성능 Local SSD replication Volume 적용 안정적인 서비스운영","모든 인스턴스에 물리적인 rCPU (1배수) 적용, 고용량의 메모리제공")} */}
                        <Grid container spacing={3}>
                            <Grid item xs={12}>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/back-office-1.png")}</Grid>
                            <Grid item xs={12}>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/back-office-2.png")}</Grid>
                        </Grid>
                        
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer />
        </div>
    );
};

export default BackOffice;