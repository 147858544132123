import React from 'react';
import styled from 'styled-components';
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import './sidebar.css';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute";
import Sidebar from '../../container/Sidebar/Sidebar';
import { useTranslation } from "react-i18next";
import '../../Style/defaultstyle.scss'
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function MSA2() {
    const { t } = useTranslation();

    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner(t('banner.hanbiro'), t('banner.microsoft'))} */}
            <Container className='main-body'>
                <Row>
                    <Col lg={3} md={12} xs={12}>
                        <Sidebar />
                    </Col>
                    <Col lg={9} md={12} xs={12} className="right-content">
                        {Titlecloudcompute("한비로 마이크로서비스 기반 개발이란")}
                        <BoxContent>
                            <BoxTitle className='sub-title'>CQRS</BoxTitle>

                            <BoxText dangerouslySetInnerHTML={{
                                __html: `
                            Command and Query Responsibility Segregation (명령과 책임분리) <br>
                            • 명령 : 데이터 변경 (Create, Update, Delete) 작업 <br>
                            • 쿼리 : 데이터 쿼리(Read)작업 <br>
                            • 명령과 쿼리 작업의 책임을 분리하는 것 <br>
                            ` }}></BoxText>

                            <Image src="https://www.hanbiro.com/msa/images/cqrs.png"></Image>
                        </BoxContent>


                        <BoxContent>
                            <BoxTitle className='sub-title'>EventSourcing</BoxTitle>

                            <BoxText dangerouslySetInnerHTML={{
                                __html: `
                            순차적으로 발생하는 이벤트를 적용된 순서대로 모두 저장 <br>
                            이벤트의 갱신이나 삭제 연산은 수행되지 않음 <br>
                            이벤트 소싱은 확장성이 뛰어나고 분산된 변경처리 방식 <br>
                            ` }}></BoxText>

                            <Image src="https://www.hanbiro.com/msa/images/event-source.png"></Image>
                        </BoxContent>


                        <BoxContent>
                            <BoxTitle className='sub-title'>API 게이트웨이</BoxTitle>

                            <BoxText dangerouslySetInnerHTML={{
                                __html: `
                            마이크로서비스는 API를 통해 통신<br>
                            URL별 : a.vora.net 과 b.vora.net 를 구분해 라우팅<br>
                            모듈별(Prefix별 / Host별) : a.vora.net/a 과 a.vora.net/b 를 구분해 라우팅<br>
                            여러 서비스의 엔드포인트와 REST API를 관리<br>
                            ` }}></BoxText>

                            <Image src="https://www.hanbiro.com/msa/images/api-gateway.png"></Image>
                        </BoxContent>


                        <BoxContent>
                            <BoxTitle className='sub-title'>컨테이너, Docker 및 Kubernetes</BoxTitle>

                            <BoxText dangerouslySetInnerHTML={{
                                __html: `
                            쿠버네티스의 POD은 가상서버보다 작고 경량으로 마이크로서비스에 적합
                            ` }}></BoxText>
                        </BoxContent>


                        <BoxContent>
                            <BoxTitle className='sub-title'>서버리스(Serverless)서비스로 제공되는 API들</BoxTitle>

                            <BoxText dangerouslySetInnerHTML={{
                                __html: `
                            쿠버네티스의 POD은 가상서버보다 작고 경량으로 마이크로서비스에 적합 <br>
                            어플리케이션이 필요한 경우에만 시작 <br>
                            유휴 상태일 때는 비용부과되지 않음 (서비스에 따라 저장용량 비용 발생) <br>
                            개발기간과 비용단축 <br><br>

                            <div style="display: grid; grid-template-columns: auto auto;">
                                <div>• Identity : OAuth</div>
                                <div>• Core Storage (S3)</div>
                                <div>• Directory</div>
                                <div>• Simple URL Shortner</div>
                                <div>• IAM (Identity and Access Management)</div>
                                <div>• Generate QR Code</div>
                                <div>• Event Sourcing</div>
                                <div>• Business Process</div>
                                <div>• Long-running</div>
                                <div>• Automation Rule</div>
                                <div>• Messaging :</div>
                                <div>• Email</div>
                                <div style="margin-left: 15px">• Topics</div>
                                <div>• SMS</div>
                                <div style="margin-left: 15px">• Queues</div>
                                <div>• DNS</div>
                                <div style="margin-left: 15px">• Incoming Webhooks</div>
                                <div>• IOT</div>
                                <div style="margin-left: 15px">• Outgoing Webhooks</div>
                                <div>• Search query</div>
                                <div style="margin-left: 15px">• Scheduler</div>
                                <div>• Rate Limiting</div>
                            </div>
                            
                            ` }}></BoxText>

                        </BoxContent>
                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};


const BoxTitle = styled.div`
  text-align: left;
  font-weight: bold;
  margin-bottom: 20px;
`

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`

const BoxSubText = styled.div`
  margin-left: 7%;
`

const Image = styled.img`
  width: 100%;
`

export default MSA2;