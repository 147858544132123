import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CommonTable from '../../container/Table/Components/CommonTable';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';


// Titlecloudcompute
function Soho() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {/* {Titlecloudcompute("소호형(한비로구성도)")} */}
                        {/* {Contentcloudcard("","")} */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className='grid-card'>{CardBoxRow(
                                "Private Kubernetes 소호형 구성도",

                                `
                                <p style="margin: 0px">Private Kubernetes 소호형은 VM으로 Master / Worker Node 로 운영합니다.</p>
                                <p style="margin: 0px">Proxmox VE VM 가상화 서버를 사용, 최소 2대부터 프라이빗 쿠버네티스 클러스터를 구성할 수 있습니다.</p>
                                <p style="margin:0;">&nbsp;</p>
                                <ul>
                                    <li>Proxmox VE 2대를 활용한 소호형 클러스터</li>
                                    <li>소규모 팀 및 스타트업에 최적화된 경량형 솔루션</li>
                                    <li>소규모 어플리케이션 운영에 적합</li>
                                    <li>초기비용절감과 빠른 배포</li>
                                    <li>테스트및 개발환경</li>
                                </ul>
                                `)}
                            </Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/back-office-1.png")}</Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/back-office-2.png")}</Grid>
                        </Grid>

                        <CloudConsultCp />
                    </Col>
                </Row>
            </Container>
            {/* <Cloudconsult /> */}
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

export default Soho;