import React from 'react';
import styled from 'styled-components';
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
//import './sidebar.css';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute";
import CardWrap from '../components/CardWrapper';
// import config from '../components/config';
import '../../container/Box/Components/Box-Style.css'
import Sidebar from '../../container/Sidebar/Sidebar';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function SimpleUrl() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Simple-Url-Shortener")}
                        <BoxContent>
                            <BoxTitle className='sub-title'>• Simple URL Shortener 란</BoxTitle>

                            <BoxText  >
                                많은 문자와 기호, 숫자가 포함된 긴 웹주소를 짧은 URL 로 제공 : PURLs 사용 <br></br>
                                (예) <u>abc.vora.net/desk/marketing/survey.html?id=a3bc65cd</u> <br></br>
                                - 기본 도메인 사용시 =&gt; sus.so/aB2b3Aza <br></br>
                                - 자사 도메인 사용시 =&gt; 자사도메인/aB2b3Aza <br></br>
                                <br></br>
                                짧고 읽기 쉬운 URL 로 제공 : 일반 웹사이트 사용 (이전 사이트 URL 인식가능). <br></br>
                                - 기본 도메인 사용시 =&gt; sus.so/자사브랜드명/자사고유단어(help, sales, marketing 등) <br></br>
                                - 자사 도메인 사용시 =&gt; 자사도메인/자사고유단어(help, sales, marketing 등) <br></br>
                            </BoxText>
                        </BoxContent>
                        <BoxContent>
                            <BoxTitle className='sub-title'>• PURLs (Personalized URLs)</BoxTitle>
                            <BoxText  >
                                - 마케팅 캠페인에서 각 대상자별로 웹 URL 주소와 컨덴츠를 제공. <br></br>
                                - 각각의 PURL 은 개인화된 유일한 웹사이트 링크가 가능. <br></br>
                                - 개인화된 텍스트, 이미지, 제공품, 설문조사등이 진행될때 마케팅담당자는 온라인 응답이나 참여에 대해서 추적 가능.
                            </BoxText>
                        </BoxContent>
                        {CardWrap("• Email / SMS 마케팅시 단축 URL 사용 예", "", "", "https://hanbiro.com/cloud/library2/simple-url-shortener/simple-url-shortener-1.svg")}
                        {/*CardWrap("• 가격",config.tb8_header,config.tb8_content)*/}

                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`

const BoxSubText = styled.div`
  margin-left: 7%;
`

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`
export default SimpleUrl;