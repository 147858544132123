import React from 'react';
import styled from 'styled-components';
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute";
import CardWrap from '../components/CardWrapper';
// import config from '../components/config';
import Sidebar from '../../container/Sidebar/Sidebar';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function WebHook() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")} */}
            <Container className='main-body'>
                <Row>
                    <Col lg={3} md={12} xs={12}>
                        <Sidebar />
                    </Col>
                    <Col lg={9} md={12} xs={12} className="right-content">
                        {Titlecloudcompute("Webhook")}
                        <BoxContent>
                            <BoxText  >• Webhook 은 직접 선택한 URL 로 보내지는 이벤트 알림입니다.</BoxText>
                            <BoxText  >• 사용자는 한 사이트에서 Event 를 트리거하여 다른 사이트의 실행 프로그램을 호출합니다.</BoxText>
                            <BoxText  >• 마이크로서비스 개발 환경에서 작은 어플리케이션 간 또는 외부의 어플리케이션 간 연관된 작업을 쉽게 구성 가능하여 개발시간의 단축에 필수 기능입니다.</BoxText>
                            {/* {Imagecloudcompute("https://hanbiro.com/cloud/library2/webhook/webhook-1.svg")} */}
                            <ImageWrap src="https://hanbiro.com/cloud/library2/webhook/webhook-1.svg"></ImageWrap>
                            <BoxText >외부에서 특정 이벤트가 발생시 클라이언트를 호출 (역방향 API)</BoxText>
                            <BoxText >클라이언트의 호출되는 URL, 데이터가 보내지는 URL 을 Callback URL</BoxText>
                            <BoxText >Hooking 의 의미 : 어떠한 액션 앞 또는 뒤에 추가로 어떤일을 하도록 하는것</BoxText>
                        </BoxContent>
                        {/*
                        {CardWrap("• 가격",config.tb6_header,config.tb6_content)}
                        */}
                        {CardWrap("• AWS 의 Webhook", "", "", "https://hanbiro.com/cloud/library2/webhook/webhook-2.svg")}

                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`

const ImageWrap = styled.img`
  width: 100%;
  object-fit: fill;
`

export default WebHook;