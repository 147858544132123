import React from 'react';
import styled from 'styled-components';
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
//import './sidebar.css';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute";
import Sidebar from '../../container/Sidebar/Sidebar';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function SimpleUrl() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Simple Storage Service")}
                        <BoxContent>
                            <BoxTitle className='sub-title'>• S3 Bucket 생성</BoxTitle>

                            <BoxText  >
                                버킷이란 스토리지에 저장되는 오브젝트를 담는 컨테이너.<br></br>
                                URL 로 접근되는 최상위 Directory.
                            </BoxText>
                            <br></br>
                            <BoxText  >
                                - Bucket 이름 : <br></br>
                                - 지역 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <br></br>
                                - Access Control (접근제어) :
                            </BoxText>
                        </BoxContent>

                        <BoxContent>
                            <BoxTitle className='sub-title'>• 파일 업로드 / 다운로드시 URL</BoxTitle>

                            <BoxText  >
                                https://
                                <font color="red">Bucket 이름</font>.storage.
                                <font color="red">지역</font>.vora.net/
                                <font color="red">Directory Name</font>
                            </BoxText>
                        </BoxContent>

                        <BoxContent>
                            <BoxTitle className='sub-title'>• Object Storage S3 에 오브젝트 업로드 구성도</BoxTitle>

                            <BoxText  >
                                S3 Bucket 에 오브젝트(파일, 메타데이터)를 업로드 하기전
                                스토리지 API로 부터 Pre-signed URL 을 요청한후 받은 Pre-signed URL 에 직접 업로드
                            </BoxText>
                            {/* {Imagecloudcompute("https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-1.svg")} */}
                            <ImageWrap src="https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-1.svg"></ImageWrap>

                            <BoxText  >
                                <b>Single request upload</b><br></br>
                                - 대략 30MB의 파일을 업로드할때 한번의 request 로 업로드.
                                <br></br>
                                <b>Multipart upload</b><br></br>
                                - 파일들이 최소 3부분이상으로 쪼개서 업로드하고, 한번의 request 로 업로드.<br></br>
                                - 전체 시간을 줄이기 위해 각 쪼개진 부분을 병렬로 업로드.
                            </BoxText>
                        </BoxContent>

                        <BoxContent>
                            <BoxTitle className='sub-title'>• Object Storage 로부터 다운로드 구성도</BoxTitle>

                            {/* {Imagecloudcompute("https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-2.svg")} */}
                            <ImageWrap src="https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-2.svg"></ImageWrap>
                        </BoxContent>

                        <BoxContent>
                            <BoxTitle className='sub-title'>• 웹에서 Bucket 내 오브젝트 관리도</BoxTitle>
                            <BoxText  >
                                - 오브젝트란 스토리지에 저장되는 파일과 메타데이터.<br></br>
                                - 버킷에 있는 오브젝트의 리스트를 가져오고, 작업을 위해서는 IAM 의 ACL (접근제어목록)에 설정된 권한에 따라 가능
                            </BoxText>
                            {/* {Imagecloudcompute("https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-3.svg")} */}
                            <ImageWrap src="https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-3.svg"></ImageWrap>
                        </BoxContent>

                        <BoxContent>
                            <BoxTitle className='sub-title'>• IAM 의 Object 에 Resource 를 등록해 권한 부여</BoxTitle>

                            <BoxText  >- Hierarchy(계층적)의 Parent / Child.</BoxText>
                            <BoxText  >
                                - 부모의 권한을 기본으로 따르고, 자식 (Directory / Object)만이 별도로 권한설정.
                            </BoxText>
                            <BoxText  >- 권한 : 읽기 / 쓰기 / 삭제</BoxText>
                            <BoxText  >• Bucket</BoxText>
                            <BoxSubText  >• Directory</BoxSubText>
                            <BoxSubTextSub  >• Object(File, Metadata)</BoxSubTextSub>
                        </BoxContent>

                        <BoxContent>
                            <BoxTitle className='sub-title'>• 기존 서버에서 파일 업로드 다운로드 구성도</BoxTitle>
                            {/* {Imagecloudcompute("https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-4.svg")} */}
                            <ImageWrap src="https://hanbiro.com/cloud/library2/simple-storage-service/simple-storage-service-4.svg"></ImageWrap>
                        </BoxContent>

                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};


const BoxTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`

const BoxSubText = styled.div`
  margin-left: 3%;
  line-height: 2em;
  
`
const BoxSubTextSub = styled.div`
  margin-left: 6%;
  line-height: 2em;
`

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const ImageWrap = styled.img`
  width: 100%;
  object-fit: fill;
`

export default SimpleUrl;