import React from 'react';
import '../Sidebar/sidebar.css';
import { useHistory, NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';

function Sidebar() {
  const currentRoute = useHistory().location?.hash;
  return (
    <Accordion defaultActiveKey={["#cluster", "#microservice", "#storage"]} alwaysOpen className='sidebardesk'>
      {/* <Accordion defaultActiveKey={currentRoute} className='sidebardesk'> */}
      <Accordion.Item eventKey="#server" className="menu-accordion-item">
        <Accordion.Header>
          <h5>Server</h5>
        </Accordion.Header>
        <Accordion.Body>
          <ul className='sidebar-main'>
            <NavLink to="/compute-vps#server">가상서버 (인스턴스)</NavLink>
            <NavLink to="/compute-bare-metal#server">베어메탈 서버 (단독서버)</NavLink>
            <NavLink to="/compute-auto-scale#server">오토스케일링</NavLink>
            <NavLink to="/compute-load-balance#server">로드발랜서</NavLink>
            <NavLink to="/compute-database#server">데이터베이스 (인스턴스)</NavLink>
            <NavLink to="/compute-ms#server">MS-SQL / Windows</NavLink>

          </ul>
        </Accordion.Body>
      </Accordion.Item>

      {/* <Accordion.Item eventKey="#cluster">
        <Accordion.Header>
          <h5>Kubernetes Clusters</h5>
        </Accordion.Header>
        <Accordion.Body>
          <div className='left-menu-box'>
            <h6>Application</h6>
            <ul className='sidebar-main'>
              <NavLink to="/cluster-cloud-elastic-search#cluster">Elastic Search</NavLink>
              <NavLink to="/cluster-kafka#cluster">Kafka</NavLink>
            </ul>
          </div>

          <div className='left-menu-box'>
            <h6>Database</h6>
            <ul className='sidebar-main'>
              <NavLink to="/cluster-cloud-mysql-db#cluster">MySQL</NavLink>
              <NavLink to="/cluster-cloud-postgresql-db#cluster">PostgreSQL</NavLink>
              <NavLink to="/cluster-cloud-vitess#cluster">Vitess</NavLink>
              <NavLink to="/cluster-cloud-mongodb#cluster">MongoDB</NavLink>
              <NavLink to="/cluster-cloud-redis#cluster">Redis</NavLink>
              <NavLink to="/cluster-cloud-scylladb#cluster">ScyllaDB</NavLink>
              <NavLink to="/cluster-clickhouse#cluster">ClickHouse</NavLink>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}

      {/* <Accordion.Item eventKey="#cluster" className="menu-accordion-item">
        <Accordion.Header>
          <h5>Private Kubernetes</h5>
        </Accordion.Header>
        <Accordion.Body>
          <ul className='sidebar-main'>
            <NavLink to="/private-cloud#cluster">장점</NavLink>

            <NavLink to="/soho#cluster">시스템 구성</NavLink>
            <ul className='sidebar-sub' id="sub-menu2#cluster">
              <NavLink to="/soho/soho1#cluster">소호형(한비로구성도)</NavLink>
              <NavLink to="/soho/scalable#cluster">확장형</NavLink>
              <NavLink to="/soho/enterprise#cluster">엔터프라이즈형</NavLink>
            </ul>

            <NavLink to="/monitor#cluster">기능 및 관리 도구</NavLink>
            <ul className='sidebar-sub' id="sub-menu1#cluster">
              <NavLink to="/monitor/monitoring-volume#cluster">모니터링•볼륨 구조</NavLink>
              <NavLink to="/back-office#cluster">한비로 백오피스</NavLink>
            </ul>

          </ul>
        </Accordion.Body>
      </Accordion.Item> */}

      <Accordion.Item eventKey="#microservice" className="menu-accordion-item">
        <Accordion.Header>
          <h5>Serverless Application</h5>
        </Accordion.Header>
        <Accordion.Body>
          <ul className='sidebar-main'>
            {/* <NavLink to="/msa1#microservice">마이크로서비스 기반개발이란</NavLink> */}
            <NavLink to="/msa2#microservice">마이크로서비스 개발의 필수요소들</NavLink>
            <NavLink to="/msa3#microservice">어플리케이션 통합과 서비스</NavLink>
            <ul className='sidebar-sub' id="sub-menu#microservice">
              <NavLink to="/msa3/iaam#microservice">Identity And Access Management</NavLink>
              <NavLink to="/msa3/messaging-service#microservice">Messaging Service</NavLink>
              <NavLink to="/msa3/event-sourcing#microservice">Event Sourcing</NavLink>
              <NavLink to="/msa3/webhook#microservice">Webhook</NavLink>
              <NavLink to="/msa3/long-running#microservice">Long-Running</NavLink>
              <NavLink to="/msa3/simple-url-shortener#microservice">Simple-Url-Shortener</NavLink>
              <NavLink to="/msa3/simple-storage-service#microservice">S3 (Simple Storage Serice)</NavLink>
            </ul>
          </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="#storage" className="menu-accordion-item">
        <Accordion.Header>
          <h5>Storage</h5>
        </Accordion.Header>
        <Accordion.Body>
            <ul className='sidebar-main'>
                <NavLink to="/store-object-store#storage">오브젝트 Storage</NavLink>
                <NavLink to="/store-block-store#storage">불록 Storage</NavLink>
                <NavLink to="/store-nas-store#storage">공유 Storage</NavLink>
            </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}


export default Sidebar;