import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import styled from 'styled-components';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Sidebar from '../../container/Sidebar/Sidebar'
import '../../container/Sidebar/sidebar.css'
import '../../Style/defaultstyle.css'
import '../../Style/defaultstyle.scss'
import CardWrap from '../components/CardWrapper';
import CommonTable from '../../container/Table/Components/CommonTable';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function MessagingService() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")}  */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Messaging Service")}
                        {CardWrap("• Messaging 구성도", "", "", "https://hanbiro.com/cloud/library2/messaging-service/messaging-service-1.svg")}
                        <BoxContent>
                            <BoxTitle className='sub-title'>• Message Entity 의 차이점</BoxTitle>

                            <CommonTable
                                header={{
                                    row_1: [
                                        { title: "&nbsp;", },
                                        { title: "Topic", },
                                        { title: "Queue", },
                                        { title: "Channel", },
                                    ],
                                }}
                                content={{
                                    row_1: [
                                        { title: "Delivery", },
                                        {
                                            title: `<div style='text-align:center'>At Least Once (Exactly Once) <br>
                                            <span style='color: #48A7FC; font-size: 14px'>(Committing Offset 방식)</span> 
                                            <img src='https://hanbiro.com/cloud/images/cloud/question.svg' width='20px'></img></div>`},
                                        {
                                            title: `<div style='text-align:center'>At Least Once (Exactly Once) <br> 
                                            <span style='color: #48A7FC; font-size: 14px'>(Ack Reply 방식)</span> 
                                            <img src='https://hanbiro.com/cloud/images/cloud/question.svg' width='20px'></img></div>`},
                                        { title: "<div style='text-align:center'>At Most Once</div>", }
                                    ],
                                    row_2: [
                                        { title: "Storage", },
                                        { title: "<div style='text-align:center'>Disk</div>", },
                                        { title: "<div style='text-align:center'>Disk</div>", },
                                        { title: "<div style='text-align:center'>In Memory</div>", },
                                    ],
                                    row_3: [
                                        { title: "Ordering", },
                                        { title: "<div style='text-align:center'>순서보장</div>", },
                                        { title: "<div style='text-align:center'>순서보장안함</div>", },
                                        { title: "<div style='text-align:center'>X</div>", },
                                    ],
                                    row_4: [
                                        { title: "Consumer", },
                                        { title: "<div style='text-align:center'>Pub / Sub</div>", },
                                        { title: "<div style='text-align:center'>1:1</div>", },
                                        { title: "<div style='text-align:center'>Fan Out (Broadcast)</div>", },
                                    ],
                                }} />
                            <BoxText>• Committing Offset 방식 </BoxText>
                            <BoxSubText>Consumer 가 Poll을 호출할때 각 파티션에 자신이 가져간 메세지 위치(Offset)을 업데이트 함 (Commit)</BoxSubText>
                            <BoxText>• Ack Reply 방식</BoxText>
                            <BoxSubText>Consumer 나 Endpoint 가 메세지를 받은후 Ack 신호를 주고 해당 메세지는 삭제</BoxSubText>
                        </BoxContent>
                        {CardWrap(
                            "• Pull 과 Push 의 차이점",

                            {
                                row_1: [
                                    { Cols50: "True", title: "Pull" },
                                    { title: "Push" },
                                ]
                            },

                            {
                                row_1: [
                                    {
                                        bold: "False", title: `<div style='text-align:'>Streaming Pull 지원. <br> 
                                                        Consumer 가 API 를 호출해서 Message 를 가져감.</div>`},
                                    { title: "<div style='text-align:'>Worker가 Message를 받아 GRPC Unary나 URL Get/Post를 보냄.</div>" }
                                ]
                            })}
                        <BoxContent>
                            <BoxTitle className='sub-title'>• Subscription 의 특성</BoxTitle>
                            <BoxTextNone className='breakword'>
                                <b>Message Entity (Topic / Queue / Channel)</b> 은 복수의 <b>Subscription</b>(구독)을 가질 수 있으나, <br />
                                한개의 주어진 <b>Subscription</b> 은 하나의 <b>Message Entity</b> 만 가질 수 있다.</BoxTextNone>
                            {/* {Imagecloudcompute("https://hanbiro.com/cloud/library2/messaging-service/messaging-service-2.svg")} */}
                            <ImageWrap src="https://hanbiro.com/cloud/library2/messaging-service/messaging-service-2.svg"></ImageWrap>
                        </BoxContent>
                        {/*
                        {CardWrap("• Vora Desk Messaging Service 요약","","","https://hanbiro.com/cloud/library2/messaging-service/messaging-service-3.svg")}
                        */}

                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxSubText = styled.div`
  margin-left: 1.5%;
  color: #48A7FC;
`

const Box = styled.div`
  @media only screen and (max-width: 767px){
    font-size: 80%;
  }
`

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
    color: #48A7FC;
    font-weight: bold;
`

const BoxTextNone = styled.div`
    margin: 5px;
    line-height: 2em;
`

const BoxTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`

const ImageWrap = styled.img`
  width: 100%;
  object-fit: fill;
`

export default MessagingService;
