import React, { Component } from "react";
import '../components/style.css'
import ImageLoGo from '../components/img';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class LogoPartner extends Component {
    render() {
      var settings = {
        infinite: true,
        speed: 3500,
        autoplaySpeed: 3500,
        slidesToShow: 4,
        slidesToScroll: 3,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              initialSlide: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            }
          }
        ]
      };
      return (
        <div className="imgSlide container">
        <Slider className='logo-company' {...settings}>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg1.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg2.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg3.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg4.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg5.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg6.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg7.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg8.svg")}
          </div>
          <div className='sizeimg'>
            {ImageLoGo("https://hanbiro.com/cloud/library/outsourcing/Lg9.svg")}
          </div>
        </Slider>
      </div>
      );
    }
  }
