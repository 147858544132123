import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CommonTable from '../../container/Table/Components/CommonTable';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';


// Titlecloudcompute
function Enterprise() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {/* {Titlecloudcompute("엔터프라이즈형")} */}
                        {/* {Contentcloudcard("고성능 Local SSD replication Volume 적용 안정적인 서비스운영","모든 인스턴스에 물리적인 rCPU (1배수) 적용, 고용량의 메모리제공")} */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className='grid-card'>{CardBoxRow(
                                "Private Kubernetes 엔터프라이즈형 클러스터",

                                `
                                <p style="margin:0;">Private Kubernetes 엔터프라이즈형 클러스터는 BareMetal을 활용하여 Master/Worker Node뿐만 아니라 Block Storage 및 Object Storage를 운영합니다.</p>
                                <p style="margin:0;">프라이빗 쿠버네티스 클러스터 구성은 최소 5대부터 구성 가능합며, Block Storage는 5, Object Storage(S3)는 4대를 이용하여 최소 구성이 가능합니다. (총 BareMetal 13대 필요)</p>
                                
                                <p style="margin:0;">&nbsp;</p>
                                <ul>
                                    <li>BareMetal 13대를 활용한 대규모 클러스터</li>
                                    <li>BareMetal 서버, Ceph 블록 스토리지, MinIO 오브젝트 스토리지로 구성된 대규모 환경 고가용성 및 안정성을 보장하는 분산 스토리지 아키텍처 엔터프라이즈급 애플리케이션 및 데이터 중심 서비스에 적합</li>
                                    <li>엔터프라이즈급 애플리케이션 및 데이터 중심 서비스에 적합</li>
                                    <li>고성능 분산 스토리지 기반 인프라</li>
                                    <li>클라우드 네이티브 애플리케이션 운영 대규모 데이터 처리 및 머신러닝 워크로드 대규모의 <br>마이크로서비스기반 서비스를 제공하는 기업</li>
                                </ul>
                                `)}
                            </Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/kube-enterprise.png")}</Grid>
                        </Grid>
                        <CloudConsultCp />
                    </Col>
                </Row>
            </Container>
            {/* <Cloudconsult /> */}
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

export default Enterprise;