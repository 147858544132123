
import React from "react";
import './style.css';
import TopNavButton from './TopNavButton'
import TopNavSlotholder from './TopNavSlotholder'
import NavButton from "./TopNavButton";


function App() {
    return (
        <>
            <div >
                <TopNavButton />
                {/* <NavButton option={{
                    
                    logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                    link: "https://hanbiro.com/",
                    color: { backgroundColor: '#47A7FF' },
                    itemNavClass: "item-nav",
                    menuListProduct: "menu-list-product-item",
                    menuListVora: "vora",
                    menuListGroupware: "groupware",
                    titleTab: "mb-3",
                }} /> */}
            </div>
            <div >
                <TopNavSlotholder />
            </div>
        </>


    );
}

export default App;