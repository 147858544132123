import { AiFillCheckCircle } from "react-icons/ai";

const obj = {
    header : {
        row_1: [
            {
                title: "구분",
            },
            {
                title: "구간",
            },
            {
                title: "구간",
            },

        ]
    },
    content : {
        row_1: [
            {
                rowSpan :"2",
                title: "구분",
            },
            {
                title: "상세내용",
            },
            {
                title: "구간",
            },

        ],
        row_2: [
            {
                title: "개발 -> 테스트 -> 검증 -> 운영 <br> 개발 -> 테스트 -> 검증 -> 운영",
            },
            {
                title: "",
                icon: <AiFillCheckCircle />,
            },

        ],
    }
}

export default obj;