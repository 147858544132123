import React from 'react';
import { Box } from '@mui/material';
import '../../outsourcing/components/card_outsourcing.css';
import {
  Container,
} from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import '../../Style/defaultstyle.css';





 function cardcloudit ($titlecarrd,$content1,$content2,$content3,$content4) {
    return(
      <Box sx={TextOutsourcing} className="dev-lang" >
        <Box className="text-card">
              <Box  sx={CardTitle} className="font-titlecard"><span className="texttitlecard-1">{$titlecarrd}</span></Box>
        </Box>
        <Box className="styletable">
          <div className="styletextcardout"><span className="cardtext-1">{$content1}</span></div>
          <div className="styletextcardout2"><span className="cardtext-1">{$content2}</span></div>
          <div className="styletextcardout2"><span className="cardtext-1">{$content3}</span></div>
          <div className="styletextcardout3"><span className="cardtext-1">{$content4}</span></div>      
        </Box>
    </Box>    
    )
 }


 function cardcloudittop ($titlecarrd,$content1,$content2,$content3,$content4) {
 
    return(
      <Box sx={TextOutsourcing}  >
        <Box className="text-card">
              <Box  sx={CardTitle} className="font-titlecard"><span className="texttitlecard-1">{$titlecarrd}</span></Box>
        </Box>
        <Box className="styletable">
          <div className="styletextcardout"><span className="cardtext-1">{$content1}</span></div>
          <div className="styletextcardout2"><span className="cardtext-1">{$content2}</span></div>
          <div className="styletextcardsub3"><span className="cardtext-1">{$content4}</span></div>
        </Box>
    </Box>   
    )
 }


function Itcardsupport() {
  
  return (
     
      <div>
         <Container>
           <Box>
            <Grid container  className="group-info" md={10} >
                <Grid item xs={12}>
                    <Box className="title-it-outsourcing"><span className="title_outsourcing_font">IT 인력 아웃소싱 <br className="title-down" /> 서비스 분야</span></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max">
                     <Box sx={CardOutsourcingui} className="stylecard">
                        {cardcloudit("Mobile Development","Flutter","React-Native","Swift / Kotlin","Xcode / AndroidStudio")}
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max-top">
                     <Box sx={CardOutsourcingui} className="stylecard">
                        {cardcloudit("Microservice","Event Sourcing / OAuth","Identity Access Management","Messaging : Topic, Queue ","Webhook / Long-running")} 
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max">
                     <Box sx={CardOutsourcingui} className="stylecard">
                        {cardcloudittop("Cloud Service","Kubernetes","Openstack","","")} 
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max">
                     <Box sx={CardOutsourcingui} className="stylecard">
                      {cardcloudit("Front-end Development","ReactJS","NodeJS / VueJS","Javascript","Typescript")}
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max-top">
                     <Box sx={CardOutsourcingui} className="stylecard">
                     {cardcloudit("Back-end Development","Go","PHP","Python","NodeJS")}
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} >
                   <Box className="style-box-max">
                     <Box sx={CardOutsourcingui} className="stylecard">
                      {cardcloudit("Linux Management","CentOS","Rocky Linux","Ubuntu","")} 
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max">
                     <Box sx={CardOutsourcingui} className="stylecard">
                      {cardcloudit("Desktop/Cross-Platfrom","Electron","Flutter","React-Native","")} 
                     </Box>  
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max-top">
                     <Box sx={CardOutsourcingui} className="stylecard">
                     {cardcloudit("Software Testing","Selenium","Appium","RPA","")} 
                     </Box>  
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}  >
                   <Box className="style-box-max">
                     <Box sx={CardOutsourcingui} className="stylecard">
                      {cardcloudit("Planning","Figma","Keynote","Mockup","")}
                     </Box>  
                    </Box>
                </Grid>
            </Grid>
           </Box>
          </Container>
          
      </div>
       

        
        
    
  );
}


const CardOutsourcingui = {
  padding: "15px",
  border: "#000000 solid 1px",
  background: "#FFFFFF",
  boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
  borderRadius: "8px",
  paddingTop: "10px",
  
}


const CardTitle = {
  textAlign: "centter",
  fontWeight: "bold",
  fontSize:"16px",
}


const TextOutsourcing = {
  paddingTop: "1px",
  
}






export default Itcardsupport;
