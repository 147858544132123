import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavButton from '../homepage/header-banner/TopNavButton';
import Footer from '../homepage/footer/Footer';
import { CheckCircle } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import './asset/css/style.css';

function Kubernetes() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 120,
            once: true,
        });
    }, []);

    document.addEventListener("DOMContentLoaded", function () {
        const header = document.querySelector(".header-kuber");
        const info = document.querySelector(".information");

        if (header && info) {
            const infoHeight = info.offsetHeight;
            header.style.Height = `${infoHeight}px`;
        }
    });

    return (

        <div>
            <div>
                <NavButton option={{
                    // logo: logoKube,
                    logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                    link: "https://hanbiro.com/",
                    color: { backgroundColor: '#47A7FF' },
                    itemNavClass: "item-nav",
                    menuListProduct: "menu-list-product-item",
                    menuListVora: "vora",
                    menuListGroupware: "groupware",
                    titleTab: "mb-3",
                }} />
                <div className='kubernetes'>
                    <div class="header-kuber">
                        <div class="title_banner">
                            <p className="head-top">• <span className="head-text-top">Hanbiro Kube 서비스는 운영중인 어플리케이션을 Kubernetes PaaS 환경으로 전환합니다.</span></p>
                            <p className="head-text">• 일반적인 홈페이지부터 쇼핑몰 모바일서비스등 운영중인 서비스에 </p>
                            <p className="head-text-bot">Private Kubernetes 플랫폼을 도입할 수 있습니다.</p>
                        </div>
                    </div>

                    <div className="information-block">
                        <div class="information">
                            <div class="infor_box">
                                <div class="infor_dt_left">
                                    <img src="https://hanbiro.com/images/kubernetes/image1.svg" />
                                    <div class="infor_dt_text">
                                        Kubernetes<br />플랫폼 도입의 장점
                                    </div>
                                </div>
                                <div class="infor_dt_right">
                                    <div class="infor_text_li">
                                        {/* <i class="fa-solid fa-circle-check"></i> */}
                                        <CheckCircle className='icon-check' />
                                        <span>서버확장:</span>서비스운영중 서버를 자유롭게 확장
                                    </div>
                                    <div class="infor_text_li">
                                        {/* <i class="fa-solid fa-circle-check"></i> */}
                                        <CheckCircle className='icon-check' />
                                        <span>무정지서비스:</span>재해복구, 서비스의 영속성
                                    </div>
                                    <div class="infor_text_li">
                                        {/* <i class="fa-solid fa-circle-check"></i> */}
                                        <CheckCircle className='icon-check' />
                                        <span>Autoscale:</span>서비스 확장 자동화
                                    </div>
                                    <div class="infor_text_li">
                                        {/* <i class="fa-solid fa-circle-check"></i> */}
                                        <CheckCircle className='icon-check' />
                                        <span>Cluster:</span>서비스의 로드발랜싱
                                    </div>
                                    <div class="infor_text_li">
                                        {/* <i class="fa-solid fa-circle-check"></i> */}
                                        <CheckCircle className='icon-check' />
                                        <span>Application:</span>Microservice 개발플랫폼 확보
                                    </div>
                                    <div class="infor_text_li">
                                        {/* <i class="fa-solid fa-circle-check"></i> */}
                                        <CheckCircle className='icon-check' />
                                        <span>서버자원의 효율성 향상</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="information-bg"></div>
                    </div>

                    <div class="section fs-section">
                        <div class="section_title">
                            Hanbiro Kube 프라이빗 쿠버네티스<br /> 시스템 구조
                        </div>
                        <div class="seciton_content">
                            <div class="content_box left">
                                <div class="image_box">
                                    <img src="https://hanbiro.com/images/kubernetes/1.png" />
                                </div>
                            </div>
                            <div class="content_box right">
                                <div class="content_dt">
                                    <ul>
                                        <li>오픈스택 또는 KVM 서버를 이용한 쿠버네티스 시스템을 구축할 수 있습니다.</li>
                                        <li>이경우 KVM 의 VM 단위가 Master 노드 또는 Worker 노드로 운영됩니다.</li>
                                        <div class="space"></div>
                                        <div class="space"></div>
                                        <li>S3 프로토콜을 지원하는 오브젝트 스토리지는 MinIO S3 스토리지를 운영합니다.</li>
                                        <div class="space"></div>
                                        <div class="space"></div>
                                        <li>서비스를 위한 스토리지 볼륨은 고성능의 I/O 지원하는 블록스토리지를 사용해야 합니다.</li>
                                        <li>Hanbiro Kube 의 기본 볼륨은 높은성능의 I/O 를 지원하는 Local 볼륨을 사용합니다.</li>
                                        <li>볼륨을 위한 스토리지 구성은 고객의 요청에 의해서 구성할 수 있습니다.</li>
                                    </ul>
                                    {/* <div class="content_text text-16"><b>Hanbiro Kube 시스템 구축</b></div>
                                    <div class="content_text text-pl10">• 기반 환경: 오픈스택 또는 <b>KVM</b> 서버를 이용하여 쿠버네티스 시스템을 구축할 수 있습니다.</div>
                                    <div class="content_text text-pl10">• <b>KVM</b> 환경의 노드: <b>KVM</b> 환경에서 <b>VM</b>은 <b>Master</b> 노드 또는 <b>Worker</b> 노드로 운영됩니다.</div>
                                    <div class="space"></div>
                                    <div class="content_text text-16"><b>스토리지</b></div>
                                    <div class="content_text text-pl10">• 오브젝트 스토리지: <b>S3</b> 프로토콜을 지원하는 <b>MinIO S3</b> 스토리지를 운영합니다.</div>
                                    <div class="content_text text-pl10">• 서비스 볼륨: 서비스를 위한 스토리지 볼륨은 고성능의 <b>I/O</b>를 지원하는 블록 스토리지를 사용해야 합니다.</div>
                                    <div class="content_text text-pl20">- 기본 볼륨: <b>Hanbiro Kube</b>의 기본 볼륨은 높은 성능의 <b>I/O</b>를 지원하는 <b>Local</b> 볼륨을 사용합니다.</div>
                                    <div class="content_text text-pl20">- 스토리지 구성: 볼륨을 위한 스토리지 구성은 고객의 요청에 의해서 구성할 수 있습니다.</div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="line"></div>

                    <div class="section">
                        <div class="section_title">
                            Hanbiro Kube 프라이빗 쿠버네티스 <br /> Namespace 상세구조
                        </div>
                        <div class="seciton_content">
                            <div class="content_box left">
                                <div class="image_box">
                                    <img src="https://hanbiro.com/images/kubernetes/2.png" />
                                </div>
                            </div>
                            <div class="content_box right">
                                <div class="content_dt">
                                    <div class="content_dt_text">
                                        <div class="content_text">
                                            <ul>
                                                <li>Hanbiro Kube 프라이빗 쿠버네티스 시스템은 Namespace 를 세분화합니다.</li>
                                                <li>Namespace 의 Sevice mesh 를 기본으로 적재할 수 있도록 설정합니다.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div class="content_dt_text">
                                        <div class="box_icon">
                                            <Search className='icon-search' />
                                        </div>
                                        <div class="content_text">
                                            • Namespace 의 Sevice mesh 를 기본으로 적재할 수 있도록 설정합니다.
                                        </div>
                                    </div> */}
                                    {/* <div class="content_text text-16"><b>Hanbiro Kube 프라이빗 쿠버네티스 시스템</b></div>
                                    <div class="content_text text-pl10">• <b>Namespace</b> 관리: <b>Namespace</b> 를 세분화합니다.</div>
                                    <div class="content_text text-pl10">• <b>Service Mesh</b> 통합: <b>Namespace</b> 의 <b>Service mesh</b> 를 기본으로 적재할 수 있도록 설정합니다.</div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section_bg_ct">
                        <div class="section_bg">
                            <div class="section_title">
                                Hanbiro Kube 프라이빗 쿠버네티스 <br /> 소호형 클러스터
                            </div>

                            <div class="server">
                                <div class="server_box">
                                    <div class="ser_title">
                                        Kubernetes Cluster: Proxmox VE 가상화서버 2대
                                    </div>
                                    <div class="server_info_col">
                                        <div class="server_info">
                                            <div class="server_name">
                                                BareMetal (Proxmox VE <span>Node 01</span>)
                                            </div>
                                            <div class="server_info_dt">
                                                <div class="server_gr">
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box1.png" />
                                                    </div>
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box2.png" />
                                                    </div>
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box3.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="server_info">
                                            <div class="server_name">
                                                BareMetal (Proxmox VE <span>Node 02</span>)
                                            </div>
                                            <div class="server_info_dt">
                                                <div class="server_gr">
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box4.png" />
                                                    </div>
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box5.png" />
                                                    </div>
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box6.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="server_intro">
                                    <div class="intro_box">
                                        <div class="intro_box_dt left">
                                            <div class="intro_text">
                                                <ul>
                                                    <li>Hanbiro Kube 프라이빗 쿠버네티스 소호형 클러스터는 VM 을 Master / Worker Node 로 운영합니다.</li>
                                                    <div class="space"></div>
                                                    <li>VMWARE, Proxmox VE VM 가상화 서버를 사용하며, 최소 1대부터 프라이빗 쿠버네티스 클러스터를 구성할 수 있습니다.</li>
                                                </ul>
                                            </div>
                                            {/* <div class="content_text text_light text-16"><b>Hanbiro Kube 프라이빗 쿠버네티스 소호형 클러스터</b></div>
                                            <div class="content_text text_light text-pl10">• <b>VM을 Master / Worker Node 로 운영:</b> 가상 머신(VM)을 마스터/워커 노드로 운영합니다.</div>
                                            <div class="content_text text_light text-pl10">• 가상화 서버: <b>VMWARE, Proxmox VE VM</b> 가상화 서버를 사용합니다.</div>
                                            <div class="content_text text_light text-pl10">• 최소 구성: 최소 1대부터 프라이빗 쿠버네티스 클러스터를 구성할 수 있습니다.</div> */}
                                        </div>
                                        <div class="intro_box_dt right">
                                            <div class="intro_text">
                                                <ul>
                                                    <li>Proxmox VE 2대를 활용한 소호형 클러스터 </li>
                                                    <li>소규모 팀 및 스타트업에 최적화된 경량형 솔루션  </li>
                                                    <li>소규모 어플리케이션 운영에 적합  </li>
                                                    <li>초기비용절감과 빠른 배포 </li>
                                                    <li>테스트및 개발환경</li>
                                                </ul>
                                                
                                            </div>
                                            {/* <div class="content_text text_light text-16"><b>소호형 클러스터 특징</b></div>
                                            <div class="content_text text_light text-pl10">• <b>Proxmox VE 2</b>대 활용: <b>Proxmox VE 2</b>대를 활용한 소호형 클러스터</div>
                                            <div class="content_text text_light text-pl10">• 최적화 대상: 소규모 팀 및 스타트업에 최적화된 경량형 솔루션</div>
                                            <div class="content_text text_light text-pl10">• 적합한 용도: 소규모 어플리케이션 운영에 적합</div>
                                            <div class="content_text text_light text-pl10">• 장점: 초기 비용 절감과 빠른 배포</div>
                                            <div class="content_text text_light text-pl10">• 사용 환경: 테스트 및 개발 환경</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="background_hide"></div>


                    </div>

                    <div class="section_bground">
                        <div class="section_ser_sub">
                            <div class="section_title">
                                Hanbiro Kube 프라이빗 쿠버네티스 <br /> 확장형 클러스터
                            </div>
                            <div class="server">
                                <div class="server_box">
                                    <div class="ser_title">
                                        Kubernetes Cluster: BareMetal 3<span>~N</span> 대
                                    </div>
                                    <div class="server_info_col">
                                        <div class="server_info">
                                            <div class="server_info_dt">
                                                <div class="server_gr">
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box7.png" />
                                                    </div>
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box8.png" />
                                                    </div>
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box9.png" />
                                                    </div>
                                                </div>
                                                <div class="server_gr_text">
                                                    +N
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="server_box bottom">
                                    <div class="ser_title">
                                        Object Storage(S3) : Proxmox VE 가상화 1<span>~N</span> 대
                                    </div>
                                    <div class="server_info_col">
                                        <div class="server_info">
                                            <div class="server_info_dt">
                                                <div class="server_gr">
                                                    <div class="server_item">
                                                        <img src="https://hanbiro.com/images/kubernetes/box10.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="server_intro">
                                    <div class="intro_box">
                                        <div class="intro_box_dt left">
                                            <div class="intro_text">
                                                <ul>
                                                    <li>Hanbiro Kube 프라이빗 쿠버네티스 확장형 클러스터는 BareMetal 을 Master / Worker
                                                    Node 로 운영하며, Object Storage(S3) 구성은 Proxmox VE VM으로 운영합니다.</li>
                                                    <div class="space"></div>
                                                    <li>프라이빗 쿠버네티스 클러스터 구성은 최소 3대부터 구성 가능합며, Object Storage(S3)는 Proxmox
                                                    VE 1대를 이용하여 최소 구성이 가능합니다.</li>
                                                </ul>
                                            </div>
                                            {/* <div class="content_text  text-16"><b>Hanbiro Kube 확장형 클러스터</b></div>
                                            <div class="content_text text-pl10">• 기본 구성: <b>BareMetal</b>을 <b>Master / Worker Node</b>로 운영하며, <b>Object Storage(S3)</b> 구성은 <b>Proxmox VE VM</b>으로 운영합니다.</div>
                                            <div class="content_text text-pl10">• 최소 구성: 프라이빗 쿠버네티스 클러스터는 최소 <b>3</b>대부터 구성 가능하며, <b>**Object Storage(S3)**</b>는 <b>Proxmox VE 1</b>대를 이용하여 최소 구성이 가능합니다.</div> */}
                                        </div>
                                        <div class="intro_box_dt right">
                                            <div class="intro_text">
                                                <ul>
                                                    <li>BareMetal과 Proxmox VE 를 활용한 확장형 클러스터</li> 
                                                    <li>확장 가능한 아키텍처 중규모 이상의 워크로드를 처리가능한 솔루션</li> 
                                                    <li>중규모 어플리케이션 운영에 적합</li> 
                                                    <li>안정적인 성능 유연성과 확장성 </li>
                                                    <li>마이크로서비스 환경 운영 데이터 처리 및 분석 워크로드</li>
                                                </ul>
                                            </div>
                                            {/* <div class="content_text text-16"><b>확장형 클러스터 특징</b></div>
                                            <div class="content_text text-pl10">• 아키텍처: <b>BareMetal</b>과 <b>Proxmox VE</b>를 활용한 확장 가능한 아키텍처</div>
                                            <div class="content_text text-pl10">• 워크로드 처리: 중규모 이상의 워크로드를 처리 가능한 솔루션</div>
                                            <div class="content_text text-pl10">• 적합한 용도</div>
                                            <div class="content_text text-pl20">• 중규모 어플리케이션 운영에 적합</div>
                                            <div class="content_text text-pl20">• 마이크로서비스 환경 운영</div>
                                            <div class="content_text text-pl20">• 데이터 처리 및 분석 워크로드</div>
                                            <div class="content_text text-pl10">• 성능 및 특징: 안정적인 성능, 유연성 및 확장성</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="section_ser_sub">
                        <div class="section_title">
                            Hanbiro Kube 프라이빗 쿠버네티스 <br /> 엔터프라이즈형 클러스터
                        </div>
                        <div class="server">
                            <div class="server_box">
                                <div class="ser_title">
                                    Kubernetes Cluster: BareMetal 5<span>~N</span> 대
                                </div>
                                <div class="server_info_col">
                                    <div class="server_info">
                                        <div class="server_info_dt">
                                            <div class="server_gr">
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box11.png" />
                                                </div>
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box12.png" />
                                                </div>
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box13.png" />
                                                </div>
                                            </div>
                                            <div class="server_gr_text">
                                                +N
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="server_box mid">
                                <div class="ser_title">
                                    Block Storage : BareMetal 3<span>~N</span> 대
                                </div>
                                <div class="server_info_col">
                                    <div class="server_info">
                                        <div class="server_info_dt">
                                            <div class="server_gr">
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box14.png" />
                                                </div>
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box15.png" />
                                                </div>
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box16.png" />
                                                </div>
                                            </div>
                                            <div class="server_gr_text">
                                                +N
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="server_box bottom">
                                <div class="ser_title">
                                    Object Storage(S3) : BareMetal 5<span>~N</span> 대
                                </div>
                                <div class="server_info_col">
                                    <div class="server_info">
                                        <div class="server_info_dt">
                                            <div class="server_gr">
                                                <div class="server_item">
                                                    <img src="https://hanbiro.com/images/kubernetes/box17.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="server_intro bottom">
                                <div class="intro_box">
                                    <div class="intro_box_dt left">
                                        <div class="intro_text">
                                            <ul>
                                                <li>Hanbiro Kube 프라이빗 쿠버네티스 엔터프라이즈형 클러스터는 BareMetal을 이용하여 Master /
                                                Worker Node 및 Block Storage, Object Storage를 운영합니다.</li>
                                                <div class="space"></div>
                                                <div class="space"></div>
                                                <div class="space"></div>
                                                <li>프라이빗 쿠버네티스 클러스터 구성은 최소 5대부터 구성 가능합며, Block Storage는 5, Object
                                                Storage(S3)는 4대를 이용하여 최소 구성이 가능합니다. (총 BareMetal 13대 필요)</li>
                                            </ul>
                                        </div>
                                        {/* <div class="content_text text-16"><b>Hanbiro Kube 엔터프라이즈형 클러스터</b></div>
                                        <div class="content_text text-pl10">• 구성: <b>BareMetal</b>을 이용하여 <b>Master / Worker Node</b> 및 <b>Block Storage</b>, 
                                        <b>Object Storage</b>를 운영합니다.</div>
                                        <div class="content_text text-pl10">• 최소 구성: 프라이빗 쿠버네티스 클러스터 구성은 최소 <b>5</b>대부터 가능하며, <b>Block Storage</b>는 5대, 
                                        **Object Storage(S3)**는 <b>4</b>대를 이용합니다.</div> */}
                                    </div>
                                    <div class="intro_box_dt right">
                                        <div class="intro_text">
                                            <ul>
                                                <li>BareMetal 13대를 활용한 대규모 클러스터</li>
                                                <li>BareMetal 서버, Ceph 블록 스토리지, MinIO 오브젝트 스토리지로 구성된 대규모 환경 고가용성 및 안정성을
                                                보장하는 분산 스토리지 아키텍처 엔터프라이즈급 애플리케이션 및 데이터 중심 서비스에 적합</li>
                                                <li>엔터프라이즈급 애플리케이션 및 데이터 중심 서비스에 적합</li>
                                                <li>고성능 분산 스토리지 기반 인프라</li>
                                                <li>클라우드 네이티브 애플리케이션 운영 대규모 데이터 처리 및 머신러닝 워크로드 대규모의 마이크로서비스기반 서비스를 제공하는 기업</li>
                                            </ul>
                                        </div>
                                        {/* <div class="content_text text-16"><b>대규모 클러스터 특징</b></div>
                                        <div class="content_text text-pl10">• 인프라: <b>BareMeta</b>l 서버, <b>Ceph</b> 블록 스토리지, <b>MinIO</b> 오브젝트 스토리지로 구성된 대규모 환경</div>
                                        <div class="content_text text-pl10">• 장점: 대규모 환경 고가용성 및 안정성을 보장하는 분산 스토리지 아키텍처</div>
                                        <div class="content_text text-pl10">• 적합 대상: 엔터프라이즈급 애플리케이션 및 데이터 중심 서비스에 적합</div>
                                        <div class="content_text text-pl10">• 인프라 특징: 고성능 분산 스토리지 기반 인프라</div>
                                        <div class="content_text text-pl10">• 활용 분야: 클라우드 네이티브 애플리케이션 운영, 대규모 데이터 처리 및 머신러닝 워크로드, 대규모의 마이크로서비스 기반 서비스를 제공하는 기업</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="blue_bg">
                        <img src="https://hanbiro.com/images/kubernetes/bg_up.png" />
                    </div>

                    <div class="section_bottom_gr">
                        <div class="banner_bottom">
                            <div class="section">
                                <div class="section_title">
                                    Hanbiro Kube 프라이빗 쿠버네티스 <br /> 모니터링 상세구조
                                </div>
                                <div class="seciton_content">
                                    <div class="content_box left">
                                        <div class="image_box">
                                            <img src="https://hanbiro.com/images/kubernetes/7.png" />
                                        </div>
                                    </div>
                                    <div class="content_box right">
                                        <div class="content_dt">
                                            <div class="content_text text_light">
                                                <ul>
                                                    <li>Hanbiro Kube 프라이빗 쿠버네티스 시스템은 Kubernetes 의 pod 상태를 실시간 모니터링하고 신속한 장애처리가 가능하도록 지원합니다.</li>
                                                    <li>모니터링 POD에 의해 수집된 데이터는 한비로 Object Storage에 일정 기간 <br /> 저장되며, 해당 데이터를 이용해 로그 및 각종 수치를 시각화하여 제공합니다.</li>
                                                    <li>POD 상태를 실시간 모니터링하여 장애 발생시 Push, Email 등을 이용하여 <br /> 엔지니어에게 전달하여 빠른 장애 처리를 수행 할 수 있게합니다.</li>
                                                </ul>
                                            </div>
                                            {/* <div class="content_text text_light text-16"><b>Hanbiro Kube 프라이빗 쿠버네티스 시스템 모니터링 및 장애 처리</b></div>
                                            <div class="content_text text_light text-pl10">• 실시간 모니터링: <b>Kubernetes</b> 의 <b>pod</b> 상태를 실시간 모니터링하고 신속한 장애 처리가 가능하도록 지원합니다.</div>
                                            <div class="content_text text_light text-pl10">• 데이터 저장 및 시각화: 모니터링 <b>POD</b>에 의해 수집된 데이터는 한비로 <b>Object Storage</b>에 일정 기간 저장되며, 해당 데이터를 이용해 로그 및 각종 수치를 시각화하여 제공합니다.</div>
                                            <div class="content_text text_light text-pl10">• 장애 알림 및 처리: <b>POD</b> 상태를 실시간 모니터링하여 장애 발생시 <b>Push, Email</b> 등을 이용하여 엔지니어에게 전달하여 빠른 장애 처리를 수행할 수 있게 합니다.</div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="line banner-blue"></div>

                            <div class="section section_bottom_dt">
                                <div class="section_title">
                                    Hanbiro Kube 프라이빗 쿠버네티스 <br /> DB Cluster 상세구조
                                </div>
                                <div class="seciton_content">
                                    <div class="content_box left">
                                        <div class="image_box">
                                            <img src="https://hanbiro.com/images/kubernetes/8.png" />
                                        </div>
                                    </div>
                                    <div class="content_box right">
                                        <div class="content_dt">
                                            <div class="content_text text_light">
                                                <ul>
                                                    <li>Hanbiro Kube 프라이빗 쿠버네티스 시스템은 Operator를 이용하여 DB Cluster의 관리를 자동화합니다.</li>
                                                    <li>서비스 부하에 따른 Autoscaling Up/Down 기능을 제공하여 이벤트등에 의한 과부하 장애로 부터 안전합니다.</li>
                                                </ul>
                                            </div>
                                            {/* <div class="content_text text_light text-16"><b>Hanbiro Kube 프라이빗 쿠버네티스 시스템</b></div>
                                            <div class="content_text text_light text-pl10">• <b>DB</b> 클러스터 관리 자동화: <b>Operator</b>를 이용하여 <b>DB Cluster</b>의 관리를 자동화합니다.</div>
                                            <div class="content_text text_light text-pl10">• 자동 스케일링: 서비스 부하에 따른 <b>Autoscaling Up/Down</b> 기능을 제공하여 이벤트 등에 의한 과부하 장애로부터 안전합니다.</div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blue_bg">
                            <img src="https://hanbiro.com/images/kubernetes/bg_down.png" />
                        </div>

                        <div class="bg_bottom_ct">
                            <div class="bg_bottom">
                                <div class="section">

                                    <div class="section_title">
                                        Hanbiro Kube 프라이빗 쿠버네티스 <br /> Persistant Volume 운영
                                    </div>
                                    <div class="content_box">
                                        <div class="image_box">
                                            <img src="https://hanbiro.com/images/kubernetes/12.png" />
                                        </div>

                                        <div class="intro_box_dt">
                                            {/* <div class="intro_text">Hanbiro Kube 프라이빗 쿠버네티스 기본시스템은 Local 볼륨을 사용합니다.
                                            </div>
                                            <div class="intro_text">openEBS 의 Hostpath 또는 cStor 볼륨을 사용하며, 무결성의 데이터복제와 고성능의 I/O 를
                                                구현합니다.
                                            </div>
                                            <div class="intro_text">클러스터 확장을 위한 스토리지는 Ceph 블록 스토리지를 지원합니다.</div> */}
                                            <div class="content_text text_light text-pl10">
                                                <ul>
                                                    <li>Hanbiro Kube 프라이빗 쿠버네티스 기본시스템은 Local 볼륨을 사용합니다.</li>
                                                    <li>openEBS 의 Hostpath 볼륨엔진을 사용하여 고성능의 I/O 를 구현합니다.</li>
                                                    <li>클러스터 확장을 위한 스토리지는 Ceph 블록 스토리지를 지원합니다.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="section">
                                    <div class="section_title">
                                        Hanbiro Kube 프라이빗 쿠버네티스 <br /> Persistant Volume 복제
                                    </div>
                                    <div class="content_box">
                                        <div class="image_box">
                                            <img src="https://hanbiro.com/images/kubernetes/9.png" />
                                        </div>

                                        <div class="intro_box_dt">
                                            {/* <div class="intro_text">Hanbiro Kube 프라이빗 쿠버네티스 기본시스템은 Local 볼륨을 사용합니다.
                                            </div>
                                            <div class="intro_text">openEBS 의 Hostpath 또는 cStor 볼륨을 사용하며, 무결성의 데이터복제와 고성능의 I/O 를
                                                구현합니다.
                                            </div>
                                            <div class="intro_text">클러스터 확장을 위한 스토리지는 Ceph 블록 스토리지를 지원합니다.</div> */}
                                            {/* <div class="content_text text_light text-16"><b>Hanbiro Kube 프라이빗 쿠버네티스 기본 시스템 스토리지</b></div> */}
                                            
                                            <div class="content_text text_light text-pl10">
                                                <ul>
                                                    <li>OpenEBS cStor 볼륨을 사용하여, 무결성의 데이터복제와 고성능 I/O 를 구현합니다.</li>
                                                    <li>클러스터확장을 위한 Ceph 블록스토리지를 지원합니다</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>

                    <div className="section cluster">
                        <div class="section_title">
                            한비로 백오피스 Kubernetes Cluster 구성도
                        </div>

                        <div className="cluster_block">
                            <div class="image_box">
                                <img src="https://hanbiro.com/images/kubernetes/kube14.png" />
                            </div>
                        </div>
                    </div>

                    <div className="section cluster pod">
                        <div class="section_title">
                            한비로 백오피스 Kubernetes Cluster 세부구조
                        </div>

                        <div className="cluster_block">
                            <div class="image_box">
                                <img src="https://hanbiro.com/images/kubernetes/kube15.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>


        </div>
    );
}

export default Kubernetes;