import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import '../../Style/defaultstyle.css'
import './styleOutsourcingIT.css';
import { FaUsers } from "react-icons/fa"
import { GrSettingsOption } from "react-icons/gr"
import { AiFillFund } from "react-icons/ai"
import { MdLocationCity } from "react-icons/md"
import { Typography } from '@mui/material';

function Product() {

  // IT 인력 아웃소싱 서비스 목표
  const outsourcingIT = {
    data: [
      {
        icon: <FaUsers />,
        text: "자사 개발인력 확보",
      },
      {
        icon: <AiFillFund />,
        text: "분야별 개발인력확보 (한국개발자와의 협업개발자 인력확보)",
      },
      {
        icon: <GrSettingsOption />,
        text: "한국에서 서비스 분석, 설계 전개후 Front-End, 퍼블리셔, 운영관리인력 확보",
      },
      {
        icon: <MdLocationCity />,
        text: "베트남 또는 해외 사업을 위한 R&D 연구소설립  (프로토타입 또는 협업)",
      },

    ],
  }

  // Hanbiro 서비스 영역
  const hanbiroCol1 = {
    data: [
      {
        text: "IT 인력확보",
      },
      {
        text: "인큐베이팅",
      },
      {
        text: "분야별 커리큘럼 진행",
      },
      {
        text: "비대면협업교욕",
      }

    ],
  }

  const hanbiroCol2 = {
    data: [
      {
        text: "인사관리대행 (근태, 휴가)",
      },
      {
        text: "IT 전담인력 고객사 배정",
      },
      {
        text: "고객사 협업업무지원",
      },
      {
        text: "기획 및 플래닝 프로토타입구성",
      }

    ]
  }

  // Hanbiro 서비스 영역
  function AddStyle(object) {
    const totalrow = object.length;
    for (let i = 0; i < totalrow; i++) {
      if (i === 0) {
        object[i]["style"] = "box-hanbiro-fi"
      } else if (i === totalrow - 1) {
        object[i]["style"] = "box-hanbiro-en"
      } else {
        object[i]["style"] = "box-hanbiro-be"
      }
    }
  }

  // Hanbiro 서비스 영역
  function hanbiroData(col, pushHan) {
    Object.keys(col).forEach(function (rowKey) {
      AddStyle(col[rowKey])

      let columns = col[rowKey].map((item) =>
        <>
          <BoxHanbiro className={item.style}>
            <BoxTexthan className="list-item">
              <b >{item.text}</b>
            </BoxTexthan>
          </BoxHanbiro>
        </>
      );
      pushHan.push(<>{columns}</>);
    });

  }

  let listHanbiroCol1 = [];
  let listHanbiroCol2 = [];
  hanbiroData(hanbiroCol1, listHanbiroCol1)
  hanbiroData(hanbiroCol2, listHanbiroCol2)


  // IT 인력 아웃소싱 서비스 목표 
  let listBox1 = [];
  Object.keys(outsourcingIT).forEach(function (rowKey) {
    let columns = outsourcingIT[rowKey].map((item) =>
      <>
        <Col xs={12} md={6} lg={5} xl={5}>
          <BoxOutsource className="parcardtext">
            <BoxIcon >
              {item.icon}
            </BoxIcon>
            <BoxText className="cardtext">
              <span dangerouslySetInnerHTML={{ __html: item.text }}></span>
            </BoxText>
          </BoxOutsource>
        </Col>
      </>
    );
    listBox1.push(<>{columns}</>);
  });



  return (
    <>
      <Container>

        {/* IT 인력 아웃소싱 서비스 목표 */}
        <BoxHr> </BoxHr>
        <BoxContainer>
          <BoxIT className='title_outsourcing_font'>IT 인력 아웃소싱 서비스 목표 </BoxIT>
          <Row className="box-col-4">
            {listBox1}
          </Row>
        </BoxContainer>


        {/* IT 인력 아웃소싱 서비스 도입절차 */}
        <BoxHr> </BoxHr>
        <BoxContainerIT className="box-container-it">
          <BoxIT className='title_outsourcing_font'><span >IT 인력 아웃소싱 </span> <br className="title-wb" /><span >서비스 도입절차</span> </BoxIT>
          <Row>
            <Col xs={12} sm={12} lg={3} xl={3}>
              <BoxParOne className="box-par-one">
                <BoxTop className="box-top-firt"><Typography sx={CardTitle}>고객사</Typography></BoxTop>
                <BoxBot className="box-bot">고객사 소프트웨어 기획</BoxBot>

                <ArrowDown1 className="arrow-down-4">
                  <ArrowDown1Con className="long-arrow-down-2"></ArrowDown1Con>
                </ArrowDown1>


                <ArrowRightBot className="arrow-right-bot">
                  <ArrowSubOne className="arrow-sub-one">
                    <ArrowSubOneSub className="long-arrow-right-1"></ArrowSubOneSub>
                    <ArrowSubTwo className="arrow-sub-two"></ArrowSubTwo>
                  </ArrowSubOne>
                </ArrowRightBot>

              </BoxParOne>
            </Col>
            <Col xs={12} sm={12} lg={3} xl={3}>
              <BoxBackground className="box-back-ground">
                <BoxContText className="box-cont-text">고객사 또는 한비로 Planning 인력배정</BoxContText>
                <BoxCon className="box-con-1">
                  UI 프로토타입구성 (Figma)
                  <ArrowDown1 className="arrow-down-1">
                    <ArrowDown1Con className="long-arrow-down-1"></ArrowDown1Con>
                  </ArrowDown1>
                </BoxCon>
                <BoxCon className="box-con-2">
                  UI 개선 / 상세 UI 추가 및 설명 추가
                  <ArrowDown1 className="arrow-down-1">
                    <ArrowDown1Con className="long-arrow-down-1"></ArrowDown1Con>
                  </ArrowDown1>
                </BoxCon>

                <BoxCon className="box-con-3">
                  UI / UX / 기능, 상세 기획 완료
                  <ArrowDown1 className="arrow-down-3">
                    <ArrowDown1Con className="long-arrow-down-2"></ArrowDown1Con>
                  </ArrowDown1>
                </BoxCon>

                <ArrowRightBot className="arrow-right-bot-1">
                  <ArrowSubOne className="arrow-sub-one">
                    <ArrowSubOneSub className="long-arrow-right-3"></ArrowSubOneSub>
                    <ArrowSubTwo className="arrow-sub-two-1"></ArrowSubTwo>
                  </ArrowSubOne>
                </ArrowRightBot>

              </BoxBackground>
            </Col>
            <Col xs={12} sm={12} lg={3} xl={3}>
              <BoxParOne className="box-par-one-3">
                <BoxTop className="box-top-firt-2">한비로</BoxTop>
                <BoxBot className="box-bot-2">개발인력 배정 </BoxBot>

                <ArrowDown1 className="arrow-down-4">
                  <ArrowDown1Con className="long-arrow-down-2"></ArrowDown1Con>
                </ArrowDown1>

                <ArrowRightBot className="arrow-right-bot-4">
                  <ArrowSubOne className="arrow-sub-one">
                    <ArrowSubOneSub className="long-arrow-right-4"></ArrowSubOneSub>
                    <ArrowSubTwo className="arrow-sub-two-4"></ArrowSubTwo>
                  </ArrowSubOne>
                </ArrowRightBot>
              </BoxParOne>
            </Col>
            <Col xs={12} sm={12} lg={3} xl={3}>
              <BoxParOne className="box-par-one-1">
                <BoxTop className="box-top-firt-3"><Typography sx={CardTitle}>고객사</Typography></BoxTop>
                <BoxBotTwo className="box-bot-3">개발시작 (고객사에 배정된 개발자)</BoxBotTwo >
                <BoxBotTwo className="box-bot-4">개발상세진행 / 운영 </BoxBotTwo >
                <ArrowDown1 className="arrow-down-5">
                  <ArrowDown1Con className="long-arrow-down-5"></ArrowDown1Con>
                </ArrowDown1>
              </BoxParOne>
            </Col>

          </Row>
        </BoxContainerIT>

        {/* Hanbiro 서비스 목표 */}
        <BoxHr> </BoxHr>
        <BoxContainer>
          <BoxIT className='title_outsourcing_font'>Hanbiro 서비스 영역</BoxIT>
          <Row className="box-hanbiro-it">
            <Col xs={12} md={6} lg={5} xl={5} className='hanbiro-col'>
              {listHanbiroCol1}
            </Col>
            <Col xs={12} md={6} lg={5} xl={5}>
              {listHanbiroCol2}
            </Col>
          </Row>
        </BoxContainer>

      </Container>




    </>
  );
}

const CardTitle = {
  color: "#2A6AF9", 
  fontWeight: "bold",
  fontFamily: "맑은고딕, Dotum, 돋움, sans-serif !important;"
}

const BoxContainer = styled.div`
  width: 100%;
  margin-bottom: 80px;
`
const BoxContainerIT = styled.div`
  width: 100%;
  margin-bottom: 120px;
  margin-top: 80px;
`
const BoxIT = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin: 50px 0;
`
const BoxOutsource = styled.div`
  border-radius: 5px;
  // background-color: #fff;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 25px;
  height: 90px;
`

const BoxText = styled.p`
  padding: 0 15px;
  margin-bottom: 0;
  font-weight: 700;
`

const BoxIcon = styled.div`
  font-size: 25px;
`

const BoxHr = styled.div`
  width: 100%;
  border-top: 1px solid #CACBCF;
`

const BoxHanbiro = styled.div`
  
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
`

const BoxTexthan = styled.p`
  
  margin-bottom: 0;
  text-align: center !important;
`

const BoxBot = styled.div`
width: 75%;
`
const BoxBotTwo = styled.div`
width: 100%;
`
const BoxTop = styled.div`
  width: 55%;
`

const BoxCon = styled.div`
  width: 110%;
  border-radius: 10px;
  border: 1.5px solid black;
  padding: 10px 0;
  text-align: center;
  background: white;
  margin: auto;
  font-weight: 700;
  box-shadow: 5px 5px 0px #BBBBBF!important;
`
const BoxBackground = styled.div`
  width: 106%;
`
const BoxContText = styled.div`
  color: #2A6AF9;
  font-weight: 700;
`
const BoxParOne = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
`

const ArrowDown1 = styled.div`
  display: block;
`
const ArrowDown1Con = styled.div`
  display: block;
`
const ArrowRightBot = styled.div`
  display: block;
`
const ArrowSubOne = styled.div`
  display: block;
`
const ArrowSubOneSub = styled.div`
  display: block;
`
const ArrowSubTwo = styled.div`
  display: block;
`
export default Product;
