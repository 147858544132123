// import React, { Component } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// class Map extends Component {
//   googleGeocoder = null;
//   constructor(props) {
//     super(props);
//     this.state = {
//       locationName: '',
//       center: {
//         lat: 10.76586,
//         lng: 106.74723
//       },
//       isShowMarker: true
//     };
//   }
//   changeLocationName(e) {
//     if (e.key === 'Enter') {
//       this.geocode();
//       return;
//     }
//     this.setState({
//       locationName: e.target.value
//     });
//   }
//   geocode() {
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode(
//       { address: this.state.locationName },
//       (results, status) => {
//         if (status === 'OK') {
//           let center = Object.assign({}, this.state.center);
//           center = {
//             lat: results[0].geometry.location.lat(),
//             lng: results[0].geometry.location.lng()
//           };
//           this.setState({
//             center,
//             isShowMarker: true
//           });
//         }
//       }
//     );
//   }
//   render() {

//     const containerStyle = {
//       width: '100%',
//       height: '20vh',
//       borderRadius: '15px'
//     };


//     return (

//       <LoadScript googleMapsApiKey="AIzaSyA5MmQP5SOgZNU8n3VlOjFiQNLboo4dVtc">
//         <GoogleMap
//           mapContainerStyle={containerStyle}
//           center={this.state.center}
//           zoom={15}
//         >
//           {this.state.isShowMarker && <Marker position={this.state.center} />}


//         </GoogleMap>
//       </LoadScript>
//     );
//   }
// }

// export default Map;

import React from "react";
import styled from 'styled-components';

function Map() {
  return (
    <>
      <BoxCMap id="map1">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15678.387646621051!2d106.7469243!3d10.7655166!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175252ece1f3117%3A0xdb1122b0cd981720!2sHanbiro%20Vietnam!5e0!3m2!1sen!2s!4v1679046355498!5m2!1sen!2s" width="100%" height="300px" style={{ borderRadius: '15px'}} allowfullscreen="" id="iFrameResizer0" ></iframe>
      </BoxCMap>

    </>


  );
}


const BoxCMap = styled.div`
  width: 100%;
`

export default Map;

