

import React from 'react';
import TrafficFee from './TrafficFeeV1';
import './style.css';
import '../../Style/defaultstyle.css'
import '../../Style/defaultstyle.scss'

const ProtitleNew = (params) => {
  return (
    <table style={params.classNameWrapper}>
      <tr style={params.classNameItem}>
        <td style={params.PadProTitle}>
          <text style={StySpecification.ColorText} className='sub-title'>{params.Title} </text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
          <text style={params.ConTitle}><span  >{params.Core} </span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span  >{params.G} </span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span  >{params.SD}</span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span  >{params.SG}</span></text>
        </td>
      </tr>
      <tr style={params.Tableborderlast}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span  >{params.원}</span></text>
        </td>
      </tr>
    </table>
  )
}

const Protitle = (params) => {
  return (
    <table style={params.classNameWrapper}>
      <tr>
        <td style={params.PadProTable} >
          <text style={StySpecification.ColorText} className='sub-title'>&nbsp;</text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
          <text style={params.ConTitle}><span  >{params.Core} </span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span  >{params.G} </span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span  >{params.SD}</span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span  >{params.SG}</span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span  >{params.원}</span></text>
        </td>
      </tr>
    </table>
  )
}

function ServerPricing({ title, content, content1 }) {
  return (
    <>
      <div >
        <div style={StySpecification.StylesContainer}>
          <div >
            <div >
              <h2 style={StySpecification.Title} className='header'>{title}</h2>
              {(content && content1) && <>
                <p style={StySpecification.Text} className='sub-title'>
                  <text>{content}
                  </text>
                  <br />
                  <text>{content1}</text>
                </p>
              </>
              }
            </div>
          </div>
          <div  style={StySpecification.TableScroll}>
            <div  style={StySpecification.TableWrap}>
              <div style={StySpecification.ListProduct}>

                {Protitle({
                  classNameWrapper: StySpecification.InfPro,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTable: StySpecification.PadProTable,
                  PadProtit: StySpecification.PadProtit,
                  ConTitle: StySpecification.ConTitle,
                  // Title: "",
                  Core: "CPU",
                  G: "Memory",
                  SD: "Storage",
                  SG: "Traffic 전송량(월)",
                  원: "서비스비용(월)"

                })}

                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "소호형 상품",
                  Core: "1 Core",
                  G: "4G",
                  SD: "고성능 로컬볼륨 60G",
                  SG: "900G",
                  원: "17,500 원"

                })}
                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "스탠다드 상품",
                  Core: "2 Core",
                  G: "8G",
                  SD: "고성능 로컬볼륨 150G",
                  SG: "900G",
                  원: "35,000 원"

                })}
                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "비지니스",
                  Core: "4 Core",
                  G: "16G",
                  SD: "고성능 로컬볼륨 400G",
                  SG: "900G",
                  원: "70,000 원"

                })}

                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "엔터프라이즈형",
                  Core: "8 Core",
                  G: "32G",
                  SD: "고성능 로컬볼륨 700G",
                  SG: "900G",
                  원: "150,000 원"

                })}
              </div>

            </div>
          </div>
          <TrafficFee/>
        </div>
      </div>
    </>

  );
}


const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
    
  },
  ConTitle: {
    color: "black",
  },
  StylesTitle: {
    textAlign: "center",
  },
  Title: {
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  ConTitle:{
    color:"black",
  },
  Text: {
    fontWeight: 600,
    marginBottom: "30px",
  },
  ColorText: {
    color: "white",
  },
  ProductTitle: {
    background: "#393939",
    fontWeight: "bold",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    // paddingTop: 8,
    // paddingBottom: 8,

  },
  Tableborder: {
    borderLeft: "1px solid #D7E5ED",
    borderRight: "1px solid #D7E5ED",
  },
  Tableborderlast: {
    borderLeft: "1px solid #D7E5ED",
    borderRight: "1px solid #D7E5ED",
    borderBottom: "1px solid #D7E5ED",
  },
  ProTitleLeft: {
    fontWeight: 500,
    paddingTop: 8,
    paddingBottom: 8,
  },
  InfProduct: {
    borderRadius: "8px",
    width: "20%",
    minWidth: "170px",
    marginLeft: 20,
    // border: "1px solid #D7E5ED",
    whiteSpace: "nowrap",
    
  },
  InfPro: {
    visibility:"visible",
    borderRadius: "8px",
    width: "20%",
    textAlign: "right",
    fontWeight: 700,
    whiteSpace: "nowrap",
    minWidth: "130px",
  },

  ListProduct: {
    display: "flex",
  },
  PadPro: {
    padding: "12px 0",
  },
  PadProtit: {
    padding: "6px 0",
  },
  PadProTable: {
    padding: "10px 0",
  },
  PadProTitle: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    background: "unset",
  },
  Auto: {
    backgroundColor: "lightblue",
    width: "150px",
    overflowX: "scroll",

  },

  TableScroll:{
    position:"relative",
    width: "100%",
    margin:"auto",
    overflow:"hidden",
  },
  TableWrap:{
    width:"100%",
    overflow:"auto",
  }
}

export default ServerPricing;
