
import language from '../../../../translations/I18n';
import { useTranslation } from "react-i18next";
function NavButton() {
  const { t } = useTranslation();
  const onchange = (event) => {
    language.changeLanguage(event.target.value);
  }
  
  return (
    <>
      
      <h1 dangerouslySetInnerHTML={{ __html: t('head.title') }}></h1>
      <select name="language" onChange={onchange}>
        <option value="en">English</option>
        <option value="kor">Kor</option>
      </select>
    </>
  );
}

export default NavButton;