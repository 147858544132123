import * as React from 'react';
import styled from 'styled-components';
import '../components/style.css'
import {
    Card, CardBody,
  } from '@/shared/components/Card';

function ImageLoGo ($imagecloud) {
    return (
        <GridCardBody>
            <CardImage className="sizename" src={$imagecloud}></CardImage>
        </GridCardBody>
    )
}

export default ImageLoGo;

const GridCardBody = styled(CardBody)`
padding: 0;
`;

const CardImage = styled(Card.Img)`
    text-align: center;
`;