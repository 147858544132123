import '../cloud-services/App.css';
import React from 'react';
import styled from 'styled-components';
import {
  Container, Col,
} from 'react-bootstrap';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import CloudIcon from 'mdi-react/CloudIcon';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";

/* eslint-disable */

function CloudServicesGrid ($title) {
  return (
    <GridCardBody className='row-box-title-1-3'>
      <div className='home-service-icon'>
          <CloudIcon size={30} className="icon-CloudIcon"/>
      </div>
      <div className='home-service-title texttitlesercard'>
        <Box dangerouslySetInnerHTML={{ __html: $title }}></Box>

      </div>
    </GridCardBody>
  )
}

function CloudServicesGridBorderNone($key, $name, $description) {
  return (
    <GridCardBody className="row-box-intro">
      <div className="home-service-name">
        <Link to={`/${$key}`}>
          <Box dangerouslySetInnerHTML={{ __html: $name }}></Box>

        </Link>
      </div>
      <Box className="home-service-description cardsertext" dangerouslySetInnerHTML={{ __html: $description }}></Box>
    </GridCardBody>
  );
}

function CloudServicesGridHidden () {
  return (
    <GridCardBody className='col-data-app-hidden'>
      <Box> </Box>
    </GridCardBody>
  )
}

function CloudServices() {
  
  return (
    <div className='cloudservice'>
      <Container className='table-cloudservices'>
        <Box mr={3} className='ServerBox'>
          <Col>
            <GridCard>
              {CloudServicesGrid ("Server")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("compute-vps#server","가상서버 (인스턴스)","Cloud 기반 가상서버")}
            </GridCard>
          </Col>
          {/*
          <Col sm={12}>
            <GridCard>
              {CloudServicesGridBorderNone ("compute-bare-metal#server","베어메탈 서버 (단독서버)","Cloud 기반 독립서버")}
            </GridCard>
          </Col>
          */}
          <Col sm={12}>
            <GridCard>
              {CloudServicesGridBorderNone ("compute-auto-scale#server","오토스케일링","가상서버(인스탄스) 자동확장 서비스")}
            </GridCard>
          </Col>
          <Col sm={12}>
            <GridCard>
              {CloudServicesGridBorderNone ("compute-load-balance#server","로드발랜서","클라우드 서버부하분산 서비스")}
            </GridCard>
          </Col>
          <Col sm={12}>
            <GridCard>
              {CloudServicesGridBorderNone ("compute-ms#server","MS-SQL/Windows","윈도우 기반 가상서버(인스턴스)")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("compute-database#server","Database(인스턴스)","가상서버(인스턴스)기반 데이터베이스")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridHidden ()}
            </GridCard>
          </Col>
        </Box>
        <Box mr={3} className='ServerBox'>
          <Col>
            <GridCard>
              {CloudServicesGrid ("Kubernetes Cluster")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-elastic-search#cluster","Cloud ElasticSearch","쿠버네티스 클러스터 검색엔진")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
            {CloudServicesGridBorderNone ("cluster-kafka#cluster","Cloud Kafka","실시간 데이터 스트리밍 처리")}
            </GridCard>
          </Col>
          {/*<Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-rabbitmq#cluster","Cloud RabbitMQ","AMQP 메세지 브로커 미들웨어")}
            </GridCard>
        </Col>*/}
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-mysql-db#cluster","Cloud for MySQL","Master-Slave Replication, Slave 자동 생성, 고 가용성")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-postgresql-db#cluster","Cloud for PostgreSQL","Master-Slave Replication, Slave 자동 생성, 고 가용성")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-vitess#cluster","Cloud for Vitess","자동 Sharding으로 수직/수평 확장")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-mongodb#cluster","Cloud for MongoDB","Document 방식의 NoSQL, 분산 Recplica set구축 제공")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-redis#cluster","Cloud for Redis","자동 Failover 구축으로 안정성, 자동 Shard 및 클러스터링 지원")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-cloud-scylladb#cluster","Cloud for SycllaDB","빠른 읽기 쓰기 NoSQL, 기본 3 노드 클러스터링 제공")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("cluster-clickhouse#cluster","Cloud for ClickHouse","OLAP를 위한 컬럼지향형 DB")}
            </GridCard>
          </Col>
        </Box>
        <Box mr={3} className='ServerBox'>
          <Col>
            <GridCard>
              {CloudServicesGrid ("Serverless Application")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa2#microservice","마이크로서비스 필수요소","CQRS / Event Sourcing / API Gateway")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3#microservice","어플리케이션 통합과 서비스","백엔드용 서버리스 어플리케이션 서비스 목록")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/iaam#microservice","Identity and Access Management","OAuth, RBAC, 사용자 역할 및 액세스 권한 관리")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/messaging-service#microservice","Messaging Service","MSA 서비스간 고속의 데이터 처리를 위한 서비스")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/event-sourcing#microservice","Event Sourcing","CQRS 를 구현하는 서비스형 Serverless 서비스")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/webhook#microservice","Webhook","트리거된 이벤트 발생시 등록된 URL의 프로그램 실행")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/long-running#microservice","Long-Running","비동기적 요청 - 응답(Asynchronous Request-Reply)패턴")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/simple-url-shortener#microservice","Simple URL Shortener","긴 URL 주소를 짧고 쉬운 URL로 제공")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridBorderNone ("msa3/simple-storage-service#microservice","S3 (Simple-Storage-Service)","Object Storage용 API와 클라이언트 라이브러리")}
            </GridCard>
          </Col>
        </Box>
        <Box mr={3} className='ServerBox'>
          <Col>
            <GridCard>
              {CloudServicesGrid ("Storage")}
            </GridCard>
          </Col>
          <Col>
          <GridCard>
              {CloudServicesGridBorderNone ("store-object-store#storage","오브젝트 Storage","S3 지원되는 파일 저장용 분산 스토리지")}
            </GridCard>
          </Col>
          <Col>
          <GridCard>
          {CloudServicesGridBorderNone ("store-block-store#storage","불록 Storage","인스턴스와 POD에 연결된 고성능 블록 스토리지")}
            </GridCard>
          </Col>
          <Col>
          <GridCard>
              {CloudServicesGridBorderNone ("store-nas-store#storage","공유 Storage","클러스터 전용 공유스토리지")}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridHidden ()}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridHidden ()}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridHidden ()}
            </GridCard>
          </Col>
          <Col>
            <GridCard>
              {CloudServicesGridHidden ()}
            </GridCard>
          </Col>
        </Box>
        </Container>
    </div>
  );
}

const GridCard = styled(Card)`
  padding-bottom: 10px;

  p {
    margin-bottom: 0;
  }
`;

const GridCardBody = styled(CardBody)`
  padding: 0;
  text-align: left;
`;




export default CloudServices;