import React, { useEffect, useLayoutEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import NavButton from '../homepage/header-banner/TopNavButton';
import Footer from '../homepage/footer/Footer';
import { Check } from '@mui/icons-material';
import { ArrowRightAlt } from "@mui/icons-material";
import { Search } from '@mui/icons-material';
import './assets/css/style-demo.css';
import { array } from "prop-types";
// import './assets/js/scrip-demo'

function IndexKo() {
    useLayoutEffect(() => {
        const titles = document.querySelectorAll(".accordion-title");
        const accordionItems = document.querySelectorAll(".accordion-item");
        const thumbScroll = document.querySelector(".thumb-scroll");
        const verticalLine = document.querySelector(".vertical-line");
        const imageDisplay = document.getElementById("displayed-image");
        const videos = document.querySelectorAll(".video-player");

        function checkVideos() {
            videos.forEach(video => {
                video.muted = true;
                let rect = video.getBoundingClientRect();
                let isVisible = rect.top < window.innerHeight && rect.bottom > 0;

                if (isVisible && video.paused) {
                    video.play();
                } else {
                    video.pause();
                }
            });
        }

        window.addEventListener("load", checkVideos);

        setTimeout(() => {
            requestAnimationFrame(checkVideos);
        }, 200);

        window.addEventListener("scroll", checkVideos);

        let tabs = document.querySelectorAll('[data-bs-toggle="pill"]');
        tabs.forEach(tab => {
            tab.addEventListener("shown.bs.tab", function () {
                let activeTabPane = document.querySelector(".tab-pane.active");
                if (!activeTabPane) return;

                let videos = activeTabPane.querySelectorAll(".video-player");
                videos.forEach(video => {
                    video.pause();
                    video.currentTime = 0;
                    video.play().catch(error => {
                        console.warn("Autoplay bị chặn sau reset, cần tương tác người dùng.", error);
                    });
                });
            });
        });

        function updateScrollThumb() {
            const activeItem = document.querySelector(".accordion-item.active");
            if (!activeItem || !thumbScroll || !verticalLine) return;

            const activeTitle = activeItem.querySelector(".accordion-title");
            if (!activeTitle) return;

            const titleRect = activeTitle.getBoundingClientRect();
            const verticalLineRect = verticalLine.getBoundingClientRect();

            const newTop = titleRect.top - verticalLineRect.top + (activeTitle.clientHeight / 2) - (thumbScroll.clientHeight / 2);
            thumbScroll.style.top = `${newTop}px`;
        }

        const handleClick = function () {
            const item = this.parentElement;
            const content = item.querySelector(".accordion-content");
            if (!content || !imageDisplay) return;

            document.querySelectorAll(".accordion-content").forEach(c => c.style.display = "none");
            document.querySelectorAll(".accordion-item").forEach(i => i.classList.remove("active"));

            content.style.display = "block";
            item.classList.add("active");

            if (item.hasAttribute("data-image")) {
                imageDisplay.src = item.getAttribute("data-image");
                imageDisplay.style.display = "block";
            }

            updateScrollThumb();
        };

        if (titles.length > 0) {
            titles.forEach(title => title.addEventListener("click", handleClick));
        }

        updateScrollThumb();

        return () => {
            titles.forEach(title => title.removeEventListener("click", handleClick));
            window.removeEventListener("scroll", checkVideos);
            window.removeEventListener("load", checkVideos);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#47A7FF' },
                itemNavClass: "item-nav",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />

            {/* banner */}
            <div class="d-banner section-box">
                <div class="banner-block banner-text" data-aos="fade-right" data-aos-duration="1000">
                    <div>
                        <div class="text-sz40 mb-4">기업의 IT 환경 구축과 운영이 <br />복잡하게 느껴지시나요?</div>
                        <div class="text-sz16">인프라 구축부터 소프트웨어 , 엔지니어, AI협업까지 한번에 해결하는<br />
                            맞춤형 클라우드 기반의 최적화된 IT 솔루션을 제공합니다.</div>
                    </div>

                    <div class="contact-us">
                        <button class="btn-more btn-sz">
                            Get Started
                        </button>
                        <button class="contact-btn btn-sz light">
                            Pricing
                            <span className="btn-icon"><ArrowRightAlt /></span>
                        </button>
                    </div>
                </div>

                <div class="banner-block banner-infor banner-service" data-aos="fade-left" data-aos-duration="1000">
                    <div className="banner-left">
                        <div class="infor-list mb-4">
                            <div class="infor-text">
                                <div class="text-sz16 mb-2"><span>클라우드 네이티브 인프라 구축</span></div>
                                <div class="text-sz16">Kubernetes 기반 컨테이너 오케스트레이션 및 최적화</div>
                            </div>
                        </div>

                        <div class="infor-list mb-4">
                            <div class="infor-text">
                                <div class="text-sz16 mb-2"><span>전문 엔지니어 기술 지원</span></div>
                                <div class="text-sz16">Windows, Linux, 클라우드 환경을 아우르는 IT 운영 기술 지원</div>
                            </div>
                        </div>

                        <div class="infor-list">
                            <div class="infor-text">
                                <div class="text-sz16 mb-2"><span>AI 기반 협업 솔루션</span></div>
                                <div class="text-sz16">LLM을 활용한 지능형 업무 자동화 및 협업솔루션 제공</div>
                            </div>
                        </div>
                    </div>

                    <div className="banner-right">
                        <img src="https://hanbiro.com/assets/images/cloud/service-icons.png" />
                    </div>
                </div>
            </div>

            {/* contain */}
            <div class="contain">
                <div class="section-box collaboration">
                    <div class="collab-tt" data-aos="fade-up" data-aos-duration="1100">
                        <div class="text-sz32 mb-4">Groupware</div>
                        <div class="text-sz16">메일, 전자결재, HR, 일정관리 뿐 아니라, 협업 관리 , 메신저,<br />
                            화상회의 등 직원간의 실시간 커뮤니케이션을 지원하는 통합 업무 관리 솔루션 입니다.</div>
                    </div>

                    <div class="collab-tab" data-aos="fade-up" data-aos-duration="1200">
                        <div className="tab-head">
                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-collab1-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab1" type="button" role="tab" aria-controls="pills-collab1"
                                        aria-selected="true">메일</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab4-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab4" type="button" role="tab" aria-controls="pills-collab4"
                                        aria-selected="false">전자결재</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab2-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab2" type="button" role="tab" aria-controls="pills-collab2"
                                        aria-selected="false">HR</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab5-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab5" type="button" role="tab" aria-controls="pills-collab5"
                                        aria-selected="false">협업관리</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab3-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab3" type="button" role="tab" aria-controls="pills-collab3"
                                        aria-selected="false">메신저</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab6-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab6" type="button" role="tab" aria-controls="pills-collab6"
                                        aria-selected="false">CRM</button>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content position-relative" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-collab1" role="tabpanel"
                                aria-labelledby="pills-collab1-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text">
                                            <div class="text-sz20 mb-2">이메일 & 스팸 필터링</div>
                                            <div class="text-sz16 mb-4">별도 구매 없이 , 그룹웨어 내 스팸 솔루션이 기본으로 포함되어 있습니다.
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">SPF, DKIM, DMARC</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">POP3/IMAP, 스팸 95% 차단</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">보안메일 , 승인 메일 , 대량 메일</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">외부메일 연동 , 메일함 공유 ( 퇴사자 포함 )</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">퇴사자 메일 공유, 퇴사자 메일 전달</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <a href="https://www.hanbiro.com/software/groupware-free-trial.html?SM=SPCMAKO">
                                                <button class="contact-btn btn-sz">
                                                    데모 신청
                                                </button>
                                            </a>
                                            <button class="contact-btn btn-sz out-light">
                                                View all features
                                                <span className="btn-icon"><ArrowRightAlt /></span>
                                            </button>
                                        </div>

                                    </div>

                                    <div class="col-lg-6 block-content-top">
                                        {/* <video class="video-player" preload="auto" loop poster="https://dapulse-res.cloudinary.com/video/upload/so_0p/Generator_featured images/Homepage - 2024/motion-values/_Platform_values_flexability_OPT2.jpg" playsinline webkit-playsinline x5-playsinline muted>
                                            <source src="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured images/Homepage - 2024/motion-values/_Platform_values_flexability_OPT2.mp4" />
                                        </video> */}
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/mail.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab2" role="tabpanel" aria-labelledby="pills-collab2-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text">
                                            <div class="text-sz20 mb-2">근태관리 & 휴가관리</div>
                                            <div class="text-sz16 mb-4">기본근무제, 탄력근무제, 선택근무제 등의 다양한 근무유형을 제공하며,<br />
                                                연차관리의 자동화 통해 직원의 출/퇴근, 휴무일을 관리합니다.
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">모바일을 통한 GPS, WIFI, Beacon 방식을 통한 출,퇴근</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">근태 승인 기능 제공</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">일/주/월 별 리포트 엑셀 다운로드 기능 제공</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">대시보드에 직원 출,퇴근 및 연차 현황 제공</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">연차관리 자동화 및 연차촉진제 지원</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <a href="https://www.hanbiro.com/software/groupware-free-trial.html?SM=SPCMAKO">
                                                <button class="contact-btn btn-sz">
                                                    데모 신청
                                                </button>
                                            </a>
                                            <button class="contact-btn btn-sz out-light">
                                                View all features
                                                <span className="btn-icon"><ArrowRightAlt /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/hr.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab3" role="tabpanel" aria-labelledby="pills-collab3-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text">
                                            <div class="text-sz20 mb-2">메신저 & 회상회의</div>
                                            <div class="text-sz16 mb-4">팀원들과 자유롭게 실시간 소통합니다.</div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">상대방의 PC에 원격지원 기능 제공</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">화면 공유 및 보이스톡, 화상회의 기능을 통한 업무 협업 지원</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">게스트와의 화상회의 지원</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <a href="https://www.hanbiro.com/software/groupware-free-trial.html?SM=SPCMAKO">
                                                <button class="contact-btn btn-sz">
                                                    데모 신청
                                                </button>
                                            </a>
                                            <button class="contact-btn btn-sz out-light">
                                                View all features
                                                <span className="btn-icon"><ArrowRightAlt /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/messenger.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab4" role="tabpanel" aria-labelledby="pills-collab4-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text">
                                            <div class="text-sz20 mb-2">전자결재</div>
                                            <div class="text-sz16 mb-4">PC와 모바일을 통해 어제 어디서나 의사결정을 진행함으로써 빠른 업무<br />
                                                처리가 이루어집니다.
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">무료 기본 양식 제공 ( 약 300 건 )</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">엑셀에디터 양식을 통한 자동수식, 간단 수식계산</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">보안결재를 이용한 이중 보안</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">양식별 결재선 지정 , 본인만의 자주쓰는 결재선 저장</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">전자결재 ERP연동 지원</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <a href="https://www.hanbiro.com/software/groupware-free-trial.html?SM=SPCMAKO">
                                                <button class="contact-btn btn-sz">
                                                    데모 신청
                                                </button>
                                            </a>
                                            <button class="contact-btn btn-sz out-light">
                                                View all features
                                                <span className="btn-icon"><ArrowRightAlt /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        {/* <video class="video-player" preload="auto" loop poster="https://dapulse-res.cloudinary.com/video/upload/so_0p/Generator_featured images/Homepage - 2024/motion-values/_Platform_values_Adopted_by_anyone_2.jpg" playsinline webkit-playsinline x5-playsinline muted>
                                            <source src="https://dapulse-res.cloudinary.com/video/upload/q_auto,f_auto,cs_copy/Generator_featured images/Homepage - 2024/motion-values/_Platform_values_Adopted_by_anyone_2.mp4" />
                                        </video> */}
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/approval.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab5" role="tabpanel" aria-labelledby="pills-collab5-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text">
                                            <div class="text-sz20 mb-2">협업관리</div>
                                            <div class="text-sz16 mb-4">회사에 맞게 업무 템플릿을 만들고, 그 일감에 맞는 업무 담당자 와<br />
                                                진행상황을 등록하여 체계적으로 업무를 관리합니다.
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">업무 효율성 향상 : 팀원들이 실시간으로 진행상황을 공유하고<br /> 피드백을 주고받을 수 있어,
                                                    중복 작업을 줄고 빠르게 업무가 진행됩니다. </div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">투명한 업무 진행 : 각 팀원과 프로젝트의 진행상황이 명확하게<br /> 표시되어 ,
                                                    모든 사람이 현재 상황을 파악하고 일정을 조율할 수 있어 <br /> 효율적인 협업으로 이어집니다. </div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">책임 분배와 관리 용이 : 각 업무에 대한 담당자 지정과 열할<br /> 분담이 확실하기에
                                                    업무 책임이 명확해 집니다. </div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">문서와 자료의 중앙화 : 모든 업무 관련 자료와 문서가 한<br /> 곳에 저장되어 ,
                                                    필요할 때 쉽게 접근 및 공유가 가능한 환경이 됩니다.</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="text-sz16">리소스 관리 최적화 : 각 팀원의 업무 부하를 파악하고,<br /> 리소스를 효율적으로 배분하여
                                                    과중한 작업을 방지합니다.</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <a href="https://www.hanbiro.com/software/groupware-free-trial.html?SM=SPCMAKO">
                                                <button class="contact-btn btn-sz">
                                                    데모 신청
                                                </button>
                                            </a>
                                            <button class="contact-btn btn-sz out-light">
                                                View all features
                                                <span className="btn-icon"><ArrowRightAlt /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/project.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab6" role="tabpanel" aria-labelledby="pills-collab6-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text">
                                            <div class="text-sz20 mb-2">HelpDesk</div>
                                            <div class="text-sz16 mb-4">여러경로를 통해 들어오는 고객 문의를 ‘헬프데스크’라는 하나의 창구에서 관리합니다. <br />
                                                접수부터 최종 답변이 제공될 때까지의 모든 과정을 지속적으로 추적하고 지원합니다.
                                            </div>

                                            <div class="text-sz20 mb-2">CRM call</div>
                                            <div class="text-sz16 mb-4">고객과의 전화 이력을 저장하고 분석합니다. <br />
                                                이전 통화 기록과 관련 정보를 바탕으로 더 나은 서비스를 제공할 수 있도록 도와줍니다.
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <a href="https://www.hanbiro.com/software/groupware-free-trial.html?SM=SPCMAKO">
                                                <button class="contact-btn btn-sz">
                                                    데모 신청
                                                </button>
                                            </a>
                                            <button class="contact-btn btn-sz out-light">
                                                View all features
                                                <span className="btn-icon"><ArrowRightAlt /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/crm.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-box infrastructure">
                    <div class="row infra-block" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="50">
                        <div class="col-lg-7 infra-text">
                            <div class="text-sz24 mb-3">안정적인 컨테이너 인프라 구축</div>
                            <div class="text-sz14 mb-4"> 최신 쿠버네티스(Kubernetes) 기술을 활용해 프라이빗 클라우드 기반의 컨테이너 환경을 구축해드립니다.<br />
                                확장성과 유연성을 갖춘 인프라를 통해 서비스를 안정적으로 운영합니다</div>

                            <div class="d-block-text">
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16">컨테이너를 이용한 컴퓨팅 자원 사용으로 비용 절감</div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16">마이크로서비스 도입을 위한 개발과 시스템 구성 상담</div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16">최적의 비용으로 자체 독립 클라우드 구축과 운영 지원</div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16">기존 서비스 쿠버네티스 환경으로 마이그레이션 및 최적화</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5 infra-image">
                            <img src="https://hanbiro.com/assets/images/cloud/cloud-architecture-1.png" />
                        </div>

                    </div>

                    <div class="row infra-block" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="50">
                        <div class="col-lg-7 infra-text">
                            <div class="text-sz24 mb-3">전문 엔지니어 지원</div>
                            <div class="text-sz14 mb-4">Windows, Linux등 다양한 운영체제에 대한 전문 엔지니어의 맞춤형 기술 지원을 제공합니다.<br />
                                클라우드 환경부터 기존 레거시 시스템까지 안정적인 운영을 보장하며, 기업의 IT 운영 부담을 줄여줍니다.</div>

                            <div class="d-block-text">
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16"><b>서버 최적화 및 성능 튜닝:</b> 애플리케이션 워크로드 분석을 통해 CPU, 메모리,<br /> 네트워크
                                        자원을 최적화하여 성능 저하
                                        없이 안정적인 운영을 보장합니다.</div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16"><b>보안 패치 및 시스템 업데이트:</b> 최신 보안 위협을 차단하기 위해 운영체제(OS)<br /> 및 소프트웨어 패치를
                                        정기적으로
                                        적용하여 보안 취약점을 예방합니다.</div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16"><b>실시간 모니터링 및 장애 대응:</b> 서버 및 네트워크 성능을 실시간 모니터링하고,<br /> 장애 발생 시 즉각적인
                                        원인 분석 및
                                        복구 지원을 제공합니다.</div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="text-sz16"><b>데이터 백업 및 복구 시스템 구축:</b> 중요한 데이터를 정기적으로 백업하고, 비상<br /> 상황 시 빠른 복구가
                                        가능하도록
                                        체계적인 복구 프로세스를 운영합니다.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 infra-image">
                            <img src="https://hanbiro.com/assets/images/cloud/system-engineer-1.png" />
                        </div>
                    </div>

                    <div class="contact-us">
                        <a href="https://www.hanbiro.com/application/index.html?Manage=mt1">
                            <button class="contact-btn btn-sz">
                                데모 신청
                            </button>
                        </a>
                    </div>
                </div>

                <div class="section-box background ai-block">
                    <div class="collab-tt mb-5">
                        <div class="text-sz32 mb-4" data-aos="fade-up" data-aos-duration="1100" data-aos-offset="50">AI 기반 협업 솔루션 & 그룹웨어 제공</div>
                        <div class="text-sz16" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="50">최신 LLM기술을 활용한 AI 협업 솔루션으로 업무 생산성을 높여줍니다.</div>
                    </div>

                    <div class="row ai-block-row" data-aos="fade-up" data-aos-duration="1300" data-aos-offset="50">
                        <div class="col-lg-4">
                            <div class="ai-box">
                                <div class="text-sz20 mb-3">스마트 이메일 관리</div>
                                <div class="text-sz16">자동 요약, 번역, 스팸 필터링 기능을 제공합니다.</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ai-box">
                                <div class="text-sz20 mb-3">고객 관리 솔루션</div>
                                <div class="text-sz16">HelpDesk & CRM Call 기능으로 <br />
                                    고객 문의를 처리하고 관리합니다.</div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ai-box">
                                <div class="text-sz20 mb-3">실시간 커뮤니케이션</div>
                                <div class="text-sz16">메신저 & 화상회의 기능을 제공합니다.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-box ai dark-block">
                    <div class="collab-tt mb-5">
                        <div class="text-sz32">AI 기반 협업 솔루션 에는 어떠한 기능이 포함되어 있나요 ?</div>
                    </div>

                    <div class="row block-content">
                        <div class="col-lg-6 image-display">
                            <div class="ai-image">
                                <div class="image-box-ai" data-aos="zoom-in" data-aos-duration="800">
                                    <img id="displayed-image" src="https://hanbiro.com/assets/images/cloud/image 615.png" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 accordion">
                            <div className="vertical-line">
                                <div className="thumb-scroll"></div>
                            </div>
                            <div>
                                <div class="accordion-item active" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit">메일</div>지능형 이메일 관리</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• 고급 스팸 필터링 및 보안 : AI기반의 정교한 스팸 필터링 및 위협 탐지 기능 제공</span><br />
                                        <span>• AI 요약 및 번역 : 메일 목록 및 본문에서 자동 요약 및 번역 기능 제공</span><br />
                                        <span>• 스마트 메일 작성 지원 : 메일 작성시 초안 및 키워드 추천 기능으로 작성 효율 극대화</span>
                                    </div>
                                </div>
                                <div class="accordion-item" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit">CRM Call</div>AI 기반 고객 통화 관리</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• 통화 데이터 저장 및 분석: 통화이력을 AI기반으로 실시간 분석하여 고객 지원을 최적화</span><br />
                                        <span>• 음성 텍스트 변환 및 요약: 음성을 텍스트로 변환하고 자동 요약, 다국어 번역을 지원</span><br />
                                        <span>• 마케팅 인사이트 도출: 고객 응대 데이터를 분석하여 맞춤형 마케팅 전략 수립</span><br />
                                        <span>** 필수 요건: 전용장비 또는 한비로 제공 라즈베리파이 기반의 녹취 시스템 구축</span>
                                    </div>
                                </div>
                                <div class="accordion-item" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit">Help Desk</div>스마트한 고객 요청 관리</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• 고객 요청 추적 및 관리: 이메일, 유선 응대 등의 고객 요청을 체계적으로 관리하여 업무 처리 속도를 향상</span><br />
                                        <span>• AI 기반 자동 응답 시스템: LLM 모델을 활용한 스마트 응대 및 자동화된 업무 분배로 율성 극대화</span>
                                    </div>
                                </div>
                                <div class="accordion-item" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit">메신저</div>AI기반 실시간 커뮤니케이션</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• 실시간 번역 및 요약: 글로벌 환경에서 실시간 번역 및 메시지 요약 기능 제공 </span><br />
                                        <span>• AI 기반 회의록 생성: 화상회의 지원 및 음성 인식을 통한 자동 회의록 생성으로 업무 편의성 향상</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="section-box partner" id="ourcustomer">
                    <div class="partner-head">
                        <div class="text-sz32 mb-4">한비로와 함께하는 다양한 고객사</div>
                        <div class="text-sz16">한비로와 함께 성공적인 길로 나아가는 기업들입니다.</div>
                    </div>

                    <div className="newscroll-row">
                        <div class="new-scroll">
                            <div class="new-m-scroll">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                            </div>
                            <div class="new-m-scroll">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                            </div>
                        </div>
                    </div>

                    {/* <div class="scroll">
                        <div class="m-scroll">
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                            </div>
                        </div>
                        <div class="m-scroll">
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                            </div>
                            <div class="card">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default IndexKo;
