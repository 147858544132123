
import React from 'react';
import './Style.css';
import Container from 'react-bootstrap/Container'
import CloudCheckIcon from 'mdi-react/CloudCheckIcon';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';





function CloudConsultContent(text) {

    const CloudContent = {
        // width: 205,
        fontSize: "16px",
        textAlign: "left",
        paddingBottom: "8px",
        '@media only screen and (min-width: 600px) and (max-width: 1368px)': {
            width: 380,
        },

        '@media only screen and (max-width: 600px)': {
            width: 190,
        },
    }

    const ConsultWrapper = {
        '@media only screen and (max-width: 1368px)': {
            justifyContent: "center",
        },
        '@media only screen and (min-width: 1200px)': {
            width: 420,
        },
    }

    const CloudText = {
        color: "#FFF",
        wordBreak: "break-word",
    }

    const IconWrap = {
        paddingRight: "8px",
        '@media only screen and (min-width: 600px) and (max-width: 1180px)': {
            paddingBottom: "3px",
        },

        '@media only screen and (max-width: 650px)': {
            paddingTop: "6px",
        },

        '@media only screen and (max-width: 675px) and (min-width: 975px)': {
            paddingTop: "6px",
        },
    }


    return (

        <Grid container sx={ConsultWrapper}>
            <Grid>
                <Box sx={IconWrap}><CloudCheckIcon color="#FFFFFF" /></Box>
            </Grid>
            <Grid item>
                <Box sx={CloudContent}><Box sx={CloudText}>{text}</Box></Box>
            </Grid>
        </Grid>
    )
}

function formatPhone($phone) {
    return 'tel:' + $phone
}

function formatMail($mail) {
    return 'mailto:' + $mail
}


function columnus($username, $imageuser, $phone, $mail) {

    const CardWrap = {
        background: "white",
        padding: "15px 0px",
        borderRadius: "10px",
        border: "#f2f2f2 solid 1px"
    }

    const InfoText = {
        fontSize: "16px",
        '@media only screen and (min-width: 280px) and (max-width: 911px)': {
            fontSize: "15px",
        },
        textAlign: "left",
        // '@media only screen and (min-width: 600px) and (max-width: 911px)' : {
        //     fontSize: "14px",
        //   },
    }

    const InfoHeader = {
        fontWeight: "bold",
        fontSize: "16px",
    }

    const UserCard = {
        padding: "0px 20px",
        // '@media only screen and (min-width: 600px) and (max-width: 975px)' : {
        //     display: "flex",
        //     justifyContent: "center",
        //   },
    }

    const AvatarWrap = {
        height: "100%",
        paddingTop: "5px",
        display: "flex",
        justifyContent: "center"
    }

    const ExtraSmall = {
        '@media only screen and (max-width: 290px)': {
            display: "block",
            textAlign: "left"
        },
        '@media only screen and (min-width: 291px)': {
            display: "none",
        },
    }

    const showMail = {
        '@media only screen and (max-width: 290px)': {
            display: "block",
        },
        '@media only screen and (min-width: 291px)': {
            display: "none !important",
        },
    }


    return (
        <Box sx={CardWrap}>
            <Box container spacing={2}>
                {/* <Grid item xs={4} sm={12} md={4}>
                    <Box sx={AvatarWrap}><Avatar src={$imageuser} sx={{ width: "60px", height: "60px" }}></Avatar></Box>
                </Grid>
                <Grid item xs={8} sm={12} md={8} sx={UserCard}>
                    <Box>
                        <Box sx={InfoText}>
                            <Box sx={InfoHeader}>{$username}</Box>
                        </Box>
                        
                        <Box sx={InfoText}>
                            <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline"  href={formatPhone($phone)}>&nbsp;&nbsp;{$phone}</a>
                        </Box>

                        <Box sx={InfoText}>
                            <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail($mail)} >&nbsp;&nbsp;{$mail}</a>
                        </Box>
                    </Box>
                </Grid> */}
                <Box sx={UserCard}>
                    <Box sx={InfoText}>
                        <Box sx={InfoHeader}>{$username}</Box>
                    </Box>

                    <Box sx={InfoText}>
                        <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline" href={formatPhone($phone)}>&nbsp;{$phone}</a>
                        <span>&nbsp;</span>
                        <span class="show-mail"><br></br></span>
                        <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail($mail)} >&nbsp;{$mail}</a>
                    </Box>
                </Box>
            </Box>
        </Box>



    )
}

/* eslint-disable */

function Cloudconsult() {
    const ConsultWrap = {
        width: "100%",
        padding: "30px 0px",
        '@media only screen and (max-width: 975px)': {
            display: "flex",
            justifyContent: "center",
        }

    }

    const ConsultBox = {
        borderRadius: "4px",
        border: "#7061ff solid 1px",
        background: "#7061ff",
        padding: "16px",
    }

    const CloudHeader = {
        fontSize: "20px",
        color: "white",
        background: "#7061ff",
        paddingBottom: "20px",
        display: "inline-block",
    }

    const PrimaryText = {
        '@media only screen and (min-width: 600px) and (max-width: 1200px)': {
            float: "left",
        },
    }

    const Image = {
        width: 150,
        '@media only screen and (min-width: 600px) and (max-width: 1200px)': {
            paddingTop: "10px",
            width: 200,
        },
        '@media only screen and (max-width: 600px)': {
            paddingTop: "15px",
            width: 180,
        }
    }

    return (
        <Box className='coloruserdata' sx={{ paddingBottom: "25px" }}>
            <Container>
                <Box sx={ConsultWrap}>
                    <Box sx={ConsultBox}>
                        <Grid container>
                            <Grid item xs={12} lg={4}>
                                <Box sx={CloudHeader}>
                                    <Box sx={PrimaryText}>CLOUD&nbsp;</Box>
                                    <Box sx={PrimaryText}>구축및 운영 컨설팅 </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {CloudConsultContent("컨터이너를 이용한 컴퓨팅 자원 사용으로 비용절감")}
                                {CloudConsultContent("마이크로서비스 도입을 위한 개발과 시스템구성 상담")}
                                {CloudConsultContent("적은 비용으로 자체 독립 클라우드 구축및 운영지원")}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Box sx={Image} component="img" src="https://www.hanbiro.com/cloud/library/cloudconsult/cloud-consult.svg"></Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box>{columnus("신윤호 부장", "https://www.hanbiro.com/cloud/library/cloudconsult/support-01.svg", "070-4804-9651", "cloud@hanbiro.com")}</Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box>{columnus("김정윤 부장", "https://www.hanbiro.com/cloud/library/cloudconsult/support-01.svg", "070-4804-9668", "cloud@hanbiro.com")}</Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box>{columnus("조성학 이사 (해외)", "https://www.hanbiro.com/cloud/library/cloudconsult/support-01.svg", "+84 909 284 160", "cloud@hanbiro.com")}</Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box>{columnus("신상우 부장", "https://www.hanbiro.com/cloud/library/cloudconsult/support-01.svg", "070-4804-9662", "cloud@hanbiro.com")}</Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box>{columnus("신경섭 부장", "https://www.hanbiro.com/cloud/library/cloudconsult/support-01.svg", "070-4804-9664", "cloud@hanbiro.com")}</Box>
                    </Grid>
                </Grid>


            </Container>
        </Box>

    );
};




export default Cloudconsult;