

import React from 'react';
import TrafficFeeV1 from './TrafficFeeV1';
import './style.css';
import '../../Style/defaultstyle.scss'

function TrafficFee() {
  return (
    <>
      <div >
        <div style={StySpecification.StylesContainer}>
          <div >
            <div >
              <h2 style={StySpecification.Title} className='header'>Load Balancer</h2>
            </div>
          </div>
          <div style={StySpecification.TableScroll}>
            <div style={StySpecification.TableWrap}>
              <div style={StySpecification.ListProduct}>
                <table width="100%">
                  <tbody >
                    <tr style={StySpecification.Header} className="style-box-title">
                      <td width="32%" style={StySpecification.BorLeft}><div style={StySpecification.TextTitle}><b className='sub-title'>구분</b></div></td>
                      <td width="36%" ><b className='sub-title'>서비스비용(일)</b></td>
                      <td width="32%" style={StySpecification.BorRight}><b className='sub-title'>서비스비용(월)</b></td>
                    </tr>
                    <tr className="style-title-content">
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}>하드웨어	</div> </td>
                      <td style={StySpecification.BorAll}><span >570	</span></td>
                      <td style={StySpecification.BorAll}><span >20,000 원</span></td>
                    </tr>
                    <tr className="style-title-content">
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}><span >트래픽</span>	</div></td>
                      <td style={StySpecification.BorAll} colSpan={2}><span >무료</span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <TrafficFeeV1/>
        </div>
      </div>
    </>

  );
}


const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },

  Title: {
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  Header: {
    color: "white",
    background: "#393939",
  },
  TextTitle: {
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 900,
    
  },
  TextContent: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  BorLeft: {
    borderTopLeftRadius: "8px",
    borderRight: "2px solid #F4F4F4",
  },
  BorRight: {
    borderTopRightRadius: "8px",
    borderLeft: "2px solid #F4F4F4",
  },
  BorAll: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
  },
  TextLeft: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    fontWeight: 900,
    paddingLeft: "10px",
    textAlign: "left",
  }
}


export default TrafficFee;
