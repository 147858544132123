import React from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, Box, Stack, TableCell, Container } from '@mui/material';
import '../../../homepage/header-banner/style.css';
import styled from "styled-components";

const cellHeight = "45px"
const headerHeight = "40px"
const borderStyles = "1px solid #D7E5ED"

export default function KubernetesTable(title, sub_title, rows, last_row, description) {
    return (
      <Box sx={CardContent}>
        <Box>
            <Box sx={HeaderWrap} className="header">{title}</Box>
            <Box sx={SubTitle} className="sub-title">{sub_title}</Box>
        </Box>

        {/* table */}
        <TableContainer>
          <Table sx={{ minWidth: 850, borderCollapse: "separate"}}>
            <TableHead sx={{ paddingBottom: "0px !important" }}>
              <TableRow>
                <StyledTableHead align="right" style={{position: "sticky", left: 0, background: "white"}}></StyledTableHead>
                <StyledTableHead align="center"><Box sx={Header} className="sub-title">스탠다드 상품</Box></StyledTableHead>
                <StyledTableHead align="center"><Box sx={Header} className="sub-title">비지니스</Box></StyledTableHead>
                <StyledTableHead align="center"><Box sx={Header} className="sub-title">엔터프라이즈형</Box></StyledTableHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow>
                  <StyledTableCell style={{position: "sticky", left: 0, background: "white"}} align="right" sx={RowTitle}><Box dangerouslySetInnerHTML={{__html: `${row.row_title}`}}></Box></StyledTableCell>
                  <StyledTableCell align="center" sx={RowStyles}><Box sx={CellContent} dangerouslySetInnerHTML={{__html: `${row.col1}`}}></Box></StyledTableCell>
                  <StyledTableCell align="center" sx={RowStyles}><Box sx={CellContent} dangerouslySetInnerHTML={{__html: `${row.col2}`}}></Box></StyledTableCell>
                  <StyledTableCell align="center" sx={RowStyles}><Box sx={CellContent} dangerouslySetInnerHTML={{__html: `${row.col3}`}}></Box></StyledTableCell>
                </TableRow>
              ))}
              {last_row.map((row) => (
                <TableRow>
                  <StyledTableCell style={{position: "sticky", left: 0, background: "white"}} align="right" sx={RowTitle}><Box dangerouslySetInnerHTML={{__html: `${row.row_title}`}}></Box></StyledTableCell>
                  <StyledTableCell align="center" sx={LastRowStyle}><Box sx={CellContent} style={BorderStyles} dangerouslySetInnerHTML={{__html: `${row.col1}`}}></Box></StyledTableCell>
                  <StyledTableCell align="center" sx={LastRowStyle}><Box sx={CellContent} style={BorderStyles} dangerouslySetInnerHTML={{__html: `${row.col2}`}}></Box></StyledTableCell>
                  <StyledTableCell align="center" sx={LastRowStyle}><Box sx={CellContent} style={BorderStyles} dangerouslySetInnerHTML={{__html: `${row.col3}`}}></Box></StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack direction="row" sx={DescriptionBox}>
            <Box sx={FrontBox}></Box>
            <Box sx={Description} dangerouslySetInnerHTML={{__html: `${description}`}}></Box>
        </Stack>
      </Box>
      
    );
  }

const TestNgoc = {
    width: "120px", 
    '@media only screen and (max-width: 767px)' : {
      minWidth: "130px",
    }
  }
  

const CardContent = {
  boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
  border: "1px solid #E7EAF3",
  width: "100%",
  borderRadius: "4px",
  padding: "20px",
}

const HeaderWrap = {
  marginBottom: "20px",
  marginTop: "20px",
  fontWeight: "bold",
}

const SubTitle = {
  marginBottom: "30px",
  fontWeight: "bold",
}

const FrontBox = {
  width: 190, 
  maxWidth: 190,
  '@media only screen and (max-width: 500px)' : {
    display: "none"
  }
}

const DescriptionBox = {
  padding: "15px 0px",
}

const Description = {
  textAlign: "left",
  fontSize: "inherit",
  fontFamily: "inherit",
  paddingLeft: "50px",
  '@media only screen and (max-width: 500px)' : {
    paddingLeft: "10px",
  }
}

const CellContent = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "5px !important",
    paddingRight: "5px !important",
    borderLeft: borderStyles,
    borderRight: borderStyles,
    
}

const BorderStyles = {
    borderBottom: borderStyles,
}

const StyledTableCell = styled(TableCell)({
  border: "hidden",
  height: cellHeight,
  fontSize: "inherit",
  fontFamily: "inherit",
  padding: "0 10px",
})

const StyledTableHead = styled(TableCell)({
    borderColor: "white",
    height: cellHeight,
    padding: "0px 10px !important",
  })

const Header = {
    fontWeight: "bold",
    background: "#393939",
    color: "white",
    height: headerHeight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px 10px 0px 0px",
}

const RowTitle = {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontWeight: "bold",
    border: borderStyles,
    // height: cellHeight,
    minWidth: "130px",
    '@media only screen and (min-width: 1201px) and (max-width: 1399px)' : {
      width: "150px",
    }
}

const RowStyles = {
    padding: "0px 10px",
    border: "hidden",
    borderBottom: "hidden", 
    borderLeft: borderStyles,
    borderRight: borderStyles,
    // height: cellHeight,
}

const LastRowStyle = {
    padding: "0px 10px",
    borderTop: "hidden",
    borderLeft: borderStyles,
    borderRight: borderStyles,
    borderBottom: borderStyles,
    // height: cellHeight,
}

  