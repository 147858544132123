import React from "react";
import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import NavButton from '../homepage/header-banner/TopNavButton';
import Footer from '../homepage/footer/Footer';
import './assets/css/style-page.css';
import BgBottom from './assets/image/bg-bottom.png';
import FrameBG from './assets/image/Frame.png';
import Image763 from './assets/image/image 763.png';
import AiImg1 from './assets/image/ai-img1.png';
import AiImg2 from './assets/image/ai-img2.png';
import AiFilled from './assets/image/ai-filled.svg';
import GroupwareCRM from './assets/image/groupware-crm 1.png';
import VectorBan from './assets/image/vector-ban.png';
import VectorLine from './assets/image/Vector 3.png';
import ReturnVisitor from './assets/image/returning_visitor.png';
import { Check } from '@mui/icons-material';
import { ChevronRight } from "@mui/icons-material";
import { ArrowRightAlt } from "@mui/icons-material";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import EmailIcon from '@mui/icons-material/Email';
import ForumIcon from '@mui/icons-material/Forum';
import PhoneIcon from '@mui/icons-material/Phone';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DnsIcon from '@mui/icons-material/Dns';
import GroupsIcon from '@mui/icons-material/Groups';
import ApartmentIcon from '@mui/icons-material/Apartment';

const ScrollToSection = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const section = document.querySelector(location.hash);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    return null;
};

function IndexPage1() {
    const [activated, setActivated] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setActivated(false);
                    setTimeout(() => setActivated(true), 200);
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const CountUp = ({ start, end, duration, activated }) => {
        const [count, setCount] = useState(start);

        useEffect(() => {
            if (activated) {
                setCount(start);
                let current = start;
                const step = (end - start) / (duration * 60);

                const interval = setInterval(() => {
                    current += step;
                    if (current >= end) {
                        setCount(end);
                        clearInterval(interval);
                    } else {
                        setCount(Math.ceil(current));
                    }
                }, 1000 / 60);

                return () => clearInterval(interval);
            }
        }, [activated, start, end, duration]);

        return <span>{count.toLocaleString()}</span>;
    };


    useEffect(() => {
        const featureBoxes = document.querySelectorAll(".feature-box-img");

        featureBoxes.forEach((featureBox) => {
            const slideContainer = featureBox.querySelector(".slides");
            const dotsContainer = featureBox.querySelector(".dots");
            if (!dotsContainer) return;

            const totalSlides = 3;
            let currentIndex = 0;

            dotsContainer.innerHTML = "";

            for (let i = 0; i < totalSlides; i++) {
                const dot = document.createElement("div");
                dot.classList.add("dot");
                if (i === 0) dot.classList.add("active");
                dotsContainer.appendChild(dot);

                dot.addEventListener("click", () => {
                    currentIndex = i;
                    updateSlidePosition();
                    updateDots();
                });
            }

            const dots = dotsContainer.querySelectorAll(".dot");

            const updateSlidePosition = () => {
                const slideWidth = featureBox.clientWidth;
                slideContainer.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
            };

            const updateDots = () => {
                dots.forEach((dot, index) => {
                    dot.classList.toggle("active", index === currentIndex);
                });
            };

            const autoSlide = () => {
                currentIndex = (currentIndex + 1) % totalSlides;
                updateSlidePosition();
                updateDots();
            };

            const interval = setInterval(autoSlide, 3000);

            const tabButtons = document.querySelectorAll('[data-bs-toggle="pill"]');
            tabButtons.forEach((tab) => {
                tab.addEventListener("click", () => {
                    currentIndex = 0;
                    updateSlidePosition();
                    updateDots();
                });
            });

            return () => clearInterval(interval);
        });
    }, []);

    return (

        <div className="indexpage1">
            <NavButton option={{
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#47A7FF' },
                itemNavClass: "item-nav",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            <ScrollToSection />
            <div className="header-ban">
                <div className="fit-box p-r-l">
                    <div className="header-ban-tt" data-aos="fade-up" data-aos-duration="1000">
                        <div className="header-ban-tt-left">
                            <p className="txt-48 wh-color mb-4">기업 IT 환경구축 • 운영</p>
                            <p className="txt-21 wh-color">인프라 구축부터 소프트웨어 , 하드웨어, AI협업까지 한번에 해결하는 <br />
                                맞춤형 클라우드 기반의 최적화 IT 솔루션을 제공합니다.</p>
                        </div>
                        <div className="header-ban-tt-right">
                            <Link to="#ai-LLM" className="ban-tt-list">
                                <div className="ban-tt-icon">
                                    <ArrowCircleDownIcon className="vector-icon wh-color" />
                                </div>
                                <div className="ban-tt-text wh-color">AI 통합 그룹웨어솔루션</div>
                            </Link>
                            <Link to="#ai-feature2" className="ban-tt-list">
                                <div className="ban-tt-icon">
                                    <ArrowCircleDownIcon className="vector-icon wh-color" />
                                </div>
                                <div className="ban-tt-text wh-color">클라우드 소프트웨어 • 서버 개발운영</div>
                            </Link>
                            <Link to="#ai-feature4" className="ban-tt-list">
                                <div className="ban-tt-icon">
                                    <ArrowCircleDownIcon className="vector-icon wh-color" />
                                </div>
                                <div className="ban-tt-text wh-color">프라이빗 쿠버네티스 인프라 서비스</div>
                            </Link>
                            <Link to="#clients" className="ban-tt-list">
                                <div className="ban-tt-icon">
                                    <ArrowCircleDownIcon className="vector-icon wh-color" />
                                </div>
                                <div className="ban-tt-text wh-color">기업전용 LLM 인프라 개발</div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            <div className="wrap-box ai-LLM" id="ai-LLM">
                <div className="fit-box" data-aos="fade-up" data-aos-duration="1500">
                    <div className="wrap-title">
                        <div className="txt-32 mb-3">AI 그룹웨어·협업솔루션</div>
                        <div className="txt-21-thin">
                            기업전용 AI • LLM 서버운영 / 고객의 데이터 학습제외 • 고객데이터보호
                        </div>
                    </div>

                    <div className="feature-dt">
                        <div className="fea-dt">
                            <div className="fea-dt-box">
                                <div className="fea-dt-col left">
                                    <div className="fea-dt-row">
                                        <div className="fea-dt-item">
                                            <EmailIcon className="fea-icon" />
                                            <div>
                                                <div className="txt-21 mb-1">메일</div>
                                                <div className="txt-14">요약 번역·초안작성</div>
                                            </div>
                                        </div>
                                        <div className="fea-dt-item">
                                            <ForumIcon className="fea-icon" />
                                            <div>
                                                <div className="txt-21 mb-1">메신저</div>
                                                <div className="txt-14">자동번역·메시지전송<br />대화요약·번역</div>
                                            </div>
                                        </div>
                                        <div className="fea-dt-item">
                                            <PhoneIcon className="fea-icon" />
                                            <div>
                                                <div className="txt-21 mb-1">AI Call +</div>
                                                <div className="txt-14">음성추출·요약·번역</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fea-dt-row">
                                        <div className="fea-dt-item">
                                            <RequestPageIcon className="fea-icon" />
                                            <div>
                                                <div className="txt-21 mb-1">전자결재</div>
                                                <div className="txt-14">요약·번역·초안작성</div>
                                            </div>
                                        </div>
                                        <div className="fea-dt-item">
                                            <ConfirmationNumberIcon className="fea-icon" />
                                            <div>
                                                <div className="txt-21 mb-1">헬프데스크</div>
                                                <div className="txt-14">요약·번역</div>
                                            </div>
                                        </div>
                                        <div className="fea-dt-item">
                                            <FactCheckIcon className="fea-icon" />
                                            <div>
                                                <div className="txt-21 mb-1">업무일지</div>
                                                <div className="txt-14">요약·번역</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap-box ai-feature2" id="ai-feature2">
                <div className="fit-box" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                    <div>
                        <div className="wrap-title">
                            <div className="txt-32 mb-2">그룹웨어</div>
                        </div>

                        <div className="ai-feature2-ct">
                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link txt-21 active" id="pills-fea1-tab" data-bs-toggle="pill" data-bs-target="#pills-fea1" type="button" role="tab" aria-controls="pills-fea1" aria-selected="true">메일</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link txt-21" id="pills-fea2-tab" data-bs-toggle="pill" data-bs-target="#pills-fea2" type="button" role="tab" aria-controls="pills-fea2" aria-selected="false">메신저</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link txt-21" id="pills-fea3-tab" data-bs-toggle="pill" data-bs-target="#pills-fea3" type="button" role="tab" aria-controls="pills-fea3" aria-selected="false">헬프데스크</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link txt-21" id="pills-fea4-tab" data-bs-toggle="pill" data-bs-target="#pills-fea4" type="button" role="tab" aria-controls="pills-fea4" aria-selected="false">전자결재</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link txt-21" id="pills-fea5-tab" data-bs-toggle="pill" data-bs-target="#pills-fea5" type="button" role="tab" aria-controls="pills-fea5" aria-selected="false">HR</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link txt-21" id="pills-fea6-tab" data-bs-toggle="pill" data-bs-target="#pills-fea6" type="button" role="tab" aria-controls="pills-fea6" aria-selected="false">프로젝트</button>
                                </li>

                            </ul>
                            <div className="gw-menu-block gw-menu-block-tab">
                                <div className="menu-line">
                                    <div className="gw-menu-item">일정</div>
                                    <div className="gw-menu-item">주소록</div>
                                    <div className="gw-menu-item">게시판</div>
                                    <div className="gw-menu-item">쪽지</div>
                                    <div className="gw-menu-item">Clouddisk</div>
                                    <div className="gw-menu-item">회람판</div>
                                    <div className="gw-menu-item">Todo</div>
                                    <div className="gw-menu-item">문서관리</div>
                                    <div className="gw-menu-item">자산관리</div>
                                    <div className="gw-menu-item">비용보고</div>
                                    {/* <div className="gw-menu-item">다국어지원</div> */}
                                </div>
                            </div>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane show active" id="pills-fea1" role="tabpanel" aria-labelledby="pills-fea1-tab">
                                    <div className="feature-box">
                                        <div className="feature-box-col">
                                            <div class="txt-21 mb-2">이메일 & 스팸 필터링</div>
                                            <div class="txt-14 txt-color mb-4">AI 기반 번역, 요약 기능을 제공합니다. <br />
                                                별도 구매 없이, 그룹웨어 내 스팸 솔루션이 기본으로 포함되어 있습니다.</div>

                                            <div class="feature-box-text">
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">SPF, DKIM, DMARC</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">POP3/IMAP, 스팸 95% 차단</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">보안메일 , 승인 메일 , 대량 메일</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">외부메일 연동 , 메일함 공유 ( 퇴사자 포함 )</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">퇴사자 메일 공유, 퇴사자 메일 전달</div>
                                                </div>
                                            </div>

                                            <div className="feature-btn-gr">
                                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                                                {/* <button className="fea-button secondary-btn txt-14">View more</button> */}
                                            </div>
                                        </div>
                                        <div className="feature-box-col">
                                            <div className="feature-box-img feature-shadow">
                                                <div class="slides">
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-1 sm.png" alt="Slide 1" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-2 sm.png" alt="Slide 2" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-3 sm.png" alt="Slide 3" />
                                                </div>
                                                <div class="dots"></div>
                                            </div>

                                        </div>
                                        {/* <div className="feature-box-col">
                                        <div className="feature-box-img">
                                            <img src="https://hanbiro.com/assets/images/cloud/1.gif" />
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <div class="tab-pane" id="pills-fea2" role="tabpanel" aria-labelledby="pills-fea2-tab">
                                    <div className="feature-box">
                                        <div className="feature-box-col">
                                            <div class="txt-21 mb-2">메신저 & 회상회의</div>
                                            <div class="txt-14 txt-color mb-4">팀원들과 자유롭게 실시간 소통합니다.</div>

                                            <div class="feature-box-text">
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">화상회의 내용요약및 번역</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">실시간 대화번역 • 오늘의 대화요약</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">화면 공유 및 보이스톡, 화상회의 기능을 통한 업무 협업 지원</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">게스트와의 화상회의 지원</div>
                                                </div>
                                            </div>

                                            <div className="feature-btn-gr">
                                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                                                {/* <button className="fea-button secondary-btn txt-14">View more</button> */}
                                            </div>
                                        </div>
                                        <div className="feature-box-col">
                                            <div className="feature-box-img">
                                                <div class="slides">
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-1 sm.png" alt="Slide 1" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-2 sm.png" alt="Slide 2" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-3 sm.png" alt="Slide 3" />
                                                </div>

                                                <div class="dots"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="pills-fea3" role="tabpanel" aria-labelledby="pills-fea3-tab">
                                    <div className="feature-box">
                                        <div className="feature-box-col">
                                            <div class="txt-21 mb-2">티켓</div>
                                            <div class="txt-14 txt-color mb-4">여러 경로를 통해 들어오는 고객문의를 하나의 창구에서 관리합니다.  <br />
                                                접수부터 최종 답변이 제공될 때 까지의 모든 과정을 지속적으로 추적하고 지원합니다.</div>

                                            <div class="txt-21 mb-2">AI call +</div>
                                            <div class="txt-14 txt-color mb-4">고객과의 전화 이력을 저장하고 분석합니다.  <br />
                                                이전 통화 기록과 관련 정보를 바탕으로 더 나은 서비스를 제공할 수 있도록 도와줍니다.<br />
                                                AI기반 통화내용 요약 및 번역합니다.
                                            </div>

                                            <div className="feature-btn-gr">
                                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                                                {/* <button className="fea-button secondary-btn txt-14">View more</button> */}
                                            </div>
                                        </div>
                                        <div className="feature-box-col">
                                            <div className="feature-box-img">
                                                <div class="slides">
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-1 sm.png" alt="Slide 1" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-2 sm.png" alt="Slide 2" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-3 sm.png" alt="Slide 3" />
                                                </div>

                                                <div class="dots"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="pills-fea4" role="tabpanel" aria-labelledby="pills-fea4-tab">
                                    <div className="feature-box">
                                        <div className="feature-box-col">
                                            <div class="txt-21 mb-2">전자결재</div>
                                            <div class="txt-14 txt-color mb-4">PC와 모바일을 통해 어제 어디서나 의사결정을 진행함으로써 빠른 업무
                                                처리가 이루어집니다.</div>

                                            <div class="feature-box-text">
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">무료 기본 양식 제공 ( 약 300 건 )</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">엑셀에디터 양식을 통한 자동수식, 간단 수식계산</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">보안결재를 이용한 이중 보안</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">양식별 결재선 지정 , 본인만의 자주쓰는 결재선 저장</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">ERP • SAP • 기타기간계시스템연동</div>
                                                </div>
                                            </div>

                                            <div className="feature-btn-gr">
                                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                                                {/* <button className="fea-button secondary-btn txt-14">View more</button> */}
                                            </div>
                                        </div>
                                        <div className="feature-box-col">
                                            <div className="feature-box-img">
                                                <div class="slides">
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-1 sm.png" alt="Slide 1" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-2 sm.png" alt="Slide 2" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-3 sm.png" alt="Slide 3" />
                                                </div>

                                                <div class="dots"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="pills-fea5" role="tabpanel" aria-labelledby="pills-fea5-tab">
                                    <div className="feature-box">
                                        <div className="feature-box-col">
                                            <div class="txt-21 mb-2">근태관리 & 휴가관리</div>
                                            <div class="txt-14 txt-color mb-4">기본근무제, 탄력근무제, 선택근무제 등의 다양한 근무유형을 제공하며,<br />
                                                연차관리의 자동화 통해 직원의 출/퇴근, 휴무일을 관리합니다.</div>

                                            <div class="feature-box-text">
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">모바일을 통한 GPS, WIFI, Beacon 방식을 통한 출,퇴근</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">근태 승인 기능 제공</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">일/주/월 별 리포트 엑셀 다운로드 기능 제공</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">대시보드에 직원 출,퇴근 및 연차 현황 제공</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">연차관리 자동화 및 연차촉진제 지원</div>
                                                </div>
                                            </div>

                                            <div className="feature-btn-gr">
                                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                                                {/* <button className="fea-button secondary-btn txt-14">View more</button> */}
                                            </div>
                                        </div>
                                        <div className="feature-box-col">
                                            <div className="feature-box-img">
                                                <div class="slides">
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-1 sm.png" alt="Slide 1" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-2 sm.png" alt="Slide 2" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-3 sm.png" alt="Slide 3" />
                                                </div>

                                                <div class="dots"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="pills-fea6" role="tabpanel" aria-labelledby="pills-fea6-tab">
                                    <div className="feature-box">
                                        <div className="feature-box-col">
                                            <div class="txt-21 mb-2">프로젝트</div>
                                            <div class="txt-14 txt-color mb-4">회사에 맞게 업무 템플릿을 만들고, 그 일감에 맞는 업무 담당자 와<br />
                                                진행상황을 등록하여 체계적으로 업무를 관리합니다.</div>

                                            <div class="feature-box-text">
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">회사별 맞춤 업무 플로우 설정 </div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">투명한 업무 지시로 담당자의 책임을 명확히하고
                                                        업무의 병목현상을 방지</div>
                                                </div>
                                                <div class="block-li">
                                                    <Check className="check" />
                                                    <div class="txt-14 txt-color">변경사항 알림을 통해 실시간으로 업무 진행</div>
                                                </div>
                                            </div>

                                            <div className="feature-btn-gr">
                                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                                                {/* <button className="fea-button secondary-btn txt-14">View more</button> */}
                                            </div>
                                        </div>
                                        <div className="feature-box-col">
                                            <div className="feature-box-img">
                                                <div class="slides">
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-1 sm.png" alt="Slide 1" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-2 sm.png" alt="Slide 2" />
                                                    <img src="https://hanbiro.com/assets/images/cloud/gw-mail-3 sm.png" alt="Slide 3" />
                                                </div>

                                                <div class="dots"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-block">
                                    <div className="menu-line">
                                        <div className="menu-item wh-color"><b>다국어지원</b></div>
                                        <div className="menu-item wh-color"><b>ERP - SAP 연동</b></div>
                                        {/* <div className="menu-item wh-color"><b>프라이빗 LLM 서버 - AI 통합</b></div> */}
                                        <div className="menu-item wh-color"><b>iOS</b></div>
                                        <div className="menu-item wh-color"><b>AI 통합</b></div>
                                        <div className="menu-item wh-color"><b>Android</b></div>
                                        <div className="menu-item wh-color"><b>Linux</b></div>
                                        <div className="menu-item wh-color"><b>Mobile - Tablet</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap-box ai-feature4" id="ai-feature4">
                <div className="fit-box">
                    <div className="wrap-title">
                        <div className="txt-32">프라이빗 클라우드</div>
                        {/* <div className="txt-21-thin">기업
                            전용 AI · LLM
                            서버운영     고객의 데이터 학습제외 · 고객데이터보호</div> */}
                    </div>

                    <div className="ai-feature4-ct">
                        <div className="feature-box">
                            <div className="feature-box-col">
                                <div className="txt-21 kuber-title kube-light-blue" data-aos="flip-up" data-aos-duration="1000" data-aos-offset="20">프라이빗 Kubernetes 인프라구축·관리운영</div>
                                <div className="feature-box-text">
                                    <div className="text-14">서버자원의 효율적인 운영, 리소스의 자동 축소 · 확장 </div>
                                    <div className="text-14">자동재해복구 · 오토스케일링기능, 서비스의 영속성보장</div>
                                    <div className="text-14">마이크로서비스 서비스 어플리케이션 운영최적화</div>
                                    <div className="text-14">고객사전용 프라이빗 클러스터 인프라구축, 보안강화</div>
                                    <div className="text-14">베어메탈 환경의 서비스 어플리케이션 마이그레이션 지원</div>
                                </div>

                            </div>
                            <div className="feature-box-col">
                                <div className="txt-21 kuber-title kube-light-blue" data-aos="flip-up" data-aos-duration="1000" data-aos-offset="20">CLOUD 서버 관리·운영 서비스</div>
                                <div className="feature-box-text">
                                    <div className="text-14">리눅스, 윈도우 관리운영서비스 제공</div>
                                    <div className="text-14">서비스에 적합한 맞춤형 서버리소스 생성</div>
                                    <div className="text-14">전용 서비스 모니터프로그램 24시간운영</div>
                                    <div className="text-14">전문 서버엔지니어의 관리 · 운영지원</div>
                                    <div className="text-14">8,000 관계사, 5,000 대의 서버운영 노하우보유</div>
                                </div>
                            </div>
                        </div>

                        <div className="feature-box">
                            <div className="feature-box-col">
                                <div className="txt-21 kuber-title kube-light-blue" data-aos="flip-up" data-aos-duration="1000" data-aos-offset="20">VM·가상화시스템 / 기업전용 LLM 시스템구축</div>
                                <div className="feature-box-text">
                                    <div className="text-14">Proxmox VE 기반 최신 가상화 운영환경 구축 </div>
                                    <div className="text-14">무중단 가상화서버 구축 · 관리운영</div>
                                    <div className="text-14">기업전용 맞춤형 LLM 인프라 구축 및 운영</div>
                                    <div className="text-14">LLM 기반 RAG 시스템 개발및 운영</div>
                                    <div className="text-14">완벽한 보안을 갖춘 기업 전용 AI 인프라</div>
                                </div>

                            </div>
                            <div className="feature-box-col">
                                <div className="txt-21 kuber-title kube-light-blue" data-aos="flip-up" data-aos-duration="1000" data-aos-offset="20">클라우드기반 마이크로서비스 API</div>
                                <div className="feature-box-text">
                                    <div className="text-14">이벤트 소싱 기반 분산 아키텍처 및 서버리스 시스템 구축</div>
                                    <div className="text-14">IAM, OAuth, Role 기반 권한 관리로 안전한 Access Control 제공</div>
                                    <div className="text-14">메시징, Webhook, Scheduler 등 이벤트 기반 자동화 기능 지원</div>
                                    <div className="text-14">Object Storage, Email 발송, URL Shortener 등 클라우드 핵심 서비스 제공</div>
                                    <div className="text-14">Workflow, Rule 기반 자동화로 비즈니스 프로세스 최적화</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="feature-btn-gr fill-btn">
                        <button className="fea-button primary-btn txt-14 btn-center">서비스 신청</button>
                    </div>
                </div>
            </div>

            <div className="wrap-box clients" id="clients">
                <div className="fit-box">
                    <div className="stats-section" ref={sectionRef}>
                        <div className="stats-row">
                            <div className="stats-card">
                                <div className="stats-des">
                                    <div className="stats-content">
                                        <div className="stats-tt">YEARS</div>
                                        <div className="stats-dt">한비로 업력</div>
                                    </div>

                                    <div className="stats-icon">
                                        <CalendarMonthIcon className="stats-fea-icon" />
                                    </div>
                                </div>
                                <div className="stats-value">
                                    <p>
                                        <CountUp start={0} end={24} duration={2} activated={activated} />
                                        <span className="stats-txt">년</span>
                                    </p>
                                </div>
                            </div>

                            <div className="stats-card">
                                <div className="stats-des">
                                    <div className="stats-content">
                                        <div className="stats-tt">SERVERS</div>
                                        <div className="stats-dt">서버 관리 운영</div>
                                    </div>
                                    <div className="stats-icon">
                                        <DnsIcon className="stats-fea-icon" />
                                    </div>
                                </div>
                                <div className="stats-value">
                                    <p>
                                        <CountUp start={0} end={5000} duration={2} activated={activated} />
                                        <span className="stats-txt">대</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="stats-row">
                            <div className="stats-card">
                                <div className="stats-des">
                                    <div className="stats-content">
                                        <div className="stats-tt">USERS</div>
                                        <div className="stats-dt">메일/그룹웨어 사용</div>
                                    </div>
                                    <div className="stats-icon">
                                        <GroupsIcon className="stats-fea-icon" />
                                    </div>
                                </div>
                                <div className="stats-value">
                                    <p>
                                        <CountUp start={0} end={300000} duration={2} activated={activated} />
                                        <span className="stats-txt">유저</span>
                                    </p>
                                </div>
                            </div>

                            <div className="stats-card">
                                <div className="stats-des">
                                    <div className="stats-content">
                                        <div className="stats-tt">COMPANY</div>
                                        <div className="stats-dt">그룹웨어 • 서버관리운영</div>
                                    </div>
                                    <div className="stats-icon">
                                        <ApartmentIcon className="stats-fea-icon" />
                                    </div>
                                </div>
                                <div className="stats-value">
                                    <p>
                                        <CountUp start={0} end={8000} duration={2} activated={activated} />
                                        <span className="stats-txt">사</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="wrap-title">
                        {/* <div className="txt-32">한비로와 함께하는 다양한 고객사</div>
                        <div className="txt-32">한비로와 함께 성공적인 길로 나아가는 기업들입니다.</div> */}
                    </div>
                </div>

                <div className="newscroll-row">
                    <div class="new-scroll">
                        <div class="new-m-scroll">
                            <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                        </div>
                        <div class="new-m-scroll">
                            <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                            <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap-box p-t-0">
                <div className="fit-box">
                    <div className="bg-bottom">
                        <div className="bg-bottom-left">
                            <p className="txt-32 wh-color mb-2">더 스마트한 업무, 더 빠른 성장</p>
                            <p className="txt-16 wh-color">스마트 그룹웨어로 불필요한 업무 시간을 줄이고, 핵심 업무에 집중하세요.  지금 바로 상담받고, 생산성 향상의 해답을 찾아보세요.</p>
                        </div>
                        <div className="bg-bottom-right">
                            <div className="feature-btn-gr">
                                <button className="fea-button primary-btn txt-14">데모 신청</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default IndexPage1;