import React from 'react';
import styled from 'styled-components';
import '../../Card/Components/Contenttitlecard.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Box from '@mui/material/Box'
import '../../../Style/defaultstyle.scss';






function Titlecloudcompute($title1) {
  
  return (
    <>
        <div className='App'>
            <Row>
                <Col md={12} >
                  <Box sx={CloudTitle} className='header'>{$title1}</Box>
                </Col>
            </Row>
        </div>
        

    </>
  );
}







const CloudTitle = {
  fontWeight: "bold",
  paddingTop: "4%",
  paddingBottom: "2%",
}


const Titlecloud2 = styled.div`
  font-weight: bold;
  font-size:28px;
  padding-top:4%;
  padding-bottom:2%
    
`;







export default Titlecloudcompute;
