import React, { useState } from "react";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import NavButton from '../homepage/header-banner/TopNavButton';
import Footer from '../homepage/footer/Footer';
import './assets/css/main-demo.css';
import CustomizationTools from './assets/image/Customization Tools.svg';
import SliderDots from './assets/image/Slider dots.png';
import CustomizationTools2 from './assets/image/Customization Tools2.svg';
import check0 from './assets/image/check0.svg';
import Bannerimg from './assets/image/banner-img.png';
import { Check } from '@mui/icons-material';
import { Mail } from "@mui/icons-material";
import { Person } from "@mui/icons-material";
import { Inventory } from "@mui/icons-material";
import AiMail from './assets/image/ai-mail-1.png';
import GroupwareMail from './assets/image/groupware-mail-1.png';

function IndexDemo() {
    const [activeTabAI, setActiveTabAI] = useState('Mail');
    const [activeTabGroupware, setActiveTabGroupware] = useState('메일');

    const openTabAI = (tabName) => {
        setActiveTabAI(tabName);
    };

    const openTabGroupware = (tabName) => {
        setActiveTabGroupware(tabName);
    };

    return (
        <div>
            <NavButton option={{
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#6861FF' },
                itemNavClass: "item-nav pur-color",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            
            <div class="main-content-page">
                <section className="wrapper banner-pur">
                    <div className="row repon-banner">
                        <div className="banner-text">
                            <div className="banner-gr banner-gr-top">
                                <div className="banner-item banner-item-col1">
                                    <div className="banner-item-tt">프라이빗 쿠버네티스 인프라 구축</div>
                                    <div className="banner-item-ct">안전하고 확장 가능한 프라이빗 쿠버네티스 인프라!  <br />
                                        전문가가 컨설팅하고 구축 해 드립니다.</div>
                                </div>
                                <div className="banner-item banner-item-col2">
                                    <div className="banner-item-tt">전문 엔지니어 기술 지원</div>
                                    <div className="banner-item-ct">Windows, Linux, 클라우드 환경까지! <br />
                                        서버 운영과 유지보수를 전문 엔지니어가 책임집니다.</div>
                                </div>
                            </div>
                            <div className="banner-gr banner-gr-bottom">
                                <div className="banner-item banner-item-col1">
                                    <div className="banner-item-tt">베어베탈 서버 임대</div>
                                    <div className="banner-item-ct">고성능 베어메탈 서버! <br />
                                        기업 전용 맞춤형 서버를 제공합니다.</div>
                                </div>
                                <div className="banner-item banner-item-col2">
                                    <div className="banner-item-tt">AI 기반 협업 솔루션</div>
                                    <div className="banner-item-ct">통화 및 화상회의까지 자동 요약 및 번역! <br />
                                        LLM 기반의 협업 솔루션을 제공합니다.</div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-image">
                            <img src={Bannerimg} />
                        </div>
                    </div>
                </section>

                <section class="ai-solution">
                    <div>
                        <div class="ai-solution-header">
                            <h1 class="title-AI">AI 협업솔루션</h1>
                            <p class="title-description-ai">최신 LLM 기술을 활용한 메일, 메신저, 그룹웨어 솔루션을 제공합니다.</p>
                        </div>
                        <div class="tab-container-ai">
                            <div class="tab-ai">
                                <button
                                    class={`tablinks-ai ${activeTabAI === 'Mail' ? 'active' : ''}`}
                                    onClick={() => openTabAI('Mail')}
                                >
                                    Mail
                                </button>
                                <button
                                    class={`tablinks-ai ${activeTabAI === '메신저' ? 'active' : ''}`}
                                    onClick={() => openTabAI('메신저')}
                                >
                                    메신저
                                </button>
                                <button
                                    class={`tablinks-ai ${activeTabAI === '헬프데스크' ? 'active' : ''}`}
                                    onClick={() => openTabAI('헬프데스크')}
                                >
                                    헬프데스크
                                </button>
                            </div>
                            {/* Mail Tab Content */}
                            {activeTabAI === 'Mail' && (
                                <div id="Mail" class="tabcontent-ai">
                                    <div class="tab-content-ai">
                                        <div class="content-tab-ai">
                                            <div class="tab-ai-header">Mail 스마트 이메일 관리</div>
                                            <div class="content-list-ai">
                                                <div class="timeline">
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">AI 요약 및 번역</div>
                                                        <div class="list-content-ai">메일 목록 및 본문에서 자동 요약 및 번역 기능 제공</div>
                                                    </div>
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">스마트 메일 작성 지원</div>
                                                        <div class="list-content-ai">메일 작성 초안 및 키워드 추천 기능 으로 효율 극대화</div>
                                                    </div>
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">고급 스팸 필터링 및 보안</div>
                                                        <div class="list-content-ai">AI기반의 정교한 스팸 필터링 및 위협 탐지 기능 제공</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="feature-img-wrap">
                                            <img class="feature-img" src={AiMail} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* 메신저 Tab Content */}
                            {activeTabAI === '메신저' && (
                                <div id="메신저" class="tabcontent-ai">
                                    <div class="tab-content-ai">
                                        <div class="content-tab-ai">
                                            <div class="tab-ai-header">메신저 실시간 커뮤니케이션</div>
                                            <div class="content-list-ai">
                                                <div class="timeline">
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">다국어 번역</div>
                                                        <div class="list-content-ai">내 대화내용을 자동으로 번역하여 상대방에게 전달</div>
                                                    </div>
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">오늘의 대화  요약</div>
                                                        <div class="list-content-ai">대화방별로 오늘의 대화 내용을 요약 제공</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="feature-img-wrap">
                                            <img class="feature-img" src={AiMail} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* 헬프데스크 Tab Content */}
                            {activeTabAI === '헬프데스크' && (
                                <div id="헬프데스크" class="tabcontent-ai">
                                    <div class="tab-content-ai">
                                        <div class="content-tab-ai">
                                            <div class="tab-ai-header">고객지원 스마트한 고객 지원 관리 </div>
                                            <div class="content-list-ai">
                                                <div class="timeline">
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">티켓 </div>
                                                        <div class="list-content-ai">여러 경로를 통해 들어오는 고객 문의를 한곳에서 처리</div>
                                                    </div>
                                                    <div class="timeline-item">
                                                        <div class="list-item-ai">AI call +</div>
                                                        <div class="list-content-ai">고객과의 통화 내용을 번역 및 요약</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="feature-img-wrap">
                                            <img class="feature-img" src={AiMail} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                
                <section class="groupware-about">
                    <div class="groupware-about-container">
                        <div class="groupware-about-header">
                            그룹웨어
                        </div>
                        <div class="groupware-about-content">
                            <div class="groupware-about-tab">
                                <button
                                    class={`tablinks-gw ${activeTabGroupware === '메일' ? 'active' : ''}`}
                                    onClick={() => openTabGroupware('메일')}
                                >
                                    메일
                                </button>
                                <button
                                    class={`tablinks-gw ${activeTabGroupware === '메신저' ? 'active' : ''}`}
                                    onClick={() => openTabGroupware('메신저')}
                                >
                                    메신저
                                </button>
                                <button
                                    class={`tablinks-gw ${activeTabGroupware === '헬프데스크' ? 'active' : ''}`}
                                    onClick={() => openTabGroupware('헬프데스크')}
                                >
                                    헬프데스크
                                </button>
                                <button
                                    class={`tablinks-gw ${activeTabGroupware === '전자결재' ? 'active' : ''}`}
                                    onClick={() => openTabGroupware('전자결재')}
                                >
                                    전자결재
                                </button>
                                <button
                                    class={`tablinks-gw ${activeTabGroupware === 'HR' ? 'active' : ''}`}
                                    onClick={() => openTabGroupware('HR')}
                                >
                                    HR
                                </button>
                                <button
                                    class={`tablinks-gw ${activeTabGroupware === '협업관리' ? 'active' : ''}`}
                                    onClick={() => openTabGroupware('협업관리')}
                                >
                                    협업관리
                                </button>
                            </div>
                            {/* 메일 Tab Content */}
                            {activeTabGroupware === '메일' && (
                                <div id="메일" class="tabcontent-gw">
                                    <div class="tab-content-gw">
                                        <div class="groupware-about-text">
                                            <div class="groupware-left-text">
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">이메일 &amp; 스팸 필터링</div>
                                                    <div class="primary-18-400">
                                                        AI 기반 번역, 요약 기능을 제공합니다.
                                                        별도 구매 없이 , <br />그룹웨어 내 스팸 솔루션이 기본으로 포함되어 있습니다.

                                                    </div>
                                                </div>
                                                <div class="groupware-text-middle">
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            SPF, DKIM, DMARC
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            POP3/IMAP, 스팸 95% 차단
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">보안메일 , 승인 메일 , 대량 메일</div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">외부메일 연동 , 메일함 공유 ( 퇴사자 포함 )</div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">퇴사자 메일 공유, 퇴사자 메일 전달
                                                            데모신청</div>
                                                    </div>
                                                </div>
                                                <div class="groupware-button">
                                                    <div class="button-demo">
                                                        <div class="white-18-500">데모신청</div>
                                                    </div>
                                                    <div class="pur-18-500">View more</div>
                                                </div>
                                            </div>
                                            <div class="feature-img-wrap">
                                                <img class="feature-img" src={GroupwareMail} alt="Groupware Mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* 메신저 Tab Content */}
                            {activeTabGroupware === '메신저' && (
                                <div id="메신저" class="tabcontent-gw">
                                    <div class="tab-content-gw">
                                        <div class="groupware-about-text">
                                            <div class="groupware-left-text">
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">메신저 &amp; 회상회의</div>
                                                    <div class="primary-18-400">
                                                        팀원들과 자유롭게 실시간 소통합니다.
                                                    </div>
                                                </div>
                                                <div class="groupware-text-middle">
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            화상회의 내용을 요약하고 번역합니다. 
                                                            실시간 번역 및 오늘의 대화 요약 기능을 통하여 팀원들과 자유롭게 소통합니다.
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            화면 공유 및 보이스톡, 화상회의 기능을 통한 업무 협업 지원
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            게스트와의 화상회의 지원
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="groupware-button">
                                                    <div class="button-demo">
                                                        <div class="white-18-500">데모신청</div>
                                                    </div>
                                                    <div class="pur-18-500">View more</div>
                                                </div>
                                            </div>
                                            <div  class="feature-img-wrap">
                                                <img class="feature-img" src={GroupwareMail} alt="Groupware Mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* 헬프데스크 Tab Content */}
                            {activeTabGroupware === '헬프데스크' && (
                                <div id="헬프데스크" class="tabcontent-gw">
                                    <div class="tab-content-gw">
                                        <div class="groupware-about-text">
                                            <div class="groupware-left-text">
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">티켓</div>
                                                    <div class="primary-18-400">
                                                        고객과의 전화 이력을 저장하고 분석합니다.
                                                        이전 통화 기록과 관련 정보를 바탕으로 더 나은 서비스를 제공할 수 있도록 도와줍니다.
                                                        AI기반 통화내용 요약 및 번역합니다.
                                                    </div>
                                                </div>
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">AI Call +</div>
                                                    <div class="primary-18-400">
                                                        여러 경로를 통해 들어오는 고객문의를 하나의 창구에서 관리합니다.
                                                        접수부터 최종 답변이 제공될 때 까지의 모든 과정을 지속적으로 추적하고 지원합니다.
                                                    </div>
                                                </div>
                                                <div class="groupware-button">
                                                    <div class="button-demo">
                                                        <div class="white-18-500">데모신청</div>
                                                    </div>
                                                    <div class="pur-18-500">View more</div>
                                                </div>
                                            </div>
                                            <div class="feature-img-wrap">
                                                <img class="feature-img" src={GroupwareMail} alt="Groupware Mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* 전자결재 Tab Content */}
                            {activeTabGroupware === '전자결재' && (
                                <div id="전자결재" class="tabcontent-gw">
                                    <div class="tab-content-gw">
                                        <div class="groupware-about-text">
                                            <div class="groupware-left-text">
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">전자결재</div>
                                                    <div class="primary-18-400">
                                                        PC와 모바일을 통해 어제 어디서나 의사결정을 진행함으로써 빠른 업무
                                                        처리가 이루어집니다.
                                                    </div>
                                                </div>
                                                <div class="groupware-text-middle">
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            무료 기본 양식 제공 ( 약 300 건 )
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            엑셀에디터 양식을 통한 자동수식, 간단 수식계산
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">보안결재를 이용한 이중 보안</div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">양식별 결재선 지정 , 본인만의 자주쓰는 결재선 저장</div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">전자결재 ERP연동 지원</div>
                                                    </div>
                                                </div>
                                                <div class="groupware-button">
                                                    <div class="button-demo">
                                                        <div class="white-18-500">데모신청</div>
                                                    </div>
                                                    <div class="pur-18-500">View more</div>
                                                </div>
                                            </div>
                                            <div class="feature-img-wrap">
                                                <img class="feature-img" src={GroupwareMail} alt="Groupware Mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* HR Tab Content */}
                            {activeTabGroupware === 'HR' && (
                                <div id="HR" class="tabcontent-gw">
                                    <div class="tab-content-gw">
                                        <div class="groupware-about-text">
                                            <div class="groupware-left-text">
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">근태관리 &amp; 휴가관리</div>
                                                    <div class="primary-18-400">
                                                        기본근무제, 탄력근무제, 선택근무제 등의 다양한 근무유형을 제공하며,
                                                        연차관리의 자동화 통해 직원의 출/퇴근, 휴무일을 관리합니다.
                                                    </div>
                                                </div>
                                                <div class="groupware-text-middle">
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            모바일을 통한 GPS, WIFI, Beacon 방식을 통한 출,퇴근 관리
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            근태 승인 기능 제공
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">일/주/월 별 리포트 엑셀 다운로드 기능 제공</div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">대시보드에 직원 출,퇴근 및 연차 현황 제공</div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">연차관리 자동화 및 연차촉진제 지원</div>
                                                    </div>
                                                </div>
                                                <div class="groupware-button">
                                                    <div class="button-demo">
                                                        <div class="white-18-500">데모신청</div>
                                                    </div>
                                                    <div class="pur-18-500">View more</div>
                                                </div>
                                            </div>
                                            <div class="feature-img-wrap">
                                                <img class="feature-img" src={GroupwareMail} alt="Groupware Mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* 협업관리 Tab Content */}
                            {activeTabGroupware === '협업관리' && (
                                <div id="협업관리" class="tabcontent-gw">
                                    <div class="tab-content-gw">
                                        <div class="groupware-about-text">
                                            <div class="groupware-left-text">
                                                <div class="groupware-text">
                                                    <div class="primary-44-400">협업관리</div>
                                                    <div class="primary-18-400">
                                                        회사에 맞게 업무 템플릿을 만들고, 그 일감에 맞는 업무 담당자 와
                                                        진행상황을 등록하여 체계적으로 업무를 관리합니다.
                                                    </div>
                                                </div>
                                                <div class="groupware-text-middle">
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            회사별 맞춤 업무 플로우 설정
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            투명한 업무 지시로 담당자의 책임을 명확히하고
                                                            업무의 병목현상을 방지
                                                        </div>
                                                    </div>
                                                    <div class="groupware-li">
                                                        <img class="check" src={check0} />
                                                        <div class="primary-18-400">
                                                            변경사항 알림을 통해 실시간으로 업무 진행
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="groupware-button">
                                                    <div class="button-demo">
                                                        <div class="white-18-500">데모신청</div>
                                                    </div>
                                                    <div class="pur-18-500">View more</div>
                                                </div>
                                            </div>
                                            <div class="feature-img-wrap">
                                                <img class="feature-img" src={GroupwareMail} alt="Groupware Mail" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                <section className="wrapper cloud">
                    <div className="text70">
                        프라이빗 클라우드
                    </div>
                    <div className="cloud-tab">
                        <div class="d-flex align-items-start">
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <div className="text44 light-color">Kubernetes <br /> 클러스터 </div>
                                <button class="nav-link active" id="v-pills-cloud1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cloud1" type="button" role="tab" aria-controls="v-pills-cloud1" aria-selected="true">Kubernetes 클러스터</button>
                                <button class="nav-link" id="v-pills-cloud2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cloud2" type="button" role="tab" aria-controls="v-pills-cloud2" aria-selected="false">VM 클라우드</button>
                                <button class="nav-link" id="v-pills-cloud3-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cloud3" type="button" role="tab" aria-controls="v-pills-cloud3" aria-selected="false">서버인대 및 관리 운영</button>
                            </div>
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane show active" id="v-pills-cloud1" role="tabpanel" aria-labelledby="v-pills-cloud1-tab">

                                    <div className="tab-title">
                                        {/* <div className="text44 mb-3">Kubernetes 클러스터</div> */}
                                        <div className="text18">최신 쿠버네티스(Kubernetes) 클러스터 기술을 활용하여 마이크로서비스 기반 서비스 환경을 구축하여 드립니다. <br />
                                            확장성과 유연성을 갖춘 인프라를 통해 서비스를 보다 안정적으로 운영하여 보시기 바랍니다.</div>
                                    </div>

                                    <div className="tab-li">
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">프라이빗 클러스터는 애플리케이션을 자동으로 배포, 확장 및 관리하는 오케스트레이션 시스템으로,
                                                컴퓨팅 자원과 인력 비용을 효과적으로 절감합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">단일 고객사를 위한 프라이빗 클러스터로 구축 및 운영되어 뛰어난 보안을 제공합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">베어메탈 임대 또는 자체 장비를 활용한 프라이빗 클러스터 구축을 지원합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">자체 구축한 클러스터를 위한 코로케이션(Colocation) 서비스를 제공합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">데이터센터 및 하이브리드 클라우드 환경에서 안정적인 운영을 지원합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">기존 베어메탈 환경을 프라이빗 클러스터로 마이그레이션 할 수 있도록  기술 지원을 제공합니다.</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane" id="v-pills-cloud2" role="tabpanel" aria-labelledby="v-pills-cloud2-tab">

                                    <div className="tab-title">
                                        {/* <div className="text44 mb-3">VM 클라우드</div> */}
                                        <div className="text18">Proxmox 클러스터는 고가용성, 확장성, 중앙 집중식 관리, 라이브 마이그레이션 등의 강력한 기능을 제공합니다</div>
                                    </div>

                                    <div className="tab-li">
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">HA 관리 기능을 활용하여 자동 페일오버(Failover)를 구현해 서비스의 고가용성을 보장합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">중앙 집중식 관리 방식을 통해 여러 노드를 효율적으로 운영 및 관리할 수 있습니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">가상 머신을 중단 없이 한 노드에서 다른 노드로 이동할 수 있는 라이브 마이그레이션 기능을 지원합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">고가용성 분산 스토리지를 활용하여 안정적인 백업 및 스냅샷 기능을 제공합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">기존 클러스터에 새로운 노드를 손쉽게 추가하여 확장성을 극대화할 수 있습니다.</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="tab-pane" id="v-pills-cloud3" role="tabpanel" aria-labelledby="v-pills-cloud3-tab">
                                    <div className="tab-title">
                                        {/* <div className="text44 mb-3">서버인대 및 관리 운영</div> */}
                                        <div className="text18">상담을 통해 서비스에 최적화된 베어메탈 임대 서비스를 제공합니다.</div>
                                    </div>

                                    <div className="tab-li">
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">리눅스, 윈도우, 기타 OS 설치를 지원하여 다양한 환경에 맞춘 운영이 가능합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">서비스에 적합한 맞춤형(Custom) 스펙으로 임대가 가능하여 비용 절감 효과를 제공합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">관리 운영 및 코로케이션 서비스를 선택하여 필요에 맞게 이용할 수 있습니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">전문 엔지니어의 24시간 모니터링으로 장애 발생 시 신속한 대응이 가능합니다.</div>
                                        </div>
                                        <div class="block-tab">
                                            <Check className="check" />
                                            <div class="text18">서버 이전 및 마이그레이션 서비스를 제공하여 원활한 환경 전환을 지원합니다.</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cloud-accordian">
                        <div className="text44 light-color">Kubernetes <br /> 클러스터 </div>
                        <div class="accordion d-lg-none" id="cloudAccordion">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" data-bs-toggle="collapse" data-bs-target="#cloud1">
                                        Kubernetes 클러스터
                                    </button>
                                </h2>
                                <div id="cloud1" class="accordion-collapse collapse show" data-bs-parent="#cloudAccordion">
                                    <div class="accordion-body">
                                        <div className="tab-title">
                                            {/* <div className="text44 mb-3">Kubernetes 클러스터</div> */}
                                            <div className="text18">최신 쿠버네티스(Kubernetes) 클러스터 기술을 활용하여 마이크로서비스 기반 서비스 환경을 구축하여 드립니다. <br />
                                                확장성과 유연성을 갖춘 인프라를 통해 서비스를 보다 안정적으로 운영하여 보시기 바랍니다.</div>
                                        </div>

                                        <div className="tab-li">
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">프라이빗 클러스터는 애플리케이션을 자동으로 배포, 확장 및 관리하는 오케스트레이션 시스템으로,
                                                    컴퓨팅 자원과 인력 비용을 효과적으로 절감합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">단일 고객사를 위한 프라이빗 클러스터로 구축 및 운영되어 뛰어난 보안을 제공합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">베어메탈 임대 또는 자체 장비를 활용한 프라이빗 클러스터 구축을 지원합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">자체 구축한 클러스터를 위한 코로케이션(Colocation) 서비스를 제공합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">데이터센터 및 하이브리드 클라우드 환경에서 안정적인 운영을 지원합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">기존 베어메탈 환경을 프라이빗 클러스터로 마이그레이션 할 수 있도록  기술 지원을 제공합니다.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#cloud2">
                                        VM 클라우드
                                    </button>
                                </h2>
                                <div id="cloud2" class="accordion-collapse collapse" data-bs-parent="#cloudAccordion">
                                    <div class="accordion-body">
                                        <div className="tab-title">
                                            {/* <div className="text44 mb-3">VM 클라우드</div> */}
                                            <div className="text18">Proxmox 클러스터는 고가용성, 확장성, 중앙 집중식 관리, 라이브 마이그레이션 등의 강력한 기능을 제공합니다</div>
                                        </div>

                                        <div className="tab-li">
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">HA 관리 기능을 활용하여 자동 페일오버(Failover)를 구현해 서비스의 고가용성을 보장합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">중앙 집중식 관리 방식을 통해 여러 노드를 효율적으로 운영 및 관리할 수 있습니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">가상 머신을 중단 없이 한 노드에서 다른 노드로 이동할 수 있는 라이브 마이그레이션 기능을 지원합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">고가용성 분산 스토리지를 활용하여 안정적인 백업 및 스냅샷 기능을 제공합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">기존 클러스터에 새로운 노드를 손쉽게 추가하여 확장성을 극대화할 수 있습니다.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#cloud3">
                                        서버인대 및 관리 운영
                                    </button>
                                </h2>
                                <div id="cloud3" class="accordion-collapse collapse" data-bs-parent="#cloudAccordion">
                                    <div class="accordion-body">
                                        <div className="tab-title">
                                            {/* <div className="text44 mb-3">서버인대 및 관리 운영</div> */}
                                            <div className="text18">상담을 통해 서비스에 최적화된 베어메탈 임대 서비스를 제공합니다.</div>
                                        </div>

                                        <div className="tab-li">
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">리눅스, 윈도우, 기타 OS 설치를 지원하여 다양한 환경에 맞춘 운영이 가능합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">서비스에 적합한 맞춤형(Custom) 스펙으로 임대가 가능하여 비용 절감 효과를 제공합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">관리 운영 및 코로케이션 서비스를 선택하여 필요에 맞게 이용할 수 있습니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">전문 엔지니어의 24시간 모니터링으로 장애 발생 시 신속한 대응이 가능합니다.</div>
                                            </div>
                                            <div class="block-tab">
                                                <Check className="check" />
                                                <div class="text18">서버 이전 및 마이그레이션 서비스를 제공하여 원활한 환경 전환을 지원합니다.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="wrapper clients">
                    <div className="stats">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="stats-column">
                                    <Mail className="stats-icon" />
                                    <div className="stats-number">24</div>
                                    <div className="text18">년 한비로 업력</div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="stats-column">
                                    <Person className="stats-icon" />
                                    <div className="stats-number">5,000</div>
                                    <div className="text18">대 서버 관리 운영</div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="stats-column">
                                    <Inventory className="stats-icon" />
                                    <div className="stats-number">300,000</div>
                                    <div className="text18">유저 메일/그룹웨어 사용</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="clients-title">
                        <div className="text44 mb-3">한비로와 함께하는 다양한 고객사</div>
                        <div className="text18">한비로와 함께 성공적인 길로 나아가는 기업들입니다.</div>
                    </div>

                    <div className="newscroll-row">
                        <div class="new-scroll">
                            <div class="new-m-scroll">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                            </div>
                            <div class="new-m-scroll">
                                <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                                <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="wrapper section-linear">
                    <div className="linear-title">
                        <div className="text44 light-color">데모신청 을 하시면 ,  무료로 30일간 체험이 가능합니다</div>
                        <div className="text18 light-color">헬프데스크 통합관리 솔루션인 CRM은 한비로 그룹웨어에 기본으로 제공됩니다. <br />
                            별도의 추가 비용 없이 헬프데스크 를 만나보세요.</div>
                    </div>

                    <div className="switch-box">
                        <div className="text18">당신의 이메일</div>
                        <div className="switch-btn">
                            <div className="text18 light-color">시도 데모</div>
                        </div>
                    </div>
                </section>

            </div>

            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
}

export default IndexDemo;
