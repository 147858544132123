import * as React from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Box, TableCell } from '@mui/material';
import styled from "styled-components";


const cellHeight = "40px"
const headerHeight = "40px"
const borderStyles = "1px solid #D7E5ED"

function StorageFee () {
    const header = {
        col1: "구분",
        col2: "구간",
        col3: "요금 (GB/월)",
    }

    const rows = {
        row_1: {
            col1: "한비로 Cloud 서비스통신구간",
            col2: "-",
            col3: "무료",
        },
        row_2: {
            col1: "인바운드 트래픽",
            col2: "-",
            col3: "무료",
        },
        row_3: {
            col1: "아웃바운드 트래픽 (기본)",
            col2: "900 GB / 월",
            col3: "무료",
        },
        row_4: {
            col1: `국내망 아웃바운드 트래픽<br>(초과사용)`,
            col2: "1 G ~ 1,000 G",
            col3: "1 G ~ 1,000 G",
        },   
        row_5: {
            col1: "",
            col2: "1,001 G ~ 2,000 G",
            col3: "1,001 G ~ 2,000 G",
        },
        row_6: {
            col1: "",
            col2: "2,001 G ~ 3,000 G",
            col3: "50 원",
        },
        row_7: {
            col1: "",
            col2: "3,001 G ~ 4,000 G",
            col3: "40 원",
        },
        row_8: {
            col1: "",
            col2: "4,001 G ~",
            col3: "30 원",
        },
        row_9: {
            col1: "해외망 아웃바운드 트래픽",
            col2: "별도문의",
            col3: "-",
        },
    }
    
    return(
        <Box>
            <Box className='header' sx={HeaderWrap}>트래픽요금안내</Box>
            <TableContainer>
                <Table sx={{ minwidth: "600px", borderCollapse: "separate"}}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead width="30%" style={{minWidth: "195px", position: "sticky", left: 0, background: "white"}}><Box className="sub-title" sx={ColumnHeader} style={{borderRadius: "6px 0px 0px 0px"}} dangerouslySetInnerHTML={{__html: `${header.col1}`}}></Box></StyledTableHead>
                            <StyledTableHead width="35%" style={{minWidth: "160px", borderLeft: borderStyles, borderRight: borderStyles}}><Box className="sub-title" sx={ColumnHeader} dangerouslySetInnerHTML={{__html: `${header.col2}`}}></Box></StyledTableHead>
                            <StyledTableHead width="35%" style={{minWidth: "160px"}}><Box className="sub-title" sx={ColumnHeader} style={{borderRadius: "0px 6px 0px 0px"}} dangerouslySetInnerHTML={{__html: `${header.col3}`}}></Box></StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}}><Box dangerouslySetInnerHTML={{__html: `${rows.row_1.col1}`}} sx={BoxTitle}></Box></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_1.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_1.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_2.col1}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_2.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_2.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_3.col1}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_3.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_3.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} rowSpan={5} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_4.col1}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_4.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_4.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_5.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_5.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_6.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_6.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_7.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_7.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_8.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_8.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_9.col1}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_9.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_9.col3}`}}></StyledTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        
    )
}


function UsuageFee () {

    const header = {
        col1: "구분",
        col2: "내용",
        col3: "요금 (월)",
    }

    const rows = {
        row_1: {
            col1: "VPC 네트워크환경",
            col2: "클러스터 시스템 구축용 독립 네트워크",
            col3: "10,000 원",
        },
        row_2: {
            col1: "이메일발송<br />(대용량메일사용불가)",
            col2: "독립 이메일 인스턴스 구축",
            col3: "10,000 원",
        },
        row_3: {
            col1: "아이피추가",
            col2: "대의 가상서버 아이피 최대 3개추가",
            col3: "3,000 원",
        },
        
    }

    return(
        <Box>
            <Box className='header' sx={HeaderWrap}>VPC / 아이피 / 이메일 사용요금안내</Box>
            <TableContainer>
                <Table sx={{ minwidth: "600px", borderCollapse: "separate"}}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead width="30%" style={{minWidth: "195px", position: "sticky", left: 0, background: "white"}}><Box className="sub-title" sx={ColumnHeader} style={{borderRadius: "6px 0px 0px 0px"}} dangerouslySetInnerHTML={{__html: `${header.col1}`}}></Box></StyledTableHead>
                            <StyledTableHead width="35%" style={{minWidth: "160px", borderLeft: borderStyles, borderRight: borderStyles}}><Box className="sub-title" sx={ColumnHeader} dangerouslySetInnerHTML={{__html: `${header.col2}`}}></Box></StyledTableHead>
                            <StyledTableHead width="35%" style={{minWidth: "160px"}}><Box className="sub-title" sx={ColumnHeader} style={{borderRadius: "0px 6px 0px 0px"}} dangerouslySetInnerHTML={{__html: `${header.col3}`}}></Box></StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_1.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_1.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_1.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_2.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_2.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_2.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_3.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_3.col2}`}}></StyledTableCell>
                            <StyledTableCell dangerouslySetInnerHTML={{__html: `${rows.row_3.col3}`}}></StyledTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}


function OperationSupport () {

    const header = {
        col1: "리눅스",
        col2: "종류",
        col3: "요금",
    }

    const rows = {
        row_1: {
            col1: "리눅스",
            col2: "리눅스	CentOS, Rocky Linux, Ubuntu, Debian",
            col3: `Microsoft <br> 라이센스 <br> 
                    요금정책별도`,
        },
        row_2: {
            col1: "윈도우",
            col2: `Windows 2012~2019 Server (64비트) <br>
                    MS-SQL 2012 ~ 2019 Standard (64비트)`,
            col3: "",
        },
        row_3: {
            col1: "데이터베이스",
            col2: `MySQL, PostgreSQL, Redis, MongoDB <br>
                    scyllaDB, Vitess, ClickHouse`,
            col3: "",
        },
        row_4: {
            col1: "쿠버네티스 클러스터",
            col2: `Nginx, Kong, Envoy, Kafka, ELK stack <br>
                    Kubernetes Database Cluster`,
            col3: "",
        },
        
    }

    return(
        <Box>
            <Box className='header' sx={HeaderWrap}>운영체제 및 데이터베이스 지원</Box>
            <TableContainer>
                <Table sx={{ minwidth: "600px", borderCollapse: "separate"}}>
                    <TableHead>
                        <TableRow>
                            <StyledTableHead width="30%" style={{minWidth: "195px", position: "sticky", left: 0, background: "white", borderRadius: "6px 0px 0px 0px"}}><Box className="sub-title" sx={ColumnHeader} dangerouslySetInnerHTML={{__html: `${header.col1}`}}></Box></StyledTableHead>
                            <StyledTableHead width="35%" style={{minWidth: "160px", borderLeft: borderStyles, borderRight: borderStyles}}><Box className="sub-title" sx={ColumnHeader} dangerouslySetInnerHTML={{__html: `${header.col2}`}}></Box></StyledTableHead>
                            <StyledTableHead width="35%" style={{minWidth: "160px"}}><Box className="sub-title" sx={ColumnHeader} style={{borderRadius: "0px 6px 0px 0px"}} dangerouslySetInnerHTML={{__html: `${header.col3}`}}></Box></StyledTableHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_1.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_1.col2}`}}></StyledTableCell>
                            <StyledTableCell rowSpan={4} dangerouslySetInnerHTML={{__html: `${rows.row_1.col3}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_2.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_2.col2}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_3.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_3.col2}`}}></StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell sx={RowTitle} style={{position: "sticky", left: 0, background: "white"}} dangerouslySetInnerHTML={{__html: `${rows.row_4.col1}`}}></StyledTableCell>
                            <StyledTableCell style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: `${rows.row_4.col2}`}}></StyledTableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}


function FeeAndSupport () {

    return(
        <Box sx={CardContent}>
            <StorageFee />
            <Box sx={{height: "30px"}}></Box>
            <UsuageFee />
            <Box sx={{height: "30px"}}></Box>
            <OperationSupport />
        </Box>
        
    )
}

const BoxTitle = {
    '@media only screen and (max-width: 767px)' : {
      width: "100px",
    }
}

const CardContent = {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    width: "100%",
    borderRadius: "4px",
    padding: "20px 20px 50px 20px",
    marginBottom: "30px",
}

const HeaderWrap = {
    marginBottom: "20px",
    marginTop: "20px",
    fontWeight: "bold",
}

const StyledTableCell = styled(TableCell)({
    textAlign: "center",
    fontFamily: "inherit",
    fontSize: "inherit",
    border: borderStyles,
    padding: "16px 0 16px 5px",
})

const StyledTableHead = styled(TableCell)({
    padding: "0px",
    height: headerHeight,
    borderTop: "hidden !important",
})

const ColumnHeader = {
    color: "white",
    background: "#393939",
    fontWeight: "bold",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const RowTitle = {
    fontWeight: "bold",
    textAlign: "left !important"
}

export default FeeAndSupport;
