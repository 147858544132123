import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import CloudconsultCp from '../../homepage/consult/cloudconsult-cp';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommonTable from '../../container/Table/Components/CommonTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../kubernetes/asset/css/style.css';

const checkIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" height="12" fill="#7061ff"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>`
// Titlecloudcompute
function KubeBenefits() {

    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />

            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {/* {Titlecloudcompute("장점")} */}
                        {/* {Contentcloudcard(`Kubernetes 장점`)} */}
                        
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className='grid-card kube-header'>{CardBoxRow(
                                    "",

                                    `
                                    <div class="kube-wrap">
                                        <p style="margin:0; font-size: 21px; color: #7061ff"><b>Private Kubernetes 정의</b></p>
                                        <p style="margin:0; font-size: 14px;"><b>글로벌 클라우드 종속 없이, 자체 인프라에 Kubernetes 클러스터 구축</b></p>
                                        <p style="margin:0;">&nbsp;</p>
                                        <p style="margin:0">복잡한 트래픽/리소스 기반 과금 모델, 예측 불가능한 클라우드 비용에 의존하지 않고</p>
                                        <p style="margin:0">기업 내부 자원 위에 독립적인 <b>Kubernetes</b> 환경을 구성할 수 있습니다.</p>
                                        <p style="margin:0">클라우드 네이티브 인프라의 유연성을 유지하면서도, <b>비용 제어와 보안 통제가</b> 필요한 환경에 최적화된 대안입니다.</p>
                                    </div>
                                    `)}
                            </Grid>
                            <Grid item xs={12} sm={12} className='kube-top-card grid-card'>{CardBoxRow(
                                "",

                                `
                                <div class="kube-card-custom">
                                    <div class="kube-card-txt">
                                        <div>${checkIcon}온프레미스 또는 프라이빗 호스팅 서버 기반의 Kubernetes 클러스터 구축</div>
                                        <div>${checkIcon}글로벌 클라우드와 동일한 워크로드 오케스트레이션 및 오토스케일링 기능 제공</div>
                                        <div>${checkIcon}리소스 사용량과 무관한 고정형 또는 유연한 비용 구조</div>
                                        <div>${checkIcon}Prometheus, Grafana 등 통합 모니터링 스택 기본 제공</div>
                                        <div>${checkIcon}Stateful/Stateless 워크로드에 적합한 볼륨 관리 및 퍼시스턴트 스토리지 지원</div>
                                        <div>${checkIcon}기업 내부망 통합, 보안 정책 커스터마이징 가능</div>
                                    </div>
                                </div>
                                `)}
                            </Grid>

                            <Grid item xs={12} sm={12} className='grid-card'>{CardBoxRow(
                                "Kubernetes 장점",

                                `<div class="kube-card-txt">
                                    <div>${checkIcon}서버자원: 서버자원의 효율적인 사용</div>
                                    <div>${checkIcon}서버확장 (Autoscale): 서비스운영중 서버를 자유롭게 확장</div>
                                    <div>${checkIcon}무정지서비스: 재해복구, 서비스의 영속성</div>
                                    <div>${checkIcon}Cluster: 서버의 자동 확장, 로드발랜싱</div>
                                    <div>${checkIcon}Application: Microservice 플랫폼 운영</div>
                                </div>
                                `)}
                            </Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/kube-node.png")}</Grid>
                        </Grid>

                        <CloudconsultCp />
                    </Col>
                </Row>
            </Container>
            {/* <Cloudconsult /> */}
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

export default KubeBenefits;