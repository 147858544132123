import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import LoadBalancer from '../../homepage/header-banner/LoadBalancer';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBox from '../../container/Box/Components/CardBox'
import { Grid } from '@mui/material';

function Computeloadbalance(){

    return (
        <div className='App'>
            <NavButton />
            {/* {Banner("HANBIRO CLOUD","Compute Load Balancer")} */}
            <Container className='main-body'>
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Load Balancer")}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>{CardBox("한비로 Cloud 가상서버(인스턴스)를 연결,클러스터 시스템 구축", "한비로 Cloud 플랫폼의 가상서버의 로드발랜싱을 구현하며. 오토스케일링으로 확장된 가상서버와 자동으로 로드발랜서와의 연결을 지원합니다.")}</Grid>
                            <Grid item xs={12} sm={12}>{CardBox("부하분산 알고리즘 지원 (Round Robin, Least Connection, IP hash)","순차적부하분산(Round Robin), 최소연결부하분산(Least Connection), 소스아이피 해시(Source IP hash) 로드발랜싱 알고리즘을 지원합니다.")}</Grid>
                            <Grid item xs={12} sm={12}>{CardBox(
                                "쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축",
                                `한비로는 쿠버네티스와의 통합 Cloud 플랫폼을 운형하고 있습니다. <br>
                                가상서버(인스턴스)는 한비로 쿠버네티스 서비스 및 마이크로서비스와 통합 운영할 수 있습니다.<br>
                                자동으로 서버시스템을 확장하여, 대량트래픽 발생으로 인한 서비스장애를 대처할 수 있습니다.`
                                )}
                            </Grid>
                            <Grid item xs={12}>{Imagecloudcompute("https://www.hanbiro.com/cloud/library/compute-load-balance/loadbalancer.svg")}</Grid>
                        </Grid>
                        <LoadBalancer />
                       
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer />
        </div>
        
    );

};

export default Computeloadbalance;