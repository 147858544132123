import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavButton from '../homepage/header-banner/TopNavButton';
import Footer from '../homepage/footer/Footer';
import { CheckCircle } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import './asset/css/main.css';

function Kubernetes() {
    document.addEventListener('DOMContentLoaded', () => {
        AOS.init({
            once: false,
        });

        window.addEventListener("scroll", () => {
            AOS.refresh();
        });

        const tabs = document.querySelectorAll('[data-bs-toggle="pill"]');
        tabs.forEach(tab => {
            tab.addEventListener('shown.bs.tab', () => {
                AOS.refresh();
            });
        });

        return () => {
            window.removeEventListener("scroll", AOS.refresh);
        };
    });



    return (

        <div>
            <div>
                <NavButton option={{
                    // logo: logoKube,
                    logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                    link: "https://hanbiro.com/",
                    color: { backgroundColor: '#47A7FF' },
                    itemNavClass: "item-nav",
                    menuListProduct: "menu-list-product-item",
                    menuListVora: "vora",
                    menuListGroupware: "groupware",
                    titleTab: "mb-3",
                }} />
                <div className='kubernetes'>
                    <div class="header-kuber" >
                        <div class="title_banner" data-aos="fade-in" data-aos-duration="1200">
                            <p className="head-top">• <span className="head-text-top">Hanbiro Kube 서비스는 운영중인 어플리케이션을 Kubernetes PaaS 환경으로 전환합니다.</span></p>
                            <p className="head-text">• 일반적인 홈페이지부터 쇼핑몰 모바일서비스등 운영중인 서비스에 </p>
                            <p className="head-text-bot">Private Kubernetes 플랫폼을 도입할 수 있습니다.</p>
                        </div>
                    </div>

                    <div className="information-block">
                        <div class="information" data-aos="fade-in" data-aos-delay="400" data-aos-duration="1200">
                            <div class="infor_box">
                                <div class="infor_dt_left">
                                    <div className="banner-logo">
                                        <img src="https://hanbiro.com/images/kubernetes/image1.svg" />
                                    </div>
                                    <div class="infor_dt_text">
                                        Kubernetes<br />플랫폼 도입의 장점
                                    </div>
                                </div>
                                <div class="infor_dt_right">
                                    <div class="infor_text_li" data-aos="fade-in" data-aos-delay="700" data-aos-duration="1200">
                                        <CheckCircle className='icon-check' />
                                        <p className="text_ban"><span>서버확장:</span>서비스운영중 서버를 자유롭게 확장</p>
                                    </div>
                                    <div class="infor_text_li" data-aos="fade-in" data-aos-delay="750" data-aos-duration="1200">
                                        <CheckCircle className='icon-check' />
                                        <p className="text_ban"><span>무정지서비스:</span>재해복구, 서비스의 영속성</p>
                                    </div>
                                    <div class="infor_text_li" data-aos="fade-in" data-aos-delay="800" data-aos-duration="1200">
                                        <CheckCircle className='icon-check' />
                                        <p className="text_ban"><span>Autoscale:</span>서비스 확장 자동화</p>
                                    </div>
                                    <div class="infor_text_li" data-aos="fade-in" data-aos-delay="850" data-aos-duration="1200">
                                        <CheckCircle className='icon-check' />
                                        <p className="text_ban"><span>Cluster:</span>서비스의 로드발랜싱</p>
                                    </div>
                                    <div class="infor_text_li" data-aos="fade-in" data-aos-delay="900" data-aos-duration="1200">
                                        <CheckCircle className='icon-check' />
                                        <p className="text_ban"><span>Application:</span>Microservice 개발플랫폼 확보</p>
                                    </div>
                                    <div class="infor_text_li" data-aos="fade-in" data-aos-delay="950" data-aos-duration="1200">
                                        <CheckCircle className='icon-check' />
                                        <p className="text_ban"><span>서버자원의 효율성 향상</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="information-bg"></div>
                    </div>

                    <div className="block">
                        {/* <div className="section-title">
                            <div class="text-sz32">Hanbiro Kube 프라이빗 쿠버네티스 시스템 구조</div>
                        </div> */}

                        <div className="section-content">
                            <div className="block-text">
                                <div className="box-ct">
                                    <div className="content-box-tt">
                                        <div className="text-sz28">쿠버네티스 <br /> 시스템 개요</div>
                                    </div>
                                    <div className="content-box-dt">
                                        <div class="text-sz18"><b>Hanbiro Kube 시스템 구축</b></div>
                                        <div class="text-sz14 text-pl5">• <b>기반 환경:</b> 오픈스택 또는 KVM 서버를 이용하여 쿠버네티스 시스템을 구축할 수 있습니다.</div>
                                        <div class="text-sz14 text-pl5">• <b>KVM 환경의 노드: </b> KVM 환경에서 VM은 Master 노드 또는 Worker 노드로 운영됩니다.</div>
                                        <div class="space"></div>
                                        <div class="text-sz18"><b>스토리지</b></div>
                                        <div class="text-sz14 text-pl5">• <b>오브젝트 스토리지:</b> S3 프로토콜을 지원하는 MinIO S3 스토리지를 운영합니다.</div>
                                        <div class="text-sz14 text-pl5">• <b>서비스 볼륨:</b> 서비스를 위한 스토리지 볼륨은 고성능의 I/O를 지원하는 블록 스토리지를 사용해야 합니다.</div>
                                        <div class="text-sz14 text-pl10">- <b>기본 볼륨:</b> Hanbiro Kube의 기본 볼륨은 높은 성능의 I/O를 지원하는 Local 볼륨을 사용합니다.</div>
                                        <div class="text-sz14 text-pl10">- <b>스토리지 구성:</b> 볼륨을 위한 스토리지 구성은 고객의 요청에 의해서 구성할 수 있습니다.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                <img src="https://hanbiro.com/images/kubernetes/kube01.png" />
                            </div>
                        </div>
                    </div>

                    <div className="block background pb-0">
                        <div className="light-background">
                            <div className="section-title">
                                <div class="text-sz32">Hanbiro Kube 프라이빗 쿠버네티스 상세 구조</div>
                            </div>

                            <div className="section-tab">
                                <ul class="nav nav-pills" id="pills-tab1" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link text-sz14 fs-tab active" id="pills-kube1-tab" data-bs-toggle="pill" data-bs-target="#pills-kube1" type="button" role="tab" aria-controls="pills-kube1" aria-selected="true">Namespace 상세구조</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link text-sz14 sc-tab" id="pills-kube2-tab" data-bs-toggle="pill" data-bs-target="#pills-kube2" type="button" role="tab" aria-controls="pills-kube2" aria-selected="false">모니터링 상세구조</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link text-sz14 th-tab" id="pills-kube3-tab" data-bs-toggle="pill" data-bs-target="#pills-kube3" type="button" role="tab" aria-controls="pills-kube3" aria-selected="false">DB Cluster 상세구조</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent1">
                                    <div class="tab-pane fade show active" id="pills-kube1" role="tabpanel" aria-labelledby="pills-kube1-tab">
                                        <div className="section-content">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">Namespace <br /> 상세구조</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        <div class="text-sz14 text-pl5">• <b>Namespace 관리:</b> Namespace 를 세분화합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>Service Mesh 통합:</b> Namespace 의 Service mesh 를 기본으로 적재할 수 있도록 설정합니다.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image namespace" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube02.png" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="pills-kube2" role="tabpanel" aria-labelledby="pills-kube2-tab">
                                        <div className="section-content">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">모니터링 <br /> 상세구조</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        {/* <div class="text-sz18"><b>Hanbiro Kube 프라이빗 쿠버네티스 시스템 모니터링 및 장애 처리</b></div> */}
                                                        <div class="text-sz14 text-pl5">• <b>실시간 모니터링:</b> Kubernetes 의 pod 상태를 실시간 모니터링하고 신속한 장애 처리가 가능하도록 지원합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>데이터 저장 및 시각화:</b> 모니터링 POD에 의해 수집된 데이터는 한비로 Object Storage에 일정 기간 저장되며, 해당 데이터를 이용해 로그 및 각종 수치를 시각화하여 제공합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>장애 알림 및 처리:</b> POD 상태를 실시간 모니터링하여 장애 발생시 Push, Email 등을 이용하여 엔지니어에게 전달하여 빠른 장애 처리를 수행할 수 있게 합니다.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube06.png" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="pills-kube3" role="tabpanel" aria-labelledby="pills-kube3-tab">
                                        <div className="section-content">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">DB Cluster <br /> 상세구조</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        {/* <div class="text-sz18"><b>Hanbiro Kube 프라이빗 쿠버네티스 시스템</b></div> */}
                                                        <div class="text-sz14 text-pl5">• <b>DB 클러스터 관리 자동화:</b> Operator를 이용하여 DB Cluster의 관리를 자동화합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>자동 스케일링:</b> 서비스 부하에 따른 Autoscaling Up/Down 기능을 제공하여 이벤트 등에 의한 과부하 장애로부터 안전합니다.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube07.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block background">
                        <div className="light-background">
                            <div className="section-title">
                                <div class="text-sz32">Hanbiro Kube 프라이빗 쿠버네티스 클러스터 종류</div>
                            </div>

                            <div className="section-tab">
                                <ul class="nav nav-pills" id="pills-tab2" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link text-sz14 fs-tab active" id="pills-kube4-tab" data-bs-toggle="pill" data-bs-target="#pills-kube4" type="button" role="tab" aria-controls="pills-kube4" aria-selected="true">소호형 클러스터</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link text-sz14 sc-tab" id="pills-kube5-tab" data-bs-toggle="pill" data-bs-target="#pills-kube5" type="button" role="tab" aria-controls="pills-kube5" aria-selected="false">확장형 클러스터</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link text-sz14 th-tab" id="pills-kube6-tab" data-bs-toggle="pill" data-bs-target="#pills-kube6" type="button" role="tab" aria-controls="pills-kube6" aria-selected="false">엔터프라이즈형 클러스터</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent2">
                                    <div class="tab-pane fade show active" id="pills-kube4" role="tabpanel" aria-labelledby="pills-kube4-tab">
                                        <div className="section-content">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">소호형 <br /> 클러스터</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        <div class="text-sz18"><b>Hanbiro Kube 프라이빗 쿠버네티스 소호형 클러스터</b></div>
                                                        <div class="text-sz14 text-pl5">• <b>VM을 Master / Worker Node 로 운영:</b> 가상 머신(VM)을 마스터/워커 노드로 운영합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>가상화 서버:</b> VMWARE, Proxmox VE VM 가상화 서버를 사용합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>최소 구성:</b> 최소 1대부터 프라이빗 쿠버네티스 클러스터를 구성할 수 있습니다.</div>
                                                        <div class="space"></div>
                                                        <div class="text-sz18"><b>소호형 클러스터 특징</b></div>
                                                        <div class="text-sz14 text-pl5">• <b>Proxmox VE 2대 활용:</b> Proxmox VE 2대를 활용한 소호형 클러스터</div>
                                                        <div class="text-sz14 text-pl5">• <b>최적화 대상:</b> 소규모 팀 및 스타트업에 최적화된 경량형 솔루션</div>
                                                        <div class="text-sz14 text-pl5">• <b>적합한 용도:</b> 소규모 어플리케이션 운영에 적합</div>
                                                        <div class="text-sz14 text-pl5">• <b>장점:</b> 초기 비용 절감과 빠른 배포</div>
                                                        <div class="text-sz14 text-pl5">• <b>사용 환경:</b> 테스트 및 개발 환경</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube03.png" />
                                            </div>
                                        </div>

                                        {/* <div className="section-content">
                                        <div className="block-text">
                                            <div className="box-ct">
                                                <div className="content-box-tt">
                                                    <div className="text-sz28">소호형 <br /> 클러스터</div>
                                                </div>
                                                <div className="content-box-dt">
                                                    <div class="text-sz18"><b>소호형 클러스터 특징</b></div>
                                                    <div class="text-sz14 text-pl5">• <b>Proxmox VE 2대 활용:</b> Proxmox VE 2대를 활용한 소호형 클러스터</div>
                                                    <div class="text-sz14 text-pl5">• <b>최적화 대상:</b> 소규모 팀 및 스타트업에 최적화된 경량형 솔루션</div>
                                                    <div class="text-sz14 text-pl5">• <b>적합한 용도:</b> 소규모 어플리케이션 운영에 적합</div>
                                                    <div class="text-sz14 text-pl5">• <b>장점:</b> 초기 비용 절감과 빠른 배포</div>
                                                    <div class="text-sz14 text-pl5">• <b>사용 환경:</b> 테스트 및 개발 환경</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                            <img src="https://hanbiro.com/images/kubernetes/kube032.png" />
                                        </div>
                                    </div> */}
                                    </div>

                                    <div class="tab-pane fade" id="pills-kube5" role="tabpanel" aria-labelledby="pills-kube5-tab">
                                        <div className="section-content mb-5">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">확장형 <br /> 클러스터</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        {/* <div class="text-sz18"><b>Hanbiro Kube 확장형 클러스터</b></div> */}
                                                        <div class="text-sz14 text-pl5">• <b>기본 구성:</b> BareMetal을 Master / Worker Node로 운영하며, Object Storage(S3) 구성은 Proxmox VE VM으로 운영합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>최소 구성:</b> 프라이빗 쿠버네티스 클러스터는 최소 3대부터 구성 가능하며, <b>**Object Storage(S3)**</b>는 Proxmox VE 1대를 이용하여 최소 구성이 가능합니다.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube041.png" />
                                            </div>
                                        </div>

                                        <div className="section-content">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">확장형 <br /> 클러스터</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        <div class="text-sz18"><b>확장형 클러스터 특징</b></div>
                                                        <div class="text-sz14 text-pl5">• <b>아키텍처:</b> BareMetal과 Proxmox VE를 활용한 확장 가능한 아키텍처</div>
                                                        <div class="text-sz14 text-pl5">• <b>워크로드 처리:</b> 중규모 이상의 워크로드를 처리 가능한 솔루션</div>
                                                        <div class="text-sz14 text-pl5">• 적합한 용도</div>
                                                        <div class="text-sz14 text-pl5">• 중규모 어플리케이션 운영에 적합</div>
                                                        <div class="text-sz14 text-pl10">• 마이크로서비스 환경 운영</div>
                                                        <div class="text-sz14 text-pl10">• 데이터 처리 및 분석 워크로드</div>
                                                        <div class="text-sz14 text-pl5">• <b>성능 및 특징:</b> 안정적인 성능, 유연성 및 확장성</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube02.png" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="pills-kube6" role="tabpanel" aria-labelledby="pills-kube6-tab">
                                        <div className="section-content mb-5">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">엔터프라이즈형<br />클러스터</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        <div class="text-sz14 text-pl5">• <b>구성:</b> BareMetal을 이용하여 Master / Worker Node 및 Block Storage, Object Storage를 운영합니다.</div>
                                                        <div class="text-sz14 text-pl5">• <b>최소 구성:</b> 프라이빗 쿠버네티스 클러스터 구성은 최소 5대부터 가능하며, Block Storage는 5대, <b>**Object Storage(S3)**</b>는 4대를 이용합니다.</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube051.png" />
                                            </div>
                                        </div>

                                        <div className="section-content">
                                            <div className="block-text">
                                                <div className="box-ct">
                                                    <div className="content-box-tt">
                                                        <div className="text-sz28">엔터프라이즈형<br />클러스터</div>
                                                    </div>
                                                    <div className="content-box-dt">
                                                        <div class="text-sz14 text-pl5">• <b>인프라:</b> BareMetal 서버, Ceph 블록 스토리지, MinIO 오브젝트 스토리지로 구성된 대규모 환경</div>
                                                        <div class="text-sz14 text-pl5">• <b>장점:</b> 대규모 환경 고가용성 및 안정성을 보장하는 분산 스토리지 아키텍처</div>
                                                        <div class="text-sz14 text-pl5">• <b>적합 대상:</b> 엔터프라이즈급 애플리케이션 및 데이터 중심 서비스에 적합</div>
                                                        <div class="text-sz14 text-pl5">• <b>인프라 특징:</b> 고성능 분산 스토리지 기반 인프라</div>
                                                        <div class="text-sz14 text-pl5">• <b>활용 분야:</b> 클라우드 네이티브 애플리케이션 운영, 대규모 데이터 처리 및 머신러닝 워크로드, 대규모의 마이크로서비스 기반 서비스를 제공하는 기업</div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                                <img src="https://hanbiro.com/images/kubernetes/kube052.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="block dark-bg">
                        <div className="light-background">
                            <div className="section-title">
                                <div class="text-sz32">Hanbiro Kube 영구 볼륨 운영</div>
                            </div>

                            <div className="section-content">
                                <div className="block-text">
                                    <div className="box-ct">
                                        <div className="content-box-tt">
                                            <div className="text-sz28">Persistant Volume <br /> 운영방안</div>
                                        </div>
                                        <div className="content-box-dt">
                                            <div class="text-sz14 text-pl5">• <b>기본 볼륨:</b> Local 볼륨을 사용합니다.</div>
                                            <div class="text-sz14 text-pl5">• <b>추가 볼륨 옵션:</b> openEBS의 Hostpath 또는 cStor 볼륨을 사용하여, 무결성의 데이터 복제와 고성능의 I/O를 구현합니다.</div>
                                            <div class="text-sz14 text-pl5">• <b>클러스터 확장 스토리지:</b> 클러스터 확장을 위한 스토리지는 Ceph 블록 스토리지를 지원합니다.</div>

                                        </div>
                                    </div>
                                </div>

                                <div className="block-image" data-aos="zoom-in" data-aos-duration="1500">
                                    <img src="https://hanbiro.com/images/kubernetes/kube08.png" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <Footer />

                </div>
            </div>


        </div>
    );
}

export default Kubernetes;