import React from 'react';
import styled from 'styled-components';
import NavButton from '../../homepage/header-banner/TopNavButton';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
//import './sidebar.css';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute";
import CardWrap from '../components/CardWrapper';
import config from '../components/config';
import Sidebar from '../../container/Sidebar/Sidebar';
import '../../Style/defaultstyle.css';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function LongRunning() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Long-Running")}

                        <BoxContent>
                            <BoxText >
                                클라이언트 어플리케이션에서 API Method가 완료되는데 시간이 걸린다면 서버에서 백그라운드로 실행시켜 놓고,<br></br>
                                클라이언트 어플리케이션은 다음 화면이나 다음 코드 실행으로 전환되게 하며, <br></br>
                                백그라운드 작업실행의 완료 상태를 반환하도록 설계를 할 수 있음.<br></br><br></br>
                                Long-running을 사용하여 Operation(완료/에러)을 비동기적으로 실시간 응답 받음
                            </BoxText>
                        </BoxContent>
                        {CardWrap(
                            `Long-running은 응답(Asynchronous Request-Reply)패턴 - 비동기적 백그라운드 실행프로세스.`, config.tb7_header, config.tb7_content)}
                        {CardWrap("• Long-running 작동흐름", "", "", "https://hanbiro.com/cloud/library2/long-running/long-running-1.svg")}

                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`

export default LongRunning;