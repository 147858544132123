import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CommonTable from '../../container/Table/Components/CommonTable';


// Titlecloudcompute
function Computevps() {
    return (
        <div className='App'>
            <NavButton />
            {/* {Banner("HANBIRO CLOUD", "Compute 가상서버(인스턴스)")} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("가상서버 (인스턴스)")}
                        {Contentcloudcard("고성능 Local SSD replication Volume 적용 안정적인 서비스운영","모든 인스턴스에 물리적인 rCPU (1배수) 적용, 고용량의 메모리제공")}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>{
                                CardBoxRow(
                                "고성능의 이중화 로컬볼륨 적용",
                                `가상서버(인스턴스) 생성시에 로컬볼륨을 집적할 수 있도록 구성했으며, <br>
                                네트워크 블록스토리지보다 수배이상의 고속 I/O 을 구현했습니다. <br><br>
                                
                                고성능의 이중화 로컬볼륨을 적용했으며, 시스템장애시 이중화볼륨으로 서비스의 영속성을 유지합니다.`
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>{
                                CardBoxRow(
                                "물리적인 rCPU (1배수) 적용",
                                `Cloud 시스템은 가상서버(인스턴스)의 CPU 자원을 배분할때 물리적인 CPU 코어수의 4배수 이상으로 구성합니다. <br>
                                만약, Cloud 서비스 공급자가 4개의 CPU 코어를 가진 컴퓨트노드의 CPU 자원을 고객에게 제공할 경우 <br>
                                CPU 4코어수의 4배수이상 즉 16개이상의 vCPU 를 고객에게 배분하는 방식입니다. <br><br>

                                한비로 Cloud 는 고객이 안정적인 서비스를 운영할 수 있도록 <br>
                                컴퓨트노드의 CPU 코어의 물리적인 갯수만큼만 배분<font color=red>(1배수)</font>하는 시스템을 운영합니다.`
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>{CardBoxRow(
                                "쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축",
                                `한비로는 쿠버네티스와의 통합 Cloud 플랫폼을 운형하고 있습니다. <br>
                                가상서버(인스턴스)는 한비로 쿠버네티스 서비스 및 마이크로서비스와 통합 운영할 수 있습니다.<br>
                                자동으로 서버시스템을 확장하여, 대량트래픽 발생으로 인한 서비스장애를 대처할 수 있습니다.`
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12}>{CardBoxRow(
                                "Cloud 관리자 콘솔에서 수분내에 서버생성 및 서비스개시", 
                                `시간단위로 사용요금을 측정하며, 사용한만큼의 비용을 지불할 수 있습니다. <br>
                                관리자 콘솔에서 한번의 클릭으로 수분내에 선택한 운영체재의 서버가 생성됩니다.`)}</Grid>
                            <Grid item xs={12}>{Imagecloudcompute("https://www.hanbiro.com/cloud/library/compute-vps/vps.svg")}</Grid>
                            {/*
                            <Grid item xs={12} sm={6}>{CardBox("서버의 스토리지용량의 자유로운 확장과 서비스 시스템 구성의 간편함과 동시성 구현","관리콘솔에서 생성된 가상서버에 블록 Storage 의 볼륨을 연결해서 서버의 용량을 확장할 수 있으며, S3, Restful API 연결을 통해서 데이터를 오브젝트 Storage 직접 저장할 수 있습니다. 오브젝트 Storage 는 표준 Amazone S3와 호환되며, 기존의 S3 연동기능을 사용할 수 있습니다.")}</Grid>
                            <Grid item xs={12} sm={6}>{CardBox("서버복제 및 오토스케일링 기능제공, 클러스터시스템 구축및 이벤트서버 구축","가상서버(인스턴스)의 복제 및 자동 증감를 지원하는 오토스케일링 기능을 운영하며, 오픈스택의 Load Balancer를 통한 클러스터 시스템을 구현할 수 있습니다.")}</Grid>
                            <Grid item xs={12} sm={6}>{CardBox("리눅스 및 윈도우 서버 외 다양한 어플리케이션 서버이미지 제공","대부분의 리눅스운영체제 서버이미지를 사용해서 관리자콘솔에서 서버생성과 동시에 운영할 수 있습니다. 윈도우 서버운영체제도 다양한 버전의 운영체제를 지원하며, 빈번히 사용되는 WAS서버, GATEWAY서버, WordPress서버등의 이미지도 제공합니다.")}</Grid>
                            */}
                        </Grid>
                       
                        <ServerPricing title={"가상서버(인스턴스) 요금안내"} 
                        content={"한비로 Cloud 콘솔에서 다양한 대용량 클라우드 가상서버(인스턴스) 상품을 확인할 수 있습니다."} content1={" "} />
                        
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer />
        </div>
    );
};

export default Computevps;