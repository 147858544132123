import * as React from 'react';
import styled from 'styled-components';
import '../../container/Box/Components/Box-Style.css'

import CommonTable from '../../container/Table/Components/CommonTable'

function CardWrap($title, $header, $content, $imagecloud) {

  return (

    <BoxContainer sx={CardWrapper}>
      <BoxTitle sx={TitleWrap} className='sub-title'>{$title}</BoxTitle>
      <CommonTable header={$header} content={$content} />
      <Image src={$imagecloud}></Image>
    </BoxContainer>

  );
};

const CardWrapper = {
  padding: "20px",
  border: "#ebebeb solid 1px",
  background: "#FFFFFF",
  boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
  borderRadius: "4px",
}

const TitleWrap = {
  height: "70px",
}

const BoxTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
`

const BoxContainer = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    // margin-top: 30px;
`

const Image = styled.img`
  width: 100%;
`

export default CardWrap;