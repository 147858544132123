
import React from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import '../../../Style/defaultstyle.css';
import '../../../Style/defaultstyle.scss'
// import { useTranslation } from "react-i18next";

function CommonTable({ header, content }) {

  // const { t } = useTranslation();
  const twoCenter = ["50%", "50%"]
  const twoCols   = ["30%", "70%"]
  const threeCols = ["30%", "40%", "30%"]
  const fourCols  = ["25%", "25%", "25%", "25%"]

  function TotalHeader(header) {
    const column = Object.values(header)[0].length;
    return column
  }

  function AddCols(totalCols, tableWidth, header) {
    for (let i = 0; i < totalCols; i++) {
      header[i]["width"] = tableWidth[i]
    }
  }

  let listHeader = [];
  Object.keys(header).forEach(function (rowKey) {
    if (header[rowKey][0]["title"] === "") {
      //Table has no header
    } else {
      const totalCols = TotalHeader(header)
      if (totalCols === 4) {
        AddCols(totalCols, fourCols, header[rowKey])
      }
      if (totalCols === 3) {
        AddCols(totalCols, threeCols, header[rowKey])
      }
      if (totalCols === 2) {
        if (header[rowKey][0]["Cols50"] === "True") {
          AddCols(totalCols, twoCenter, header[rowKey])
        } else {
          AddCols(totalCols, twoCols, header[rowKey])
        }

      }

      header[rowKey][0]["style"] = Style.BorLeft
      header[rowKey][totalCols - 1]["style"] = Style.BorRight

      let columns = header[rowKey].map((item) =>
        <TableCell width={item.width} style={item.style}>
          <BoxHeader>
            <BoxText dangerouslySetInnerHTML ={{ __html: item.title }} />
            <BoxText>{item.icon}</BoxText>
            {/* <BoxText dangerouslySetInnerHTML ={{ __html: item.title }}>{item.icon}</BoxText> */}

            
          </BoxHeader>
        </TableCell >
      );
      listHeader.push(<TableRow sx={Header}>{columns}</TableRow>);
    }

  });

  let listRow2 = [];
  Object.keys(content).forEach(function (rowKey) {
    const totalHeader = TotalHeader(header);
    const TotalContent = content[rowKey].length;
    if (totalHeader === TotalContent) {
      if (content[rowKey][0]["bold"] === "False"){
      }else{
        content[rowKey][0]["style"] = Style.TextCard
      }
    }
    let columns = content[rowKey].map((item) =>
      <TableCell style={Style.BorAll} rowSpan={item.rowSpan}>
        <BoxContent >
          <BoxText dangerouslySetInnerHTML={{ __html: item.title }} style={item.style}></BoxText>
          <BoxIcon>{item.icon}</BoxIcon>
        </BoxContent>
      </TableCell>
    );
    listRow2.push(<TableRow>{columns}</TableRow>);
  });


  return (
    <>
      <TableScroll>
        <TableWrap>
          <Table width="100%">
            <TableBody >
              {listHeader}
              {listRow2}
            </TableBody>
          </Table>
        </TableWrap>
      </TableScroll>
    </>
  );
}

const TableScroll = styled.div`
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
`
const TableCell = styled.td`
    
`
const TableWrap = styled.div`
    width: 100%;
    overflow: auto;
`

const BoxText = styled.div`
`
const BoxIcon = styled.div`
    text-align: center; 
`
const BoxContent = styled.div`
    text-align: left;
    padding-left: 10px;
`
const BoxHeader = styled.div`
    font-weight: bold;
    color: white;
    text-align: center;
`
const Header = {
  background: "#393939",
}

const Content = {
  color: "#091e42",
}

const Style = {
  BorLeft: {
    borderTopLeftRadius: "8px",
    borderRight: "2px solid #F4F4F4",
    paddingTop: 8,
    paddingBottom: 8,
  },
  BorRight: {
    borderTopRightRadius: "8px",
    borderLeft: "2px solid #F4F4F4",
  },
  BorAll: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    borderTop: "2px solid #DDDDDD",
    paddingTop: 8,
    paddingBottom: 8,
  },
  TextCard: {
    color: "#21253d",
    fontWeight: "bold"

  }
}


export default CommonTable;
