import * as React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import '../components/style.css';
import '../../Style/defaultstyle.css';
import '../../container/Box/Components/Box-Style.css'
import '../../homepage/cloud-services/App.css'
import {
    Container, Col,
  } from 'react-bootstrap';
import {
    Card, CardBody,
  } from '@/shared/components/Card';



function ImageCompany ($imagecloud) {
    return (
        <GridCardBody className='imagecard'>
            <CardImage src={$imagecloud}></CardImage>
        </GridCardBody>
    )
}

function ImgCompany() {
  return (
    <div className='box-image-company'>
        <BoxTitle className='title_outsourcing_font'><span>Hanbiro Vietnam R&D</span><br className='sizetitle' /><span>센터 근무환경</span> </BoxTitle>
        <Container className='background-top'>
            <Container className='img-company background-bottom'>
                <Box mr={1} className='ImageBox stylebox'>
                    <Col>
                        <GridCard>
                            {ImageCompany("https://hanbiro.com/cloud/library/outsourcing/Company 7.png")}
                        </GridCard>
                    </Col>
                    <Col>
                        <GridCard>
                            {ImageCompany("https://hanbiro.com/cloud/library/outsourcing/Company 4.svg")}
                        </GridCard>
                    </Col>
                </Box>
                <Box mr={1} className='ImageBox stylebox'>
                    <Col>
                        <GridCard>
                            {ImageCompany("https://hanbiro.com/cloud/library/outsourcing/Company 2.svg")}
                        </GridCard>
                    </Col>
                    <Col>
                        <GridCard>
                            {ImageCompany("https://hanbiro.com/cloud/library/outsourcing/Company 8.png")}
                        </GridCard>
                    </Col>
                </Box>
                <Box mr={1} className='ImageBox stylebox'>
                    <Col>
                        <GridCard>
                            {ImageCompany("https://hanbiro.com/cloud/library/outsourcing/Company 3.svg")}
                        </GridCard>
                    </Col>
                    <Col>
                        <GridCard>
                            {ImageCompany("https://hanbiro.com/cloud/library/outsourcing/Company 6.svg")}
                        </GridCard>
                    </Col>
                </Box>
            </Container>
        </Container>
        
        
    </div>
    
    
  );
};

export default ImgCompany;

const BoxTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-top: 50px;
  margin-bottom: 50px;
`

const GridCard = styled(Card)`
  padding-bottom: 10px;

  p {
    margin-bottom: 0;
  }
`;

const GridCardBody = styled(CardBody)`
  padding: 0;
`;

const CardImage = styled(Card.Img)`
    text-align: center;
    height: 197px !important;
    border: 2px solid black;
    border-radius: 32px;
    object-fit: cover;
`;
