import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CommonTable from '../../container/Table/Components/CommonTable';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';


// Titlecloudcompute
function Scalable() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {/* {Titlecloudcompute("확장형")} */}
                        {/* {Contentcloudcard("고성능 Local SSD replication Volume 적용 안정적인 서비스운영","모든 인스턴스에 물리적인 rCPU (1배수) 적용, 고용량의 메모리제공")} */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className='grid-card'>{CardBoxRow(
                                "Private Kubernetes 확장형 클러스터",

                                `
                                <p style="margin:0;">Private Kubernetes 확장형 클러스터는 BareMetal 을 Master / Worker Node 로 운영하며, Object Storage(S3) 구성은 Proxmox VE VM으로 운영합니다.</p>
                                <p style="margin:0;">프라이빗 쿠버네티스 클러스터 구성은 최소 3대부터 구성 가능합며, Object Storage(S3)는 Proxmox VE 1대를 이용하여 최소 구성이 가능합니다.</p>
                                
                                <p style="margin:0;">&nbsp;</p>
                                <ul>
                                    <li>BareMetal과 Proxmox VE 를 활용한 확장형 클러스터</li>
                                    <li>확장 가능한 아키텍처 중규모 이상의 워크로드를 처리가능한 솔루션</li>
                                    <li>중규모 어플리케이션 운영에 적합</li>
                                    <li>안정적인 성능 유연성과 확장성</li>
                                    <li>마이크로서비스 환경 운영 데이터 처리 및 분석 워크로드</li>
                                </ul>
                                `)}
                            </Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/kube-scalable.png")}</Grid>
                        </Grid>
                        <CloudConsultCp />
                    </Col>
                </Row>
            </Container>
            {/* <Cloudconsult /> */}
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

export default Scalable;