import React from 'react';
import styled from 'styled-components';
import { useTranslation  } from "react-i18next";


function TableTwoColumns() {
  const {t} = useTranslation ();
  return (
    <Box >
      <div>
          <div style={StySpecification.ListProduct}>
            <table width="100%">
              <tbody >
                <tr style={StySpecification.Header}>
                  <td  width="30%" style={StySpecification.BorLeft}><div style={StySpecification.TextTitle}>{t('TableTwoColumns.row_1.column_1')}</div></td>
                  <td  width="70%" style={StySpecification.BorRight}><div style={StySpecification.TextTitle}>{t('TableTwoColumns.row_1.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b> {t('TableTwoColumns.row_2.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_2.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b>{t('TableTwoColumns.row_3.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_3.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b>{t('TableTwoColumns.row_4.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_4.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b>{t('TableTwoColumns.row_5.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_5.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b>{t('TableTwoColumns.row_6.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_6.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b>{t('TableTwoColumns.row_7.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_7.column_2')}</div></td>
                </tr>
                <tr>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}><b>{t('TableTwoColumns.row_8.column_1')}</b></div></td>
                  <td style={StySpecification.BorAll}><div style={StySpecification.Text}>{t('TableTwoColumns.row_8.column_2')}</div></td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </Box>


  );
}

const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginBottom:30,
    marginTop: 30,
  },
  ListProduct:{
    paddingBottom: 20,
  },
  TextTitle:{
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    fontWeight: "bold",
  },
  Text: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  Header:{
    color: "white",
    background: "#393939",
  },
  BorLeft:{
    borderTopLeftRadius: "8px",
    borderRight:"2px solid #F4F4F4",
  },
  BorRight:{
    borderTopRightRadius: "8px",
    borderLeft:"2px solid #F4F4F4",
  },
  BorAll:{
    borderLeft:"2px solid #DDDDDD",
    borderRight:"2px solid #DDDDDD",
    borderBottom:"2px solid #DDDDDD",
    paddingTop: 8,
    paddingBottom: 8,
  },
  KindLeft:{
    textAlign: "left",
  },
  TextLeft:{
    borderLeft:"2px solid #DDDDDD",
    borderRight:"2px solid #DDDDDD",
    borderBottom:"2px solid #DDDDDD",
    fontWeight: 600,
    textAlign: "left",
    paddingLeft:"10px"
  },
  TextRight:{
    marginTop: "5px",
    textAlign: "right",
  }
}

const Box = styled.div`
  @media only screen and (max-width: 767px){
    font-size: 80%;
  }
`

export default TableTwoColumns;


