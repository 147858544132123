import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import styled from 'styled-components';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import { Typography, Box } from '@mui/material';
import './sidebar.css'
import '../../Style/defaultstyle.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Sidebar from '../../container/Sidebar/Sidebar';
import '../../Style/defaultstyle.scss'
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';


// Titlecloudcompute
function EventSourcing() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Event Sourcing")}

                        <BoxContent>
                            <BoxText>• CQRS 를 구현하는 서비스형 Serverless 서비스</BoxText>
                            <BoxText>• Application 의 모든 상태를 일으키는 이벤트를 순서대로 저장 - 기존방식은 마지막 상태값만 저장</BoxText>
                            <ImageWrap src="https://www.hanbiro.com/cloud/library2/event-sourcing/event-sourcing-1.svg"></ImageWrap>
                        </BoxContent>

                        <BoxContent>
                            <Box sx={TitleWrap}><Typography className="sub-title normal" sx={CardTitle} variant="h6">• 특징</Typography></Box>
                            <BoxText>• 마이크로서비스별 독립적인 확장과 유지보수, 빠르게 빌드 배포가능</BoxText>
                            <BoxText>• 각각의 오류는 해당기능에만 발생하며 빠르게 수정, 정상화 가능</BoxText>
                            <BoxText>• Write 의 비동기 작동으로 처리응답을 기다리지 않고 화면전환</BoxText>
                            <BoxText>• 읽기 쪽의 데이터베이스 구성</BoxText>
                            <BoxSubText>- 쿼리에 최적화된 스키마를 정의하며</BoxSubText>
                            <BoxSubText>- 단순한 쿼리로 최적화된 읽기 구현</BoxSubText>
                            <BoxSubText>- 데이터베이스의 교체, 전체 재구성, 과거상태 재구성 가능</BoxSubText>
                        </BoxContent>

                        {/*}
                        <BoxContent>
                            <Box sx={TitleWrap}><Typography className="titlecard normal" sx={CardTitle} variant="h6">• 가격</Typography></Box>
                            <br/>
                            <CommonTable header={config.tb4_header} content={config.tb4_content} />
                            <br/>
                            <CommonTable header={config.tb5_header} content={config.tb5_content} />
                        </BoxContent>
                        */}
                        <BoxContent>
                            <Box><Typography className="sub-title normal" sx={CardTitle} variant="h6">• AWS 의 CQRS</Typography></Box>
                            {/* {Imagecloudcompute("https://www.hanbiro.com/cloud/library2/event-sourcing/event-sourcing-2.svg")} */}
                            <ImageWrap src="https://www.hanbiro.com/cloud/library2/event-sourcing/event-sourcing-2.svg"></ImageWrap>
                        </BoxContent>

                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxSubText = styled.div`
  margin-left: 7%;
`

const CardTitle = {
    textAlign: "left",
    fontWeight: "bold",
    fontFamily: "맑은고딕, Dotum, 돋움, sans-serif !important;"
}

const TitleWrap = {
    height: "40px",
}


// const Box = styled.div`
//   @media only screen and (max-width: 767px){
//     font-size: 80%;
//   }
// `

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`

const ImageWrap = styled.img`
  width: 100%;
  object-fit: fill;
`

export default EventSourcing;
