import React from 'react';
import styled from 'styled-components';
import '../../Card/Components/Contenttitlecard.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
  Card, CardBody,
} from '@/shared/components/Card';






function Imagecloudcompute($imagecloud) {

  return (
    <>
      <div className='App'>
        <Row>
          <Col sm={12} md={12}>
            <GridCard>
              <GridCardBody3>
                <CardImage className="sizeimage" src={$imagecloud}></CardImage>
              </GridCardBody3>

            </GridCard>
          </Col>
        </Row>

      </div>


    </>
  );
}





const GridCard = styled(Card)`
  padding-bottom: 0;
  
  p {
    margin-bottom: 0;
  }
  
`;






const GridCardBody3 = styled(CardBody)`
    border: solid #ebebeb 1px;
    border-radius: 4px;
    background:  white;
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%) !important;
`;









const CardImage = styled(Card.Img)`
    text-align: center;
    padding-left:1%;
    padding-right:1%;
`;



export default Imagecloudcompute;
