import React, { useEffect } from "react";
import { Route, Switch, BrowserRouter, useLocation, HashRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import App from "../App";
import Clustercloudelasticsearch from "../../detailspage/Page/cluster-cloud-elastic-search";
import Clusterkafka from "../../detailspage/Page/cluster-kafka";
import Clustercloudrabbitmq from "../../detailspage/Page/cluster-cloud-rabbitmq";
import Clustercloudmysqldb from "../../detailspage/Page/cluster-cloud-mysql-db";
import Clustercloudpostgresqldb from "../../detailspage/Page/cluster-cloud-postgresql-db";
import Clustercloudvitess from "../../detailspage/Page/cluster-cloud-vitess";
import Clustercloudmongodb from "../../detailspage/Page/cluster-cloud-mongodb";
import Clustercloudredis from "../../detailspage/Page/cluster-cloud-redis";
import Clustercloudscylladb from "../../detailspage/Page/cluster-cloud-scylladb";
import Clusterclickhouse from "../../detailspage/Page/cluster-clickhouse";
import Storeblockstore from "../../detailspage/Page/store-block-store";
import Storenasstore from "../../detailspage/Page/store-nas-store";
import Storeobjectstore from "../../detailspage/Page/store-object-store";
import Computeautoscale from "../../detailspage/Page/compute-auto-scale";
import Computebaremetal from "../../detailspage/Page/compute-bare-metal";
import Computedatabase from "../../detailspage/Page/compute-database";
import Computevps from "../../detailspage/Page/compute-vps";
import Computems from "../../detailspage/Page/compute-ms";
import Computeloadbalance from "../../detailspage/Page/compute-load-balance";
import MSA1 from "../../microservice/container/msa-1";
import MSA2 from "../../microservice/container/msa-2";
import data from '../../../package.json'
import MSA from "../../microservice/demo/microservice/container/msa-1";
import WebHook from "../../microservice/container/webhook";
import Iaam from "../../microservice/container/iaam";
import LongRunning from "../../microservice/container/long-running";
import SimpleStorage from "../../microservice/container/simple-storage";
import SimpleUrl from "../../microservice/container/simple-url";
import EventSourcing from "../../microservice/container/event-sourcing";
import MSA3 from "../../microservice/container/msa3";
import OutSourcing from "../../outsourcing/container/outsourcing";
import MessagingService from "../../microservice/container/messaging-service";
import ClusterClickHouseTest from "../../detailspage/Page/cluster-clickhouse-test";
import KubernetesDemo from "../../kubernetes/kubernetes-demo";
import Kubernetes from "../../kubernetes/kubernetes";
import IndexKo from "../../hanbiropage/index-ko";
import IndexVi from "../../hanbiropage/index-vi";
import HelpDesk from "../../hanbiropage/helpdesk/help-desk";
import CrmCall from "../../hanbiropage/crmcall/crm-call";
import IndexDemo from "../../hanbiropage/index-demo";
import IndexPage from "../../hanbiropage/index-page";
import IndexPage1 from "../../hanbiropage/index-page1";
import IndexPage2 from "../../hanbiropage/index-page2";
import MonitoringVolume from "../../detailspage/Page/kube-monitoring-volume";
import BackOffice from "../../detailspage/Page/kube-back-office";
import Soho from "../../detailspage/Page/kube-soho";
import Scalable from "../../detailspage/Page/kube-scalable";
import Enterprise from "../../detailspage/Page/kube-enterprise";
import KubeBenefits from "../../detailspage/Page/kube-benefits";


const RouteQaCloud = () => {
  const location = useLocation();

  useEffect(() => {
    AOS.init(
      //   {
      //   duration: 1000,
      //   offset: 120,
      //   once: true,
      // }
    );
    AOS.refresh();
  }, [location]);

  return (

    <HashRouter>
      <Switch>
        <Route exact path="/" component={IndexPage2} />
        {/* <Route exact path="/cloud" component={App} /> */}
        <Route exact path={data.homepage} component={IndexPage2} />
        <Route exact path='/cloud-service' component={App} />
        <Route exact path="/cloud/msa1" component={MSA1} />
        {/* Cloud */}
        <Route exact path="/cluster-cloud-elastic-search" component={Clustercloudelasticsearch} />
        <Route exact path="/cluster-kafka" component={Clusterkafka} />
        <Route exact path="/cluster-cloud-rabbitmq" component={Clustercloudrabbitmq} />
        <Route exact path="/cluster-cloud-mysql-db" component={Clustercloudmysqldb} />
        <Route exact path="/cluster-cloud-postgresql-db" component={Clustercloudpostgresqldb} />
        <Route exact path="/cluster-cloud-vitess" component={Clustercloudvitess} />
        <Route exact path="/cluster-cloud-mongodb" component={Clustercloudmongodb} />
        <Route exact path="/cluster-cloud-redis" component={Clustercloudredis} />
        <Route exact path="/cluster-cloud-scylladb" component={Clustercloudscylladb} />
        <Route exact path="/cluster-clickhouse" component={Clusterclickhouse} />
        {/* ----------- */}
        {/* Storage */}
        <Route exact path="/store-object-store" component={Storeobjectstore} />
        <Route exact path="/store-block-store" component={Storeblockstore} />
        <Route exact path="/store-nas-store" component={Storenasstore} />
        {/* ----------- */}
        {/* Compute */}
        <Route exact path="/compute-vps" component={Computevps} />
        <Route exact path="/compute-bare-metal" component={Computebaremetal} />
        <Route exact path="/compute-auto-scale" component={Computeautoscale} />
        <Route exact path="/compute-load-balance" component={Computeloadbalance} />
        <Route exact path="/compute-ms" component={Computems} />
        <Route exact path="/compute-database" component={Computedatabase} />
        {/* ----------- */}
        {/* Microservice */}
        <Route exact path="/msa" component={MSA1} />
        <Route exact path="/msa1" component={MSA1} />
        <Route exact path="/msa2" component={MSA2} />
        <Route exact path="/msa3" component={MSA3} />
        <Route exact path="/msa3/iaam" component={Iaam} />
        <Route exact path="/msa3/event-sourcing" component={EventSourcing} />
        <Route exact path="/msa3/webhook" component={WebHook} />
        <Route exact path="/msa3/long-running" component={LongRunning} />
        <Route exact path="/msa3/simple-url-shortener" component={SimpleUrl} />
        <Route exact path="/msa3/simple-storage-service" component={SimpleStorage} />
        <Route exact path="/msa3/messaging-service" component={MessagingService} />

        {/* Private Kubernetes */}
        <Route exact path="/monitor" component={MonitoringVolume} />
        <Route exact path="/monitor/monitoring-volume" component={MonitoringVolume} />
        <Route exact path="/back-office" component={BackOffice} />
        <Route exact path="/soho" component={Soho} />
        <Route exact path="/soho/soho1" component={Soho} />
        <Route exact path="/soho/scalable" component={Scalable} />
        <Route exact path="/soho/enterprise" component={Enterprise} />
        <Route exact path="/private-cloud" component={KubeBenefits} />

        {/* Demo Language */}
        <Route path="/msa4" component={MSA} />
        <Route path="/outsourcing" component={OutSourcing} />
        <Route path="/demo" component={ClusterClickHouseTest} />
        <Route path="/kubernetes" component={Kubernetes} />
        <Route path="/kubernetes-demo" component={KubernetesDemo} />
        <Route path="/index-ko" component={IndexKo} />
        <Route path="/index-vi" component={IndexVi} />
        <Route path="/helpdesk" component={HelpDesk} />
        <Route path="/crmcall" component={CrmCall} />
        <Route path="/indexdemo" component={IndexDemo} />
        <Route path="/indexpage" component={IndexPage} />
        <Route path="/indexpage1" component={IndexPage1} />
        <Route path="/index" component={IndexPage2} />

      </Switch>
    </HashRouter>

  )

};

export default RouteQaCloud;
