// import React, { useState, createRef } from "react";
// import { useTranslation  } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import common_kor from './Kor/common.json';
import common_en from './En/common.json'


i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: { translation: common_en },
      kor: { translation: common_kor },
    },
    lng: "kor",
    fallbackLng: "kor",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

