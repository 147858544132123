import React from 'react'
import styled from 'styled-components';
import '../container/banner.css'
import '../../Style/defaultstyle.css'
import {
    Container, Row, Col
} from 'react-bootstrap';
import { Box, Grid } from '@mui/material';

function Banner() {
  
    return (
      <div className='main'>
         <Container>
            <Box>
              <Grid container md={10} className='main_contain'>
                  <Grid item xs={12}>
                    <Box className='banner_outsourcing'>
                        <Box className='title_outsourcing_font title_banner_color'><b>IT 인력 아웃소싱 서비스</b></Box>
                        <Box className="textbanner">
                        <b>어플리케이션의 컨설팅-{`>`}개발 지원-{`>`}클라우드 서비스-{`>`}유지보수 까지 한비로와 함께</b>
                        </Box>
                        <Box className="subtitle">
                        <br/>소프트웨어개발 20년 한비로의 노하우가 전수된 베트남 개발인력 보유 <br />
                        베트남 개발 회사 10년으로 우수한 인력을 훈련하여 제공
                      </Box>  
                    </Box>
                  </Grid>
              </Grid>
            </Box>
          </Container>
                {/* <Row className='default_row'>=
                    <Col xl={6} md={6} xs={12} className='left-banner'>
                      <Box className='title_banner'>IT 인력 아웃소싱 서비스</Box>
                      <Box className="textbanner">
                        Mobile / Front-end / Back-end developer 양성 <br></br>
                        Openstack / Kubernetes 기반 개발과 운영인력 확보 <br></br>
                        해외 또는 베트남 R&D 센터 구축
                      </Box>
                    </Col>

                    <Col xl={6} md={6} xs={12} className='right-banner'>
                      <Image className='banner_img' src='https://www.hanbiro.com/cloud/library/outsourcing/Banner_IMG.svg'></Image>
                    </Col>
                </Row> */}

            
        </div>

      
    );
};



const BoxHr = styled.div`
  width: 100%;
  border-top: 1px solid #CACBCF;
`

const Image = styled.img`
  width: 100%;
`



export default Banner;
