import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import TrafficFee from '../../homepage/header-banner/TrafficFee';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
import '../../container/Sidebar/sidebar.css'
import CardButtonWhite from '../../container/CardButton/Components/CardButton-White'
import CardButtonGray from '../../container/CardButton/Components/CardButton-Gray'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import { Grid } from '@mui/material';

function Computedatabase(){

    return (
        <div className='App'>
            <NavButton />
            {/* {Banner("HANBIRO CLOUD","Hanbiro Cloud Database")} */}
            <Container className='main-body'>
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Database 가성서버(인스턴스)")}
                        {Contentcloudcard("고성능 Local SSD replication Volume 적용 안정적인 서비스운영","모든 인스턴스에 물리적인 rCPU (1배수) 적용, 고용량의 메모리제공")}
                        <Grid container spacing={3}>
                            <Grid item xs={12}>{Imagecloudcompute("https://www.hanbiro.com/cloud/library/compute-database/database.svg")}</Grid>
                        </Grid>
                        
                        {CardButtonWhite("MySQL (Maria) Database","고객별 서버자원의 독립성보장, 안정적인 서비스운영","물리적인 CPU 코어를 할당하는 가상서버(인스턴스) 제공","모든 서비스 고용량 SSD 디스크 제공, 고용량의 메모리 할당","쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축","서버복제 및 오토스케일링 기능제공, 클러스터시스템 구축및 이벤트서버 구축","https://hanbiro.com/cloud/library/compute-database//1.svg","MySQL (Maria) Database 요금안내","가상서버(인스턴스)요금만 부과되며, 별도의 추가요금이 발생하지 않습니다.","https://hanbiro.com/cloud/library/compute-database//2.svg","/cluster-cloud-mysql-db","Kubernetes MySQL 클러스터 확인하기")}
                        {CardButtonGray("PostgreSQL Database","고객별 서버자원의 독립성보장, 안정적인 서비스운영","물리적인 CPU 코어를 할당하는 가상서버(인스턴스) 제공","모든 서비스 고용량 SSD 디스크 제공, 고용량의 메모리 할당","쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축","서버복제 및 오토스케일링 기능제공, 클러스터시스템 구축및 이벤트서버 구축","https://hanbiro.com/cloud/library/compute-database//3.svg","PostgreSQL Database 요금안내","가상서버(인스턴스)요금만 부과되며, 별도의 추가요금이 발생하지 않습니다.","https://hanbiro.com/cloud/library/compute-database//4.svg","/cluster-cloud-postgresql-db","Kubernetes PostgreSQL 클러스터 확인하기")}
                        {CardButtonWhite("Redis","고객별 서버자원의 독립성보장, 안정적인 서비스운영","물리적인 CPU 코어를 할당하는 가상서버(인스턴스) 제공","모든 서비스 고용량 SSD 디스크 제공, 고용량의 메모리 할당","쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축","서버복제 및 오토스케일링 기능제공, 클러스터시스템 구축및 이벤트서버 구축","https://hanbiro.com/cloud/library/compute-database//5.svg","Redis 요금안내","가상서버(인스턴스)요금만 부과되며, 별도의 추가요금이 발생하지 않습니다.","https://hanbiro.com/cloud/library/compute-database//6.svg","/cluster-cloud-redis","Kubernetes Redis 클러스터 확인하기")}
                        {CardButtonGray("MongoDB","고객별 서버자원의 독립성보장, 안정적인 서비스운영","물리적인 CPU 코어를 할당하는 가상서버(인스턴스) 제공","모든 서비스 고용량 SSD 디스크 제공, 고용량의 메모리 할당","쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축","서버복제 및 오토스케일링 기능제공, 클러스터시스템 구축및 이벤트서버 구축","https://hanbiro.com/cloud/library/compute-database//7.svg","MongoDB 요금안내","가상서버(인스턴스)요금만 부과되며, 별도의 추가요금이 발생하지 않습니다.","https://hanbiro.com/cloud/library/compute-database//8.svg","/cluster-cloud-mongodb","Kubernetes MongoDB 클러스터 확인하기")}
                        {CardButtonWhite("SycllaDB on Cloud Server","고객별 서버자원의 독립성보장, 안정적인 서비스운영","물리적인 CPU 코어를 할당하는 가상서버(인스턴스) 제공","모든 서비스 고용량 SSD 디스크 제공, 고용량의 메모리 할당","쿠버네티스 기반 데이터베이스 및 어플리케이션과 통합 클러스터 플랫폼 구축","서버복제 및 오토스케일링 기능제공, 클러스터시스템 구축및 이벤트서버 구축","https://hanbiro.com/cloud/library/compute-database//9.svg","SycllaDB 데이터베이스 요금안내","가상서버(인스턴스)요금만 부과되며, 별도의 추가요금이 발생하지 않습니다.","https://hanbiro.com/cloud/library/compute-database//10.svg","/cluster-cloud-scylladb","Kubernetes ScyllaDB 클러스터 확인하기")}
                        <TrafficFee />
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer />
        </div>
        
    );

};

export default Computedatabase;