import * as React from 'react';
import Box from '@mui/material/Box';
import '../../Box/Components/Box-Style.css'
import '../../../Style/defaultstyle.scss';
import { margin } from 'polished';

function CardBoxRow($title, $description) {

  return (
    <Box sx={CardWrapper} className='box'>
      <Box sx={TitleWrap} className="sub-title normal" variant="h7" dangerouslySetInnerHTML={{ __html: $title }}></Box>
      <Box sx={TextWrap} className="normal" dangerouslySetInnerHTML={{ __html: $description }}></Box>
    </Box>
  );
};


const CardWrapper = {
  padding: "20px",
  border: "#ebebeb solid 1px",
  background: "#FFFFFF",
  boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
  borderRadius: "4px",
  height: "100%",
}

const TitleWrap = {
  textAlign: "left !important",
  fontWeight: "bold",
}

const TextWrap = {
  // paddingTop: "5px",
  textAlign: "left",
}

export default CardBoxRow;