
import React from 'react';
import './Style.css';
import Container from 'react-bootstrap/Container'
import CloudCheckIcon from 'mdi-react/CloudCheckIcon';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';


function formatPhone($phone) {
    return 'tel:' + $phone
}

function formatMail($mail) {
    return 'mailto:' + $mail
}

const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12" height="12" fill="#7061ff">
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
    </svg>
);


function CloudConsultCp() {
    return (
        <div className='cloud-consult'>
            <div className='cloud-cs-contain'>
                <div className='cloud-ct-block'>
                    <div className='cloud-ct-item left'>
                        <div className='cloud-item-tt'>Private Kubernetes<br />
                        구축•운영 컨설팅</div>
                    </div>
                    <div className='cloud-ct-item right'>
                        <div className='cloud-item-li'>
                            <div className='cloud-item-icon'>
                                <CheckIcon />
                            </div>
                            <div className='cloud-item-text'>컨터이너를 이용한 컴퓨팅 자원 사용으로 비용절감</div>
                        </div>
                        <div className='cloud-item-li'>
                            <div className='cloud-item-icon'>
                                <CheckIcon />
                            </div>
                            <div className='cloud-item-text'>마이크로서비스 도입을 위한 개발과 시스템구성 상담</div>
                        </div>
                        <div className='cloud-item-li'>
                            <div className='cloud-item-icon'>
                                <CheckIcon />
                            </div>
                            <div className='cloud-item-text'>적은 비용으로 자체 독립 클라우드 구축및 운영지원</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='cloud-cs-contact'>
                <div className='cloud-cs-contact-box'>
                    <div className='cloud-contact-block'>
                        <div className='cloud-contact-item'>
                            <div className='cloud-contact-item1'>
                                <div className='cloud-contact-text'>신윤호 부장</div>
                                <div className='cloud-contact-number'>
                                    <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline" href={formatPhone()}>&nbsp;{"070-4804-9651"}</a>
                                    <span>&nbsp;</span>
                                    <span class="show-mail"><br></br></span>
                                    <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail()} >&nbsp;{"cloud@hanbiro.com"}</a>
                                </div>
                            </div>
                        </div>
                        <div className='cloud-contact-item'>
                            <div className='cloud-contact-item1'>
                                <div className='cloud-contact-text'>김정윤 부장</div>
                                <div className='cloud-contact-number'>
                                    <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline" href={formatPhone()}>&nbsp;{"070-4804-9668"}</a>
                                    <span>&nbsp;</span>
                                    <span class="show-mail"><br></br></span>
                                    <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail()} >&nbsp;{"cloud@hanbiro.com"}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cloud-contact-block'>
                        <div className='cloud-contact-item'>
                            <div className='cloud-contact-item1'>
                                <div className='cloud-contact-text'>신경섭 부장</div>
                                <div className='cloud-contact-number'>
                                    <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline" href={formatPhone()}>&nbsp;{"070-4804-9664"}</a>
                                    <span>&nbsp;</span>
                                    <span class="show-mail"><br></br></span>
                                    <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail()} >&nbsp;{"cloud@hanbiro.com"}</a>
                                </div>
                            </div>
                        </div>
                        <div className='cloud-contact-item'>
                            <div className='cloud-contact-item1'>
                                <div className='cloud-contact-text'>신상우 부장</div>
                                <div className='cloud-contact-number'>
                                    <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline" href={formatPhone()}>&nbsp;{"070-4804-9662"}</a>
                                    <span>&nbsp;</span>
                                    <span class="show-mail"><br></br></span>
                                    <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail()} >&nbsp;{"cloud@hanbiro.com"}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cloud-contact-block'>
                    <div className='cloud-contact-item'>
                            <div className='cloud-contact-item1'>
                                <div className='cloud-contact-text'>조성학 이사 (해외)</div>
                                <div className='cloud-contact-number'>
                                    <LocalPhoneIcon sx={{ fontSize: 13 }} /><a className="phoneline" href={formatPhone()}>&nbsp;{"+84 909 284 160"}</a>
                                    <span>&nbsp;</span>
                                    <span class="show-mail"><br></br></span>
                                    <MailOutlineIcon sx={{ fontSize: 13 }} /><a className="mailline" href={formatMail()} >&nbsp;{"cloud@hanbiro.com"}</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className='cloud-contact-item'>
                            {/* <div className='cloud-contact-item1'>
                                <div className='cloud-contact-text'></div>
                                <div className='cloud-contact-number'></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CloudConsultCp;