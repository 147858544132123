import React from 'react';
import styled from 'styled-components';
import '../../Card/Components/Contenttitlecard.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {
    Card, CardBody,
}from '@/shared/components/Card';
import Box from '@mui/material/Box'
import '../../../Style/defaultstyle.scss';






function Titlecloudcompute($content1,$content2,$content3) {
  
  return (
    <>
        <div className='App'>
            <Row>
                <Col md={12} >
                  <Box>
                    <GridCard >
                        <GridCardBody className="sub-title">
                            <Titlecloud2 className="normal narrow">{$content1}</Titlecloud2>
                            <Titlecloud2 className="normal narrow">{$content2}</Titlecloud2>
                            <Card.Link className="sizelink" href="https://www.hanbiro.com/hosting/product-list.html">{$content3}</Card.Link>
                        </GridCardBody>
                    </GridCard>
                  </Box>
                </Col>
            </Row>
            
            
            
        </div>
        

    </>
  );
}











const GridCard = styled(Card)`
  padding-bottom: 20px;
  
  p {
    margin-bottom: 0;
  }
  
`;

const GridCardBody = styled(CardBody)`
  padding: 10;
  text-align: center;
  background-color:#F5F7FA;
  font-weight: bold;
  border-radius: 10px;
  
`;




const Titlecloud2 = styled.p`
  font-weight: bold;
  
`;







export default Titlecloudcompute;
