import React from 'react';
import styled from 'styled-components';
import '../../Style/defaultstyle.css'
import '../../Style/defaultstyle.scss'

import { useTranslation } from "react-i18next";
function TableThreeColumns() {
  const { t } = useTranslation();
  return (
    <Box >
      <div>
        <div >
          <div >
            <h5 style={StySpecification.Title}>
              <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.title') }}></span>
            </h5>
          </div>
        </div>
        <div style={StySpecification.ListProduct}>
          <table width="100%">
            <tbody >
              <tr style={StySpecification.Header}>
                <td width="32%" style={StySpecification.BorLeft}>
                  <div style={StySpecification.TextTitle} className='sub-title'>
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_1.column_1') }}></span>
                  </div>
                </td>
                <td width="36%" >
                  <div style={StySpecification.TextTitle} className='sub-title'>
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_1.column_2') }}></span>
                  </div>
                </td>
                <td width="32%" style={StySpecification.BorRight}>
                  <div style={StySpecification.TextTitle} className='sub-title'>
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_1.column_3') }}></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                    <b><span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_2.column_1') }}></span></b>
                    </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_2.column_2') }}></span>
                  </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_2.column_3') }}></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                    <b><span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_3.column_1') }}></span></b>
                    </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_3.column_2') }}></span>
                  </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_3.column_3') }}></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                    <b><span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_4.column_1') }}></span></b>
                    </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_4.column_2') }}></span>
                  </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_4.column_3') }}></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                    <b><span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_5.column_1') }}></span></b></div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_5.column_2') }}></span>
                  </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_5.column_3') }}></span>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                    <b><span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_6.column_1') }}></span></b>
                    </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_6.column_2') }}></span>
                  </div>
                </td>
                <td style={StySpecification.BorAll}>
                  <div style={StySpecification.Text}  >
                  <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.row_6.column_3') }}></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={StySpecification.TextRight}>
            <div style={StySpecification.Text} class="textnote">
            <span dangerouslySetInnerHTML={{ __html: t('TableThreeColumns.Cloud') }}></span>
            </div>
          </div>
        </div>
      </div>
    </Box>


  );
}


const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginBottom: 30,
    marginTop: 30,
  },
  ListProduct: {
    paddingBottom: 20,
  },
  Title: {
    fontWeight: "bold",
    paddingBottom: 10,
    paddingTop: 10,

  },
  Header: {
    color: "white",
    background: "#393939",
  },
  TextTitle: {
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    fontWeight: "bold",
  },
  Text: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  BorLeft: {
    borderTopLeftRadius: "8px",
    borderRight: "2px solid #F4F4F4",
  },
  BorRight: {
    borderTopRightRadius: "8px",
    borderLeft: "2px solid #F4F4F4",
  },
  BorAll: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    paddingTop: 8,
    paddingBottom: 8,
  },
  KindLeft: {
    textAlign: "left",
  },
  TextLeft: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    fontWeight: 600,
    textAlign: "left",
    paddingLeft: "10px"
  },
  TextRight: {
    marginTop: "5px",
    textAlign: "right",
  }
}

const Box = styled.div`
`

export default TableThreeColumns;


