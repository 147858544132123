import React from 'react';
import TrafficFeeV1 from './TrafficFeeV1';
import '../../Style/defaultstyle.scss'
function TrafficFee() {
  return (
    <>
      <div >
        <div style={StySpecification.StylesContainer}>
          <div >
            <div >
              <h2 style={StySpecification.Title} className='header'>OBJECT 스토리지 요금안내</h2>
              <p style={StySpecification.Text}>
                <text className='sub-title'>API Endpoint 호출수 무료정책은 이벤트기간 종료시 요금정책이 변경됩니다.</text>
              </p>
            </div>
          </div>
          <div style={StySpecification.TableScroll}>
            <div style={StySpecification.TableWrap}>
              <div style={StySpecification.ListProduct}>
                <table width="100%" >
                  <tbody >
                    <tr style={StySpecification.Header} >
                      <td width="21%" style={StySpecification.BorLeft}><div style={StySpecification.TextTitle}><b className='sub-title'>구분</b></div></td>
                      <td width="21%" ><b className='sub-title'>서비스비용(일)</b></td>
                      <td width="21%" ><b className='sub-title'>서비스비용(월)</b></td>
                      <td width="37%" style={StySpecification.BorRight}><b className='sub-title'>비고</b></td>
                    </tr>
                    <tr>
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}><span >저장용량	</span></div> </td>
                      <td style={StySpecification.BorAll}><span  >1.5원 (1G)	</span></td>
                      <td style={StySpecification.BorAll}><span  >45원 (1G)	</span></td>
                      <td style={StySpecification.BorAll}><span  ></span></td>
                    </tr>
                    <tr>
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}><span  >API 요청수 (전체)		</span></div> </td>
                      <td style={StySpecification.BorAll} colSpan={2}><span  >무료 (이벤트기간)		</span></td>
                      <td style={StySpecification.BorAll}><span  >모든 API EndPoint</span></td>
                    </tr>
                    <tr>
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}><span  >트래픽		</span></div> </td>
                      <td style={StySpecification.BorAll}><span  >30G 까지 무료	</span></td>
                      <td style={StySpecification.BorAll}><span  >900G 까지 무료 </span></td>
                      <td style={StySpecification.BorAll}><span  >기본제공초과시 구간트래픽요금적용</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div style={StySpecification.StylesCenter}></div>
          <TrafficFeeV1/>
        </div>
      </div>
    </>

  );
}


const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  StylesCenter: {
    display: "flex",
    width: "100%",
  },
  Title: {
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  Header: {
    color: "white",
    background: "#393939",
  },
  TextTitle: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  TextContent: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  BorLeft: {
    borderTopLeftRadius: "8px",
    borderRight: "2px solid #F4F4F4",
  },
  BorRight: {
    borderTopRightRadius: "8px",
    borderLeft: "2px solid #F4F4F4",
  },
  BorAll: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
  },
  TextLeft: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    fontWeight: 900,
    textAlign: "left",
    paddingLeft: "10px"
  },

  TableScroll: {
    position: "relative",
    width: "100%",
    margin: "auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  TableWrap: {
    width: "100%",
    overflow: "auto",
  }
}


export default TrafficFee;
