import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import styled from 'styled-components';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import './sidebar.css'
import '../../Style/defaultstyle.css'
import CommonTable from '../../container/Table/Components/CommonTable';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import config from '../components/config';
import Sidebar from '../../container/Sidebar/Sidebar';
import { useTranslation } from "react-i18next";

// add
import { Grid } from '@mui/material';
import CardBox from '../../container/Box/Components/CardBox';
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function MSA3() {
    const { t } = useTranslation();
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner(t('banner.hanbiro'), t('banner.microsoft'))} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("어플리케이션 통합과 서비스")}
                        <BoxContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>{CardBox(
                                    "마이크로스서비스 아키텍쳐의 장점",
                                    `마이크로서비스 형태로 개발된 어플리케이션에서 작은 단위로 분리된 각각의 컴포넌트와 어플리케이션 사이에서 상호 운용성, 연결성, 통합성을 손쉽게 수행해 줍니다. 
                                    <br><br>
                                    또한, 고객에게는 빠른 개발, 쉬운업데이트, 더 빠른 기능추가가 가능하며, 업데이트, 패치, 관리에서 벗어나 고객의 비지니스에 더 집중하게 될 수 있습니다.`
                                )}
                                </Grid>
                                <Grid item xs={12} sm={12}>{CardBox(
                                    "API 서비스, 서비스형 서버리스란",
                                    `자신의 서버나 계약된 Cloud 서비스가 아닌 외부의 클라우드 서버에서 작동되는 프로그램과 스토리지 데이터베이스등을 서비스 형태로 사용하고 사용한 만큼만 비용을 지불하는 서비스입니다. 
                                    <br></br>
                                    어플리케이션 Scaling 과 분산시스템이 적용된 서비스이며, 개발자는 직접 프로그래밍을 할 필요가 없어 시간절약과 부담이 많이 줄어들게 됩니다.`
                                )}
                                </Grid>
                                {/*
                                <Grid item xs={12}>{Imagecloudcompute("https://www.hanbiro.com/cloud/library2/cluster-cloud-postgresql-db/postgresql_cluster.svg")}</Grid>
                                */}
                            </Grid>
                            <br></br>
                            <Boxtitle className='MuiTypography-root MuiTypography-h6 sub-title normal css-y5btr1-MuiTypography-root'>{t('msa_3.line_5')}</Boxtitle>
                            <CommonTable header={config.tb1_header} content={config.tb1_content} />
                        </BoxContent>

                        {/* <CloudConsultCp /> */}
                    </Col>

                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 30px;
    text-align: left;
`
const SmallBox = styled.div`
    border: 1px solid #E7EAF3;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: left;
`

const Boxtitle = styled.div`
    margin: 20px 5px;
    font-weight: bold;
`


const BoxText = styled.div`
    margin: 20px 5px;
    
`

export default MSA3;