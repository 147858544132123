import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";


function TableThreeColumns() {
  const { t } = useTranslation();
  const header = {
    row_1: {
      col_1: t('TableThreeColumns.row_1.column_1'),
      col_2: t('TableThreeColumns.row_1.column_2'),
      col_3: t('TableThreeColumns.row_1.column_3')
    }
  }
  let listHeader = [];
  Object.keys(header).forEach(function (key) {
    listHeader.push(
      <tr style={StySpecification.Header}>
        <td width="32%" style={StySpecification.BorLeft}>
          <div style={StySpecification.TextTitle}>
            <div dangerouslySetInnerHTML={{ __html: header[key].col_1 }}></div>
          </div>
        </td>
        <td width="36%" >
          <div style={StySpecification.TextTitle}>
            <div dangerouslySetInnerHTML={{ __html: header[key].col_2 }}></div>
          </div>
        </td>
        <td width="32%" style={StySpecification.BorRight}>
          <div style={StySpecification.TextTitle}>
            <div dangerouslySetInnerHTML={{ __html: header[key].col_3 }}></div>
          </div>
        </td>
      </tr>
    );

  })


  const data = {
    row_2: {
      col_1: t('TableThreeColumns.row_2.column_1'),
      col_2: t('TableThreeColumns.row_2.column_2'),
      col_3: t('TableThreeColumns.row_2.column_3')
    },
    row_3: {
      col_1: t('TableThreeColumns.row_3.column_1'),
      col_2: t('TableThreeColumns.row_3.column_2'),
      col_3: t('TableThreeColumns.row_3.column_3')
    },
    row_4: {
      col_1: t('TableThreeColumns.row_4.column_1'),
      col_2: t('TableThreeColumns.row_4.column_2'),
      col_3: t('TableThreeColumns.row_4.column_3')
    },
    row_5: {
      col_1: t('TableThreeColumns.row_5.column_1'),
      col_2: t('TableThreeColumns.row_5.column_2'),
      col_3: t('TableThreeColumns.row_5.column_3')
    },
    row_6: {
      col_1: t('TableThreeColumns.row_6.column_1'),
      col_2: t('TableThreeColumns.row_6.column_2'),
      col_3: t('TableThreeColumns.row_6.column_3')
    }
  }
  let listRow = [];
  Object.keys(data).forEach(function (key) {
    listRow.push(
      <tr>
        <td width={data[key].width_Col1} style={StySpecification.BorAll}>
          <div style={StySpecification.Text}>
            <div dangerouslySetInnerHTML={{ __html: data[key].col_1 }}>
            </div>
          </div>
        </td>
        <td width={data[key].width_Col2} style={StySpecification.BorAll}>
          <div style={StySpecification.Text}>
            <div dangerouslySetInnerHTML={{ __html: data[key].col_2 }}>
            </div>
          </div>
        </td>
        <td width={data[key].width_Col3} style={StySpecification.BorAll}>
          <div style={StySpecification.Text}>
            <div dangerouslySetInnerHTML={{ __html: data[key].col_3 }}>
            </div>
          </div>
        </td>
      </tr>
    );
  })

  
  return (
    <Box >
      <div>
        <div >
          <div >
            <h5 style={StySpecification.Title}>{t('TableThreeColumns.title')} </h5>
          </div>
        </div>
        <div style={StySpecification.ListProduct}>
          <table width="100%">
            <tbody >
              {listHeader}
              {listRow}
            </tbody>
          </table>
          <div style={StySpecification.TextRight}>
            <div style={StySpecification.Text}>{t('TableThreeColumns.Cloud')}</div>
          </div>
        </div>
      </div>
    </Box>


  );
}


const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginBottom: 30,
    marginTop: 30,
  },
  ListProduct: {
    paddingBottom: 20,
  },
  Title: {
    fontWeight: "bold",
    paddingBottom: 10,
    paddingTop: 10,

  },
  Header: {
    color: "white",
    background: "#393939",
  },
  TextTitle: {
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    fontWeight: "bold",
  },
  Text: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  BorLeft: {
    borderTopLeftRadius: "8px",
    borderRight: "2px solid #F4F4F4",
  },
  BorRight: {
    borderTopRightRadius: "8px",
    borderLeft: "2px solid #F4F4F4",
  },
  BorAll: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    paddingTop: 8,
    paddingBottom: 8,
  },
  KindLeft: {
    textAlign: "left",
  },
  TextLeft: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    fontWeight: 600,
    textAlign: "left",
    paddingLeft: "10px"
  },
  TextRight: {
    marginTop: "5px",
    textAlign: "right",
  }
}

const Box = styled.div`
  @media only screen and (max-width: 767px){
    font-size: 80%;
  }
`

export default TableThreeColumns;


