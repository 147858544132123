import React, { useEffect, useLayoutEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavButton from "../../homepage/header-banner/TopNavButton";
import Footer from '../../homepage/footer/Footer';
import { Check } from '@mui/icons-material';
import '../assets/css/style-demo.css';


function HelpDesk() {
    return (
        
        <div>
             <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#47A7FF' },
                itemNavClass: "item-nav",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />


             {/* banner */}

            <div class="d-banner-helpdesk section-box-helpdesk">
                    <div class="banner-block-helpdesk banner-text-helpdesk"  >
                        <div>
                            <div class="helpdesk-text-sz40 mb-4">헬프데스크란 ?</div>
                            <div class="helpdesk-text-sz16">메일, 홈페이지, 게시판 등 여러 채널로 들어오는 고객 문의를 <br />
                            "헬프데스크"라는 하나의 창구에서 관리합니다. <br/>담당자 배정 및 상태 변경, 메일 답변을 지원하고 <br/>
                            내부 구성원 및 고객과 공유합니다.
                            </div>
                        </div>
                        <div class="btn-get-pricing-helpdesk">
                            <button class="btn-more-helpdesk btn-sz-helpdesk">
                                Get Started
                            </button>
                            <button class="contact-btn-helpdesk btn-sz-helpdesk light">
                                Request a demo
                            </button>
                        </div>
                    </div>

                    <div class="banner-block-helpdesk banner-infor-helpdesk" >
                        <img src="https://hanbiro.com/assets/images/cloud/helpdesk-concept-1.png" />
                    </div>
            </div>
            {/* contain */}

            <div class="contain">
                <div class="section-box-helpdesk">
                    <div class="collab-tt-helpdesk">
                        <div class="text-sz40-helpdesk mb-4">헬프데스크 상세 안내</div>
                    </div>
                    <div class="row block-content-helpdesk">
                        <div class="column-helpdesk col-lg-8 ">
                            <div class="content-helpdesk">
                                <div class="text-sz16-left-helpdesk">
                                    고객을 연결하고, 진행상태와 담당자를 설정합니다. <br/>
                                    메일 답장을 통해 헬프데스크에서 바로 고객에게 답변합니다.
                                </div>
                                <div class="image-helpdesk">
                                    <img src="https://hanbiro.com/assets/images/cloud/ticket-reply.png" class="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div class="column-helpdesk col-lg-4 ">
                            <div class="content-helpdesk">
                                <div class="text-sz16-left-helpdesk">
                                    변경 이력을 통해 고객 문의  <br/>
                                    사항을 추적하고 관리합니다.
                                </div>
                                <div class="image-helpdesk">
                                    <img src="https://hanbiro.com/assets/images/cloud/ticket-history.png" class="img-fluid2" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="helpdesk-box2 mt-3">
                       <div class="content-helpdesk-box2 ">
                            <div class="title-box2-helpdesk-sz16">
                                모든 고객 문의는 “헬프데스크” 에서 통합 관리합니다.<br/>
                                현재 문의가 누구에게 배정되었는지, 어떻게 진행되고 있는지 실시간으로 확인합니다.
                            </div>
                            <div class="image2-box2-helpdesk">
                                <img src="https://hanbiro.com/assets/images/cloud/ticket-list.png" />
                            </div>
                        </div>     
                    </div>
                </div>

            </div>


            <div class="section-box-helpdesk background-helpdesk ">
                    <div class="box3-title-helpdesk mb-5">
                        <div class="helpdesk-text-sz40 " >헬프데스크 장점</div>
                    </div>

                    <div class="row box3-helpdesk" >
                        <div class="col-lg-6 ">
                            <div class="box3-content-left-helpdesk">
                                <div class="image-box3-helpdesk">
                                    <img src="https://hanbiro.com/assets/images/cloud/helpdesk-concept-2.png" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="box3-content-helpdesk">
                                <div class="box3-content-right-helpdesk">
                                    <div class="text-sz20-helpdesk mb-3">
                                        <span>고객 관리 솔루션</span>
                                        <span class="text-sz20-helpsedk-color">하나로</span>
                                        <span> 통합 하여 문제 해결</span>
                                    </div>

                                    <div class="box3-text-sz16-helpdesk">
                                        <div>•</div>
                                        <div>모든 고객 문의 정보가 한 곳에서 관리되므로 , 지원팀은 빠르게 요한 정보를 찾아 <br/>
                                        일관성 있는 답변을 하게되어 고객 만족도가 높아집니다</div>
                                    </div>
                                    <div class="box3-text-sz16-helpdesk">
                                        <div>•</div>
                                        <div>이력관리를 통해 지연 및 누락에 따른 고객 민원이 감소됩니다.</div>
                                    </div>
                                </div>

                                <div class="box3-content-right-helpdesk">
                                    <div class="text-sz20-helpdesk mb-3">
                                        <span class="text-sz20-helpsedk-color">중소기업 맞춤</span>
                                        <span> 으로 간소화된 기능 제공!</span>
                                    </div>

                                    <div class="box3-text-sz16-helpdesk">
                                        <div>•</div>
                                        <div>초기 설정이 간단하고, 복잡한 절차가 없기에 별도의 교육이 필요 없습니다.</div>
                                    </div>
                                    <div class="box3-text-sz16-helpdesk">
                                        <div>•</div>
                                        <div>담당자별 업무처리 현황을 파악하여, 제한된 인력 자원을 효율적으로 배분하는데 <br/>활용합니다 .</div>
                                    </div>
                                </div>
                                
                                <div class="box3-content-right-helpdesk">
                                    <div class="text-sz20-helpdesk mb-3">
                                        <span>그룹웨어를 사용하면 헬프데스크 기능은 </span>
                                        <span class="text-sz20-helpsedk-color">Free</span>
                                        <span> 로 이용</span>
                                    </div>

                                    <div class="box3-text-sz16-helpdesk">
                                        <div>•</div>
                                        <div>한비로 그룹웨어 사용 시 , 헬프데스크 기능은 무료로 제공됩니다.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                

            </div>




        

            <Footer />

        </div>
    )
}

export default HelpDesk;