import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import styled from 'styled-components';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
import './sidebar.css'
import '../../Style/defaultstyle.css'
import '../../Style/defaultstyle.scss'
import CommonTable from '../../container/Table/Components/CommonTable';
import CardWrap from '../components/CardWrapper';
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import config from '../components/config';
import Sidebar from '../../container/Sidebar/Sidebar';

// add
import { Grid } from '@mui/material';
import CardBox from '../../container/Box/Components/CardBox';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';

// Titlecloudcompute
function Iaam() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            {/* {Banner("Hanbiro", "Microservice architecture")} */}
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {Titlecloudcompute("Identity and Access Management")}

                        {CardWrap("개념", config.tb2_header, config.tb2_content)}

                        <BoxContent>
                            <Box sx={TitleWrap}><Typography className="sub-title normal" sx={CardTitle}>OIDC/Oauth</Typography></Box>
                            <BoxText >
                                • OIDC (Open ID Connect) : 인증을 위한것, 사용자의 신원정보가 담긴 ID Token 확보. <br />
                                • OAuth : <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delegated Authorization (위임권한). <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resource 서버에 접근가능 확인절차. <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resource 서버로부터 리소스를 가져오기 위한 Access Token 확보.
                            </BoxText>

                            <Grid container spacing={1}>
                                <ImageWrap src="https://www.hanbiro.com/cloud/library/msa3/msa3-1.svg"></ImageWrap>
                                <Grid item xs={12} sm={12}>{CardBox(
                                    "",
                                    `예) <br>
                                    (자사 Application)에서 (사용자)의 (페이스북)계정으로 로그인 (1) ~ (9) <br>
                                    (자사 Application)에서 (사용자)의 (페이스북) 권한으로 뉴스피드(리소스)에 글 작성허용 (A) ~ (D)
                                    <br></br>
                                    위임권한과 방법 <br>
                                    1. 사용자가 자사의 Application 에 페이스북 ID/PW를 전달 (보안으로 사용하면 안됨) <br>
                                    2. OAuth를 이용 (내앱에서 사용자의 ID, PW를 몰라 안전) <br>`
                                )}
                                </Grid>
                            </Grid>
                            <BoxContent>
                                <BoxTitleIaam className='sub-title'>Token 생성과 권한</BoxTitleIaam>
                                <CommonTable header={config.tb3_header} content={config.tb3_content} />
                            </BoxContent>
                        </BoxContent>


                        <BoxContent>
                            <Box sx={TitleWrap}><Typography className="sub-title normal" sx={CardTitle}>• IAM 사용가이드</Typography></Box>

                            <ImageWrap src="https://www.hanbiro.com/cloud/library/msa3/msa3-2.svg"></ImageWrap>
                            <SmallBox>
                                <BoxDetail >Role 은 생성/수정/삭제/공유/읽기(리스팅/뷰)의 허용권한의 집합체.</BoxDetail>
                            </SmallBox>

                            <SmallBox>
                                <BoxDetail >
                                    - 기본 생성된 Role Name -- 예)Role Name(Permission Action)<br />
                                    - Owner(all)<br />
                                    - Admin(all except delete)<br />
                                    - Editor(create/ delete/ update/ get/ list)<br />
                                    - Viewer(get/list)<br />
                                    - OnlyListingUser(list)
                                </BoxDetail>
                            </SmallBox>

                            <SmallBox>
                                <BoxDetail >
                                    예)<br />
                                    리소스 - 파일A<br />
                                    생성자인 A유저는 All 권한<br />
                                    B유저에게 (생성/삭제/업데이트/읽기)권한 부여<br />
                                    C그룹에게 리스팅 보이기 권한만 부여
                                </BoxDetail>
                                <br></br>
                                <BoxText >• 설정</BoxText>
                                <BoxDetail >
                                    파일 A 의 Access Control 설정에서<br />
                                    A 유저는 파일 생성시 자동 생성된 Tag A;s Files 로 모든 권한부여<br />
                                    B 유저를 선택해서 Role Name 이 Editor 를 선택<br />
                                    C 그룹을 선택해서 Role Name 이 OnlyListingUser 를 선택<br />
                                    Tag = Service / Resource Type / Owner
                                </BoxDetail>
                            </SmallBox>

                            {/* {Imagecloudcompute("https://www.hanbiro.com/docs/cloud/images/c.png")} */}

                            {/*}
                            <BoxDetail >
                                사용자와 자원을 분리해 중간에 역활을 추가<br/>
                                사용자는 자주 바뀌지만 역활은 자주바뀌지 않음<br/>
                                서비스(Object)에 접속하는 Client(Subject)들은 미리 정의한<br/>
                                역활명으로 분류함 역활에 따라 자원(Object)의 Actions 권한을 할당<br/>
                                권한을 줄때 Role 단위로 줌<br/>
                                사용자는 할당된 역활에 기반하여 객체에 접근<br/>
                                역활은 직무에 따라서 정의<br/>
                                한유저는 여러 역활에 배정가능<br/>
                                여러유저는 한 역활에 배정가능
                            </BoxDetail>
                            {*/}
                        </BoxContent>
                        {/* <CloudConsultCp /> */}
                    </Col>
                </Row>
            </Container>
            <Cloudconsult />
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

const BoxSubText = styled.div`
  margin-left: 3%;
`

const BoxTitleIaam = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 15px;
`

const CardTitle = {
    textAlign: "left",
    fontWeight: "bold",
    fontFamily: "맑은고딕, Dotum, 돋움, sans-serif !important;"
}

const TitleWrap = {
    height: "40px",
}

// const Box = styled.div`
//   @media only screen and (max-width: 767px){
//     font-size: 80%;
//   }
// `

const ImageWrap = styled.img`
  width: 100%;
  object-fit: fill;
`

const BoxContent = styled.div`
    box-shadow: 0 0.375rem 1.5rem rgb(140 152 164 / 13%);
    border: 1px solid #E7EAF3;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: left;
`

const SmallBox = styled.div`
    border: 1px solid #E7EAF3;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: left;
`

const BoxDetail = styled.div`
    margin: 10px 5px;
    line-height: 2em;
`

const BoxText = styled.div`
    margin: 5px;
    line-height: 2em;
`


export default Iaam;