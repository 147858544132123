import React from 'react';
import styled from 'styled-components';
import {
  Container, Row
} from 'react-bootstrap';
import Map from './map';
import User from '../SVG/User.svg'
import Mail from '../SVG/Mail.svg'
import Phone from '../SVG/Phone.svg'
import '../../Style/defaultstyle.css';

function FooterOutsourcing() {
  return (
    <>
        <BgImage>
          <Container>
          <BoxHr></BoxHr>
            <Row>
              <BoxContent>
                <LeftContent>
                  <BoxInfor>
                    <BoxUser>
                        <UserInfor>한국어</UserInfor>
                          <TextInfor>
                            <Image src={User}></Image>
                            <TextPa>조성학</TextPa>
                          </TextInfor>

                          <TextInfor>
                            <Image src={Phone}></Image>
                            <TextLink href='tel:+82 10 9292 3646'>+82 10 9292 3646</TextLink>
                          </TextInfor>

                           <TextInfor>
                            <Image src={Phone}></Image>
                            <TextLink href='tel:+84 909 284 160'>+84 909 284 160</TextLink>
                          </TextInfor>

                          <TextInfor>
                            <Image src={Mail}></Image>
                            <TextLink href='mailto:cloud@hanbiro.com'>cloud@hanbiro.com</TextLink>
                          </TextInfor>
                    </BoxUser>

                    <BoxUser>
                        <UserInfor>영어</UserInfor>
                          <TextInfor>
                            <Image src={User}></Image>
                            <TextPa>문소리</TextPa>
                          </TextInfor>

                          <TextInfor>
                            <Image src={Phone}></Image>
                            <TextLink href='tel:+82 1544 4755'>+82 1544 4755</TextLink>
                          </TextInfor>
                          
                          <TextInfor>
                            <Image src={Mail}></Image>
                            <TextLink href='mailto:cloud@hanbiro.com'>cloud@hanbiro.com</TextLink>
                          </TextInfor>
                    </BoxUser>
                  </BoxInfor>
                </LeftContent>

                <RightContent>
                  <Map />
                </RightContent>

              </BoxContent>
            </Row>
          </Container>
        </BgImage>
    </>
  )
}

export default FooterOutsourcing;


const Image = styled.img`
  // width: 100%;
`

const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  @media (max-width: 1023px) {
    flex-direction: column;
    margin-top: 30px;
  }
`

const BoxHr = styled.div`
  width: 100%;
  border-top: 1px solid #CACBCF;
`

const RightContent = styled.div`
  flex: 1;
  display: flex;
  align-items:center;
  margin-right: 70px;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
`

const BgImage = styled.div`
  width: 100%;
  background-size: 100%;
  padding: 50px 0 50px 0;
`

const LeftContent = styled.div`
  padding: 0 50px;
  text-align: left;
  flex: 1;

  @media (max-width: 1180px) {
    padding: 0;
  }
`

const BoxUser = styled.div`
  margin-right: 15px;
@media (max-width: 767px) {
  margin-bottom: 15px;

}
`

const BoxInfor = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px 50px 25px 0;

  @media (max-width: 1024px) {
    margin: 15px 0;
  }

  @media (max-width: 666px) {
    flex-direction: column;
  }
`

const UserInfor = styled.div`
  color: gray;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
`

const TextInfor = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  padding-bottom: 5px;
`
const TextPa = styled.p`
  margin-bottom: 0;
  margin-left: 10px; 
  font-size: 16px;
`

const TextLink = styled.a`
  margin-bottom: 0;
  margin-left: 10px;
  text-decoration: none;
  // color: #000;
  font-weight: 100; 

  &:hover {
    color: #763030;
    cursor: pointer;
  }
`

