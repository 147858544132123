import React from 'react';
import HomePage from "../homepage";
import {Helmet} from "react-helmet";
function App() {
  return (
    <div className="App">
      <Helmet>
          <title>Hanbiro Cloud</title>
          <meta
            name="description"
            content="한비로 오픈스택(Openstack), 쿠버네티스(Kubernetes), 클라우드(Cloud), 마이크로서비스(Microservice Architecture) 안내페이지입니다."
          />
          <meta name="keywords" content="
            Cloud, Openstack, Kubernetes, Cloud, Outsourcing, Microservice, Event Sourcing, Messaging, S3, AWS, Go, Grpc, Protobuf, Python
          " />
        </Helmet>
      <HomePage />
    </div>
  );

}

export default App;
