import { AiOutlineCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import '../container/sidebar.css'
import i18n from "../../translations/I18n";

const obj = {

    tb1_header: {
        row_1: [
            {
                title: i18n.t('msa_3_config.table_1.row_1.column_1'),
            },
            {
                title: i18n.t('msa_3_config.table_1.row_1.column_2'),
            },
            {
                title: i18n.t('msa_3_config.table_1.row_1.column_3'),
            },

        ]
    },
    tb1_content: {
        row_1: [
            {
                title: i18n.t('msa_3_config.table_1.row_2.column_1'),
            },
            {
                title: i18n.t('msa_3_config.table_1.row_2.column_2'),
            },
            {
                title: i18n.t('msa_3_config.table_1.row_2.column_3'),
            },

        ],
        row_2: [
            {
                rowSpan: "4",
                title: i18n.t('msa_3_config.table_1.row_3.column_1'),
            },
            {
                title: i18n.t('msa_3_config.table_1.row_3.column_2'),
            },
            {
                title: i18n.t('msa_3_config.table_1.row_3.column_3'),
            },


        ],
        row_3: [
            {
                title: "OAuth Server/Client 구축으로 권한위임과 인가를 통한 Access Control",
            },
            {
                title: "OAuth",
            },

        ],
        row_4: [
            {
                title: "ID 의 그룹화와 조직도 관리",
            },
            {
                title: "Directory",
            },

        ],
        row_5: [
            {
                title: "Object 의 읽기, 쓰기, 삭제등의 권한을 Role 에 부여, Access Control List <br> 생성",
            },
            {
                title: "Role based <br> Access Management",
            },

        ],
        row_6: [
            {
                rowSpan: "4",
                title: "Messaging",
            },
            {
                title: "Publisher 가 메세지를 토픽으로 정의하고 등록, Subscriber 에게 Broadcast",
            },
            {
                title: "Topics",
            },

        ],
        row_7: [
            {
                title: "수신된 메세지 토픽을 필터링하여 Subscriber 당 Queue 에 저장해 관리",
            },
            {
                title: "Queues",
            },

        ],
        row_8: [
            {
                title: "서버측 어떤 event 를 트리거로 등록해 외부 End Point 로 Push 전달",
            },
            {
                title: "Webhooks",
            },

        ],
        row_9: [
            {
                title: "리눅스 Cron 형식지원으로 어플리케이션과 Process 에서 사용",
            },
            {
                title: "Scheduler",
            },

        ],
        row_10: [
            {
                title: "Web RPC",
            },
            {
                title: "Unary, Streaming (Web Socket: Server / Client Bidirectional),<br> Client Type script (Streaming 용)",
            },
            {
                title: "WebRPC",
            },

        ],
        row_11: [
            {
                title: "Long-running",
            },
            {
                title: "Event Sourcing 내 모듈 CUD 의 비동기 작동으로 서버응답을 별도로 <br> 하고 페이지전환",
            },
            {
                title: "Long-running",
            },

        ],
        row_12: [
            {
                title: "Storage (S3)",
            },
            {
                title: "Cloud 내 생성되는 모든 업로드된 파일들을 Object Storage 에 직접저장",
            },
            {
                title: "Core Storage",
            },

        ],
        row_13: [
            {
                title: "Email",
            },
            {
                title: "Cloud 내 Outgoing E-mail 대량발송과 Reporting Dashboard 제공",
            },
            {
                title: "Vora Email",
            },

        ],
        row_14: [
            {
                rowSpan: "2",
                title: "Process(Workflow)",
            },
            {
                title: "업무자동화, 자동상태변경, 담당자에 자동업무전달",
            },
            {
                title: "Business Process",
            },

        ],
        row_15: [
            {
                title: "발송되는 Email/SMS 를 통해 연결된 Site 내 클릭에 따른 자동 프로세스",
            },
            {
                title: "Journey Process",
            },

        ],
        row_16: [
            {
                rowSpan: "2",
                title: "Rule",
            },
            {
                title: "트리거로 자동실행되어 Criteria 의 True / False 에 따른 액션",
            },
            {
                title: "Automation Rule",
            },

        ],
        row_17: [
            {
                title: "룰에 따른 담당자 자동할당",
            },
            {
                title: "Assignment Rule",
            },

        ],
        row_18: [
            {
                title: "Rate Limiting",
            },
            {
                title: "불안정하고 예측불가한 클라이언트 요청이 급증하는 것에 대한 어플리케이션 보호",
            },
            {
                title: "Rate Limiting",
            },

        ],
        row_19: [
            {
                title: "Simple URL shortner",
            },
            {
                title: "긴 URL 을 짧은 URL 로 개인별 변환되며 받는이 식별과 레포팅 제공",
            },
            {
                title: "SUS",
            },

        ]
    },

    tb2_header: {
        row_1: [
            {
                title: "",
            },
            {
                title: "",
            },

        ]
    },
    tb2_content: {
        row_1: [
            {
                title: "Identity (식별)",
            },
            {
                title: "구성원(Principal), 신원(Id) 제시 - 사용자명, 계정번호",
            },

        ],
        row_2: [
            {
                title: "Authentication (인증)",
            },
            {
                title: " 제시된 신원이 올바른지 증명 - 패스워드, 생체인식, 스마트카드 등",
            },

        ],
        row_3: [
            {
                title: "Authorization (인가)",
            },
            {
                title: "특정 객체(Object)에 대해 접근(Access)을 허용하는 과정 <br>-- 접근제어목록 (ACL : Access Control List)",
            },

        ],
        row_4: [
            {
                title: "Access Control (접근제어)",
            },
            {
                title: "식별 -> 인증 -> 인가를 통과한 유저에게 특정시스템이나 정보에 접근하도록 통제 <br>접근제어유형 : 여러 유형중 RBAC (Role Based Access Control) 를 지원",
            },

        ],
        row_5: [
            {
                title: "RBAC",
            },
            {
                title: "Principal(구성원)에게 리소스(Resource)에 대한 Role을 부여하고,<br>미리 정해진 Role에 리소스별 Permission 부여.<br> Permission : Write / Delete / Update / List / Get",
            },

        ],
        row_6: [
            {
                title: "IdP (ID 공급자)",
            },
            {
                title: "Identity Provider, 디지털 ID 생성, 저장, 관리.<br>사용자를 직접 인증하거나, 타사 서비스공급자(SP)에 인증제공 (Google / Facebook 등)",
            },

        ],
    },

    tb3_header: {
        row_1: [
            {
                title: "구분",
            },
            {
                title: "Access Token (OAuth)",
            },
            {
                title: "ID Token (OIDC)",
            },
        ]
    },
    tb3_content: {
        row_1: [
            {
                title: "리소스 접근권한",
            },
            {
                title: "",
                icon: <AiOutlineCheckCircle className="icon-check" />,
            },
            {
                title: "",
                icon: <AiOutlineCloseCircle className="icon-close" />,
            },
        ],
        row_2: [
            {
                title: "사용자 신원정보",
            },
            {
                title: "",
                icon: <AiOutlineCloseCircle className="icon-close" />,
            },
            {
                title: "",
                icon: <AiOutlineCheckCircle className="icon-check" />,
            },
        ],
    },

    tb4_header: {
        row_1: [
            {
                title: "항목",
            },
            {
                title: "내용",
            },
            {
                title: "가격(원)",
            },
        ]
    },
    tb4_content: {
        row_1: [
            {
                title: "API 요청",
            },
            {
                title: "최초 ________ 건까지",
            },
            {
                title: "00 원",
            },
        ],
        row_2: [
            {
                title: "",
            },
            {
                title: "이후 ________ 건까지",
            },
            {
                title: "00 원",
            },
        ],
    },
    tb5_header: {
        row_1: [
            {
                title: "항목",
            },
            {
                title: "내용",
            },
            {
                title: "가격(원)",
            },
        ]
    },
    tb5_content: {
        row_1: [
            {
                title: "Event Store",
            },
            {
                title: "최초 ________ 건까지",
            },
            {
                title: "00 원",
            },
        ],
        row_2: [
            {
                title: "",
            },
            {
                title: "이후 ________ 건까지",
            },
            {
                title: "00 원",
            },
        ],
    },

    tb6_header: {
        row_1: [
            {
                title: "항n",
            },
            {
                title: "건 (월비용)",
            },
            {
                title: "가격(원)",
            },

        ]
    },
    tb6_content: {
        row_1: [
            {
                title: "클라우드 내부간 Push",
            },
            {
                title: "최초 ________ 건까지",
            },
            {
                title: "00 원",
            },

        ],
        row_2: [
            {
                rowSpan: "2",
                title: "외부 Push",
            },
            {
                title: "백만 건까지",
            },
            {
                title: "00 원",
            },

        ],
        row_3: [
            {
                title: "이후 십만건당",
            },
            {
                title: "1,000 원",
            },

        ],
    },

    tb7_header: {
        row_1: [
            {
                Cols50: "True",
                title: "일반적인 방식",
            },
            {
                title: "Long-running 방식",
            },
        ],
    },
    tb7_content: {
        row_1: [
            {
                bold: "False",
                title: "모든 코드는 동기적으로 작동.<br>동기적은 코드가 순서대로 실행되며, 이전코드의 실행이 끝날때까지 기다림.",
            },
            {
                title: "코드를 비동기로 처리하면, 서버내 백그라운드에서 작업을 처리 <br> 작업이 끝날때까지 기다리지 않고 다음코드를 실행",
            },
        ],
    },

    tb8_header: {
        row_1: [
            {
                title: "항옥",
            },
            {
                title: "API 사용건",
            },
            {
                title: "웹 접속건",
            },
            {
                title: "가격(원)",
            },
        ],
    },
    tb8_content: {
        row_1: [
            {
                title: "클라우드 사용고객",
            },
            {
                title: "________________",
            },
            {
                title: "_______ /월 건당",
            },
            {
                title: "00 원",
            },
        ],
        row_2: [
            {
                title: "외부 - Vora Works",
            },
            {
                title: "________________",
            },
            {
                title: "_______ /월 건당",
            },
            {
                title: "00 원",
            },
        ],
    },

    tb9_header: {
        row_1: [
            {
                title: "&nbsp;",
            },
            {
                title: "Topic",
            },
            {
                title: "Queue",
            },
            {
                title: "Channel",
            },
        ],
    },
    tb9_content: {
        row_1: [
            {
                title: "Delivery",
            },
            {
                title: "<div style='text-align:center'>At Least Once (Exactly Once) <br> <span style='color: #48A7FC; font-size: 14px'>(Committing Offset 방식)</span> <img src='https://hanbiro.com/cloud/images/cloud/question.svg' width='20px'></img></div>"
            },
            {
                title: "<div style='text-align:center'>At Least Once (Exactly Once) <br> <span style='color: #48A7FC; font-size: 14px'>(Ack Reply 방식)</span> <img src='https://hanbiro.com/cloud/images/cloud/question.svg' width='20px'></img></div>"
            },
            {
                title: "<div style='text-align:center'>At Most Once</div>",
            }
        ],
        row_2: [
            {
                title: "Storage",
            },
            {
                title: "<div style='text-align:center'>Disk</div>",
            },
            {
                title: "<div style='text-align:center'>Disk</div>",
            },
            {
                title: "<div style='text-align:center'>In Memory</div>",
            },
        ],
        row_3: [
            {
                title: "Ordering",
            },
            {
                title: "<div style='text-align:center'>순서보장</div>",
            },
            {
                title: "<div style='text-align:center'>순서보장안함</div>",
            },
            {
                title: "<div style='text-align:center'>X</div>",
            },
        ],
        row_4: [
            {
                title: "Consumer",
            },
            {
                title: "<div style='text-align:center'>Pub / Sub</div>",
            },
            {
                title: "<div style='text-align:center'>1:1</div>",
            },
            {
                title: "<div style='text-align:center'>Fan Out (Broadcast)</div>",
            },
        ],
    },
    tb10_header: {
        row_1: [
            {   
                Cols50: "True",
                title: "Pull"
            },
            {
                title: "Push"
            },
        ],
    },
    tb10_content: {
        row_1: [
            {
                bold: "False",
                title: "<div style='text-align:'>Streaming Pull 지원. <br> Consumer 가 API 를 호출해서 Message 를 가져감.</div>"
            },
            {
                title: "<div style='text-align:'>Worker가 Message를 받아 GRPC Unary나 URL Get/Post를 보냄.</div>"
            }
        ]
    },
};


export default obj;