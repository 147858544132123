import React from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import './App.css';
import "bootstrap/dist/js/bootstrap.min.js";
import { Container, Row, Col } from "react-bootstrap";
import Slide from "@mui/material/Slide";




function NavButton() {

  return (
    <>
      <div className="slotho-bg">
        <Container>
          <Row>
            <Col md={12} sm={12} className="slotho-list">
              <div className="Container-bar-left">
                <div className="slotho-list-list">
                  {/* <div >

                    <Slide
                      direction="right"
                      in={true}
                      mountOnEnter
                      unmountOnExit
                      timeout={1000}
                    >
                      <img
                        className="img-logo"
                        src={"https://www.hanbiro.com/cloud/images/main-text.png"}
                      ></img>
                    </Slide>
                  </div> */}



                  <Slide
                    direction="right"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                    style={{
                      transitionDelay: 1000,
                    }}
                  >
                    <div>쿠버네티스 기반 클러스터</div>
                  </Slide>

                  <Slide
                    direction="right"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                    style={{
                      transitionDelay: 1500,
                    }}
                  >
                    <div>
                    서버리스 어플리케이션 제공
                    </div>
                  </Slide>

                  <Slide
                    direction="right"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                    style={{
                      transitionDelay: 2000,
                    }}
                  >
                    <div>
                    마이크로서비스 아키텍쳐 기반 개발 지원
                    </div>
                  </Slide>

                  <Slide
                    direction="right"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                    timeout={1000}
                    style={{
                      transitionDelay: 2500,
                    }}
                  >
                    <div>
                    빠른 앱 개발 지원, 클라우드 운영비 절감
                    </div>
                  </Slide>
                </div>
              </div>
              <div className="Container-bar-right">

                <Slide
                  direction="left"
                  in
                  mountOnEnter
                  unmountOnExit
                  timeout={1500}
                  style={{
                    transitionDelay: 1500,
                  }}
                >
                  <img
                    className="img-org"
                    src={" https://www.hanbiro.com/cloud/images/main-img01.png"}
                  ></img>
                </Slide>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
      

    </>
  );
}

export default NavButton;
