import React from 'react';
import NavButton from '../../homepage/header-banner/TopNavButton';
import ServerPricing from '../../homepage/header-banner/ServerPricing';
import Footer from '../../homepage/footer/Footer'
import Cloudconsult from '../../homepage/consult/cloudconsult';
import Banner from '../../container/Banner/Banner';
import {
    Container, Row, Col
} from 'react-bootstrap';
import Sidebar from '../../container/Sidebar/Sidebar'
// import '../../container/Sidebar/sidebar.css'
import Titlecloudcompute from "../../container/Card/Components/Titlecloudcompute"
import Contentcloudcard from "../../container/Card/Components/Contentcloudcard"
import Imagecloudcompute from "../../container/Card/Components/Imagecloudcompute"
import CardBoxRow from '../../container/Box/Components/CardBox-Row'
import { Grid } from '@mui/material';
import CommonTable from '../../container/Table/Components/CommonTable';
import CloudConsultCp from '../../homepage/consult/cloudconsult-cp';


// Titlecloudcompute
function MonitoringVolume() {
    return (
        <div className='App'>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#7061ff' },
                itemNavClass: "item-nav nav-pur",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            <Container className="main-body">
                <Row>
                    <Col md={12} lg={3}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={9} className="right-content">
                        {/* {Titlecloudcompute("모니터링•볼륨 구조")} */}
                        {/* {Contentcloudcard("고성능 Local SSD replication Volume 적용 안정적인 서비스운영","모든 인스턴스에 물리적인 rCPU (1배수) 적용, 고용량의 메모리제공")} */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} className='grid-card'>{CardBoxRow(
                                "Private Kubernetes 모니터링 상세구조",

                                `
                                <p style="margin:0;">Private Kubernetes 시스템은 Kubernetes 의 pod 상태를 실시간 모니터링하고 
                                    신속한 장애처리가 가능하도록 지원합니다.</p>
                                
                                <p style="margin:0;">모니터링 POD에 의해 수집된 데이터는 한비로 Object Storage에 일정 기간
                                    저장되며, 해당 데이터를 이용해 로그 및 각종 수치를 시각화하여 제공합니다.</p>
                                
                                <p style="margin:0;">POD 상태를 실시간 모니터링하여 장애 발생시 Push, Email 등을 이용하여
                                    엔지니어에게 전달하여 빠른 장애 처리를 수행 할 수 있게합니다.</p>

                                `)}</Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/back-office-2.png")}</Grid>

                            <Grid item xs={12} sm={12} className='grid-card'>{CardBoxRow(
                                "Private Kubernetes Persistant Volume 운영",

                                `
                                <p style="margin:0;">Private Kubernetes 기본시스템은 Local 볼륨을 사용합니다.</p>
                                
                                <p style="margin:0;">openEBS 의 Hostpath 볼륨엔진을 사용하여 고성능의 I/O 를 구현합니다.</p>
                                
                                <p style="margin:0;">클러스터 확장을 위한 스토리지는 Ceph 블록 스토리지를 지원합니다.</p>
                                `)}</Grid>
                            <Grid item xs={12} className='grid-card'>{Imagecloudcompute("https://hanbiro.com/assets/images/cloud/monitoring-volume-2.png")}</Grid>
                        </Grid>
                        <CloudConsultCp />
                    </Col>
                </Row>
            </Container>
            {/* <Cloudconsult /> */}
            <Footer option={{
                textColor: "text-color external-link text-pur-color",
                textlinkColor: "text-color text-pur-color"
            }} />
        </div>
    );
};

export default MonitoringVolume;