import React from 'react';
import TrafficFee from './TrafficFeeV1';
import './style.css';
import '../../Style/defaultstyle.css'
import '../../Style/defaultstyle.scss'

const ProtitleNew = (params) => {
  return (
    <table style={params.classNameWrapper}>
      <tr style={params.classNameItem}>
        <td style={params.PadProTitle}>
          <text style={StySpecification.ColorText} >{params.Title} </text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
          <text style={params.ConTitle}><span className='tabletext'>{params.Core} </span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span className='tabletext'>{params.G} </span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span className='tabletext'>{params.SD}</span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span className='tabletext'>{params.SG}</span></text>
        </td>
      </tr>
      <tr style={params.Tableborder}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span className='tabletext'>{params.data5}</span></text>
        </td>
      </tr>
      <tr style={params.Tableborderlast}>
        <td style={params.PadPro} >
        <text style={params.ConTitle}><span className='tabletext'>{params.data6}</span></text>
        </td>
      </tr>
    </table>
  )
}

const Protitle = (params) => {
  return (
    <table style={params.classNameWrapper}>
      <tr>
        <td style={params.PadProTable} >
          <text style={StySpecification.ColorText} >&nbsp;</text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
          <text style={params.ConTitle}><span className='tabletitle'>{params.Core} </span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span className='tabletitle'>{params.G} </span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span className='tabletitle'>{params.SD}</span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span className='tabletitle'>{params.SG}</span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span className='tabletitle'>{params.data5}</span></text>
        </td>
      </tr>
      <tr>
        <td style={params.PadProtit} >
        <text style={params.ConTitle}><span className='tabletitle'>{params.data6}</span></text>
        </td>
      </tr>
    </table>
  )
}

// const ProtitleNewtit = (params) => {
//   return (

//     <div style={params.classNameWrapper}>
//       <div style={params.classNameItem}>
//         <text style={StySpecification.ColorText} >{params.Title} </text>
//       </div>
//       <div>
//         <p><text style={params.ConTitle} ><span className='ngoctest'>{params.Core} </span></text></p>
//         <p><text style={params.ConTitle}><span className='ngoctest'>{params.G} </span></text></p>
//         <p><text style={params.ConTitle}><span className='ngoctest'>{params.SD}</span></text></p>
//         <p><text style={params.ConTitle}><span className='ngoctest'>{params.SG}</span></text></p>
//         <p><text style={params.ConTitle}><span className='ngoctest'>{params.data5}</span></text></p>
//         <p><text style={params.ConTitle}><span className='ngoctest'>{params.data6}</span></text></p>
//       </div>
//     </div>
//   )
// }

function ServerPricing() {
  return (
    <>
      <div style={StySpecification.StylesAll}>
        <div style={StySpecification.StylesContainer}>
          <div >
            <div >
              <h2 style={StySpecification.Title} className='header'>Kubernetes for Kafka 요금안내</h2>
              <p style={StySpecification.Text} >
                <text className='sub-title'>리플리카구성, Node 및 Shard 분산처리, 대용량 고가용성 분산 데이터 스트리밍 플랫폼</text>
              </p>
            </div>
          </div>
          <div style={StySpecification.TableScroll}>
            <div style={StySpecification.TableWrap}>
              <div style={StySpecification.ListProduct}>

                {Protitle({
                  classNameWrapper: StySpecification.InfPro,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTable: StySpecification.PadProTable,
                  PadProtit: StySpecification.PadProtit,
                  ConTitle: StySpecification.ConTitle,
                  // Title: "hi",
                  Core: "CPU",
                  G: "Memory",
                  SD: "Storage",
                  SG: "Traffic 전송량(월)",
                  data5: "가상서버(POD) 최소수량",
                  data6: "서비스비용(월)"

                })}

                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "스탠다드 상품",
                  Core: "1 Core",
                  G: "4G",
                  SD: "SSD 100G",
                  SG: "900G",
                  data5: "가상서버(POD) 4대, LB 1대",
                  data6: "90,000"

                })}

                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "비지니스",
                  Core: "2 Core",
                  G: "8G",
                  SD: "SSD 100G",
                  SG: "900G",
                  data5: "가상서버(POD) 4대, LB 1대",
                  data6: "160,000"

                })}
                {ProtitleNew({
                  classNameWrapper: StySpecification.InfProduct,
                  classNameItem: StySpecification.ProductTitle,
                  PadProTitle: StySpecification.PadProTitle,
                  Tableborder: StySpecification.Tableborder,
                  Tableborderlast: StySpecification.Tableborderlast,
                  Title: "엔터프라이즈형",
                  Core: "4 Core",
                  G: "16G",
                  SD: "SSD 100G",
                  SG: "900G",
                  data5: "가상서버(POD) 4대, LB 1대",
                  data6: "300,000"
                })}
              </div>
            </div>
          </div>
          <div style={StySpecification.TextContent} >

            <span >
              - 오토스케일링으로 확장된 클러스터는 추가된 컨테이너의 수량만큼 과금됩니다.<br />
              - 컨테이너 구성을 고객의 요청에 따라 별도로 지정할 수 있습니다.<br />
              <span style={StySpecification.geek}>
              - 다양한 방식으로 쿠베네티스 클러스터를 구축할 수 있습니다. (Cloud 구축컨설팅) <br />
              - 가상서버(POD) 5대이상의 자원으로 클러스터를 구성합니다.
              </span>
              <br /><br />
              예&#10089; [Zookeeper 노드, 0.5cpu 컨테이너 3개] + [Kafka 노드, 1cpu 컨테이너 3]<br />
              &#61;&#62;	 (17,500 원 x 1/2 x 3) + (17,500원 x 3) + 20,000원 = 98,750원 / 월<br />
            </span>

          </div>
          <div style={StySpecification.StylesCenter}></div>
          <TrafficFee />
        </div>

      </div>
    </>

  );
}


const StySpecification = {
  StylesAll: {
    display: "flex",
  },
  geek: {
    background: "yellow",
    fontWeight: "bold",
  },
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    width: "100%",
    borderRadius: "8px",
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  StylesCenter: {
    display: "flex",
    width: "100%",
  },
  StylesTitle: {
    textAlign: "center",
  },
  Title: {
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  Text: {
    fontWeight: 600,
    marginBottom: "30px",
  },
  ConTitle: {
    color: "black",
    // padding: "10px 0",
  },
  ColorText: {
    color: "white",
  },
  TextContent: {
    width: "75%",
    float: "right",
    textAlign: "left",
    paddingLeft: 15,
    marginTop: 15
  },
  ProductTitle: {
    background: "#393939",
    fontWeight: "bold",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    // paddingTop: 8,
    // paddingBottom: 8,

  },
  Tableborder: {
    borderLeft: "1px solid #D7E5ED",
    borderRight: "1px solid #D7E5ED",
  },
  Tableborderlast: {
    borderLeft: "1px solid #D7E5ED",
    borderRight: "1px solid #D7E5ED",
    borderBottom: "1px solid #D7E5ED",
  },
  ProTitleLeft: {
    fontWeight: 500,
    paddingTop: 8,
    paddingBottom: 8,
  },
  InfProduct: {
    borderRadius: "8px",
    width: "25%",
    marginLeft: 20,
    // border: "1px solid #D7E5ED",
    whiteSpace: "nowrap",
    minWidth: "170px",
  },
  InfPro: {
    borderRadius: "8px",
    width: "25%",
    marginRight: 10,
    textAlign: "right",
    fontWeight: 700,
    whiteSpace: "nowrap",
    minWidth: "130px",
  },

  ListProduct: {
    display: "flex",
  },
  PadPro: {
    padding: "12px 0",
  },
  PadProtit: {
    padding: "6px 0",
  },
  PadProTable: {
    padding: "10px 0",
  },
  PadProTitle: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    background: "unset",
  },
  TableScroll: {
    position: "relative",
    width: "100%",
    margin: "auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  TableWrap: {
    width: "100%",
    overflow: "auto",
  }

}





export default ServerPricing;
