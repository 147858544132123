import React from "react";
import '../../../Style/defaultstyle.scss'
import '../../CardButton/Components/CardButtonLeft-RightStyle.css'
import { Box } from '@mui/material'
import { ButtonToolbar } from '@/shared/components/Button';
import Button from '@mui/material/Button';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Link } from 'react-router-dom';


function CardButtonWhite($title1,$description1,$description2,$description3,$description4,$description5,$imagedata1,$title2,$description6,$imagedata2,$link1,$link2) {
  
    return (
      <div className='CardButtonWhite'>
            <Box className="data-content-box">  
                <div className="Card-White">
                    <div className="data-content">
                        <Box className="sub-title data-content-title">{$title1}</Box>
                        <Box className="data-content-description">{$description1}</Box>
                        <Box className="data-content-description">{$description2}</Box>
                        <Box className="data-content-description">{$description3}</Box>
                        <Box className="data-content-description">{$description4}</Box>
                        <Box className="data-content-description">{$description5}</Box>
                    </div>
                    <div className="img-data">
                        <img src={$imagedata1} alt="imagecloud"></img>
                    </div>
                </div>
                <ButtonToolbar className='ButtonLeft-White'>
                    <Button className='Buttonstyle'><Link to={$link1}>{$link2}<ChevronRightIcon size={30} className='iconButton' /></Link></Button>
                </ButtonToolbar>
            </Box>
            <Box className="data-content-box">  
                <div className="CardButton-White">
                    <div className="Card-White">
                        <div className="img-data">
                            <img src={$imagedata2} alt="imagecloud"></img>
                        </div>
                        <div className="data-content-right">
                            <Box className="sub-title data-content-title-right">{$title2}</Box>
                            <Box className="data-content-description-right">{$description6}</Box>
                        </div>
                    </div>
                    <ButtonToolbar className='ButtonRight'>
                        <Button className='Buttonstyle'><Link to="/cluster-cloud-mysql-db">가상서버(인스턴스) 요금 확인하기<ChevronRightIcon size={30} className='iconButton' /></Link></Button>
                    </ButtonToolbar>
                </div> 
            </Box>
      </div>
    );
};



export default CardButtonWhite;