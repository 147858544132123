import React, { useEffect, useLayoutEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavButton from '../homepage/header-banner/TopNavButton';
import Footer from '../homepage/footer/Footer';
import { Check } from '@mui/icons-material';
import { Search } from '@mui/icons-material';
import './assets/css/style-demo.css';
import { array } from "prop-types";
// import './assets/js/scrip-demo'

function IndexVi() {
    useLayoutEffect(() => {
        const titles = document.querySelectorAll(".accordion-title");
        const accordionItems = document.querySelectorAll(".accordion-item");
        const thumbScroll = document.querySelector(".thumb-scroll");
        const verticalLine = document.querySelector(".vertical-line");
        const imageDisplay = document.getElementById("displayed-image");



        function updateScrollThumb() {
            const activeItem = document.querySelector(".accordion-item.active");
            if (!activeItem || !thumbScroll || !verticalLine) return;

            const activeTitle = activeItem.querySelector(".accordion-title");
            const titleRect = activeTitle.getBoundingClientRect();
            const verticalLineRect = verticalLine.getBoundingClientRect();

            const newTop = titleRect.top - verticalLineRect.top + (activeTitle.clientHeight / 2) - (thumbScroll.clientHeight / 2);

            thumbScroll.style.top = `${newTop}px`;
        }

        const handleClick = function () {
            const item = this.parentElement;
            const content = item.querySelector(".accordion-content");

            if (!content || !imageDisplay) return;

            document.querySelectorAll(".accordion-content").forEach(c => c.style.display = "none");
            document.querySelectorAll(".accordion-item").forEach(i => i.classList.remove("active"));

            content.style.display = "block";
            item.classList.add("active");

            imageDisplay.src = item.getAttribute("data-image");
            imageDisplay.style.display = "block";

            updateScrollThumb();
        };

        titles.forEach(title => title.addEventListener("click", handleClick));

        updateScrollThumb();

        return () => {
            titles.forEach(title => title.removeEventListener("click", handleClick));
        };
    }, []);

    window.scrollTo(0, 0);

    return (
        <div>
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#47A7FF' },
                itemNavClass: "item-nav",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />

            {/* banner */}
            <div class="d-banner section-box">
                <div class="banner-block banner-text" data-aos="fade-right" data-aos-duration="1000">
                    <div>
                        <div class="vi-text-sz40 vi-white mb-4">Xây dựng và vận hành IT<br/>khó khăn?</div>
                        <div class="vi-text-sz16 vi-white">Hanbiro cung cấp các giải pháp phần mềm và kỹ thuật xây dựng 
                        <br/>
                        cơ sở hạ tầng tích hợp AI, đồng thời tối ưu hóa và tùy chỉnh các 
                        <br/>
                        giải pháp IT trên nền tảng đám mây.</div>
                    </div>

                    <div class="contact-us">
                        <button class="btn-more btn-sz">
                            <span class="vi">Bắt đầu</span>
                        </button>
                        <button class="contact-btn btn-sz light">
                            <span class="vi">Xem giá</span>
                        </button>
                    </div>
                </div>

                <div class="banner-block banner-infor" data-aos="fade-left" data-aos-duration="1000">
                    <div class="infor-list mb-4">
                        <div class="infor-text">
                            <div class="vi-text-sz16 vi-white mb-2"><span class="vi-text-bold">Xây dựng cơ sở hạ tầng trên nền tảng đám mây</span></div>
                            <div class="vi-text-sz16 vi-white">Tối ưu hoá và sắp xếp container dựa trên cấu trúc Kubernetes</div>
                        </div>
                        <div class="infor-image">
                            <img src="https://hanbiro.com/assets/images/cloud/image 608.png" />
                        </div>
                    </div>

                    <div class="infor-list mb-4">
                        <div class="infor-text">
                            <div class="vi-text-sz16 vi-white mb-2"><span class="vi-text-bold">Đội ngũ kỹ sư chuyên nghiệp</span></div>
                            <div class="vi-text-sz16 vi-white">Hỗ trợ vận hành hệ thống bao gồm Windows, Linux và Đám mây</div>
                        </div>
                        <div class="infor-image">
                            <img class="win" src="https://hanbiro.com/assets/images/cloud/image 609.png" />
                            <img src="https://hanbiro.com/assets/images/cloud/image 610.png" />
                        </div>
                    </div>

                    <div class="infor-list">
                        <div class="infor-text">
                            <div class="vi-text-sz16 vi-white mb-2"><span class="vi-text-bold">Phần mềm cộng tác tích hợp AI</span></div>
                            <div class="vi-text-sz16 vi-white">Cung cấp các gói giải pháp cộng tác thông minh và tự động hoá với LLM</div>
                        </div>
                        <div class="infor-image">
                            <img src="https://hanbiro.com/assets/images/cloud/image 614.png" />
                        </div>
                    </div>
                </div>
            </div>

            {/* contain */}

            <div class="contain">
                <div class="section-box collaboration">
                    <div class="collab-tt" data-aos="fade-up" data-aos-duration="1100">
                        <div class="vi-text-sz32 mb-4">Groupware</div>
                        <div class="vi-text-sz16">Các công cụ cộng tác của Hanbiro giúp bạn giao tiếp và làm việc nhóm hiệu quả hơn,
                                            <br/>đồng thời quản lý dự án một cách hợp lý. Giao diện thân thiện và các tính năng đa dạng
                                            <br/>giúp nâng cao trải nghiệm làm việc nhóm.</div>
                    </div>

                    <div class="collab-tab" data-aos="fade-up" data-aos-duration="1200">
                        <div className="tab-head">
                            <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="pills-collab1-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab1" type="button" role="tab" aria-controls="pills-collab1"
                                        aria-selected="true"><span class="vi">Email</span></button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab2-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab2" type="button" role="tab" aria-controls="pills-collab2"
                                        aria-selected="false"><span class="vi">HR</span></button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab3-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab3" type="button" role="tab" aria-controls="pills-collab3"
                                        aria-selected="false"><span class="vi">Tin nhắn</span></button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab4-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab4" type="button" role="tab" aria-controls="pills-collab4"
                                        aria-selected="false"><span class="vi">Phê duyệt</span></button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab5-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab5" type="button" role="tab" aria-controls="pills-collab5"
                                        aria-selected="false"><span class="vi">Dự án</span></button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="pills-collab6-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-collab6" type="button" role="tab" aria-controls="pills-collab6"
                                        aria-selected="false"><span class="vi">CRM</span></button>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content position-relative" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-collab1" role="tabpanel"
                                aria-labelledby="pills-collab1-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text-vi">
                                            <div class="vi-text-sz20 mb-2">Bộ lọc Email & Thư rác</div>
                                            <div class="vi-text-sz16 mb-4">Tiết kiệm chi phí với giải pháp chống thư rác được tích hợp sẵn trong phần mềm nhóm.</div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Hỗ trợ các giao thức SPF, DKIM, DMARC</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Sử dụng POP3/IMAP, chặn đến 95% thư rác</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Liên kết hộp thư bên ngoài, chia sẻ hộp thư nội bộ, và quản lý hộp thư của người nghỉ việc.</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Thư bảo mật, phê duyệt gửi thư, gửi nhận thư số lượng lớn</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <button class="contact-btn btn-sz">
                                                <span class="vi">Xem chi tiết</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/mail.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab2" role="tabpanel" aria-labelledby="pills-collab2-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text-vi">
                                            <div class="vi-text-sz20 mb-2">Quản lý chấm công & ngày nghỉ</div>
                                            <div class="vi-text-sz16 mb-4">Chấm công trên điện thoại bằng GPS, Wifi và Beacon.
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Phê duyệt thời gian chấm công</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Tải báo cáo hàng ngày/tuần/tháng</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Cập nhật trạng thái làm việc, nghỉ phép của nhân viên trên bảng điều khiển</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Tự động hoá quản lý ngày phép và quy đổi ngày phép</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <button class="contact-btn btn-sz">
                                                <span class="vi">Xem chi tiết</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/hr.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab3" role="tabpanel" aria-labelledby="pills-collab3-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text-vi">
                                            <div class="vi-text-sz20 mb-2">Tin nhắn</div>
                                            <div class="vi-text-sz16 mb-4">Giao tiếp liền mạch với đồng nghiệp trong nhóm làm việc.</div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Cung cấp chức năng hỗ trợ điều khiển máy tính từ xa.</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Tăng cường hiệu quả làm việc thông qua các tính năng chia sẻ màn hình, ghi âm tin nhắn và cuộc họp trực tuyến.</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Tổ chức cuộc họp trực tuyến với khách hàng một cách dễ dàng.</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <button class="contact-btn btn-sz">
                                            <span class="vi">Xem chi tiết</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/messenger.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab4" role="tabpanel" aria-labelledby="pills-collab4-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text-vi">
                                            <div class="vi-text-sz20 mb-2">Phê duyệt</div>
                                            <div class="vi-text-sz16 mb-4">Phê duyệt nhanh chóng mọi lúc mọi nơi trên PC và thiết bị di động, giúp công việc được xử lý hiệu quả hơn</div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Cung cấp hơn 300 mẫu phê duyệt cơ bản</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Tự động tính toán và xử lý công thức đơn giản với biểu mẫu excel</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">An toàn hơn với các thanh toán bảo mật</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Tùy chỉnh dòng phê duyệt theo biểu mẫu, hoặc lưu lại các dòng phê duyệt thường dùng</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16">Hỗ trợ tích hợp ERP phê duyệt điện tử</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <button class="contact-btn btn-sz">
                                                <span class="vi">Xem chi tiết</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/approval.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab5" role="tabpanel" aria-labelledby="pills-collab5-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text-vi">
                                            <div class="vi-text-sz20 mb-2">Quản lý dự án</div>
                                            <div class="vi-text-sz16 mb-4">Tạo mô hình làm việc phù hợp với công ty của bạn, chỉ định người phụ trách công việc 
                                                và ghi lại tiến độ để quản lý công việc một cách có hệ thống.</div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16"><b>Nâng cao hiệu quả công việc:</b> Cập nhật tiến độ và phản hồi
                                                                    theo thời gian thực giúp các thành viên trong nhóm làm việc 
                                                                    hiệu quả hơn, tránh trùng lặp nhiệm vụ và đẩy nhanh tiến độ dự án </div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16"><b>Tiến độ công việc minh bạch:</b> Tiến độ công việc của từng
                                                                thành viên và dự án được hiển thị rõ ràng, giúp mọi người dễ
                                                                dàng nắm bắt tình hình và phối hợp nhịp nhàng, nâng cao
                                                                hiệu quả làm việc nhóm</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16"><b>Dễ dàng phân công trách nhiệm và quản lý:</b> Mỗi nhiệm vụ
                                                                được chỉ định cụ thể cho từng người, giúp quản lý công việc
                                                                và trách nhiệm một cách minh bạch</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16"><b>Tập trung hóa tài liệu:</b> Tất cả tài liệu và tập tin liên quan
                                                                đến công việc được tập trung lưu trữ tại một nơi, giúp bạn
                                                                dễ dàng truy cập và chia sẻ khi cần</div>
                                            </div>
                                            <div class="block-li">
                                                <Check className="check" />
                                                <div class="vi-text-sz16"><b>Quản lý nguồn lực thông minh:</b> Nắm bắt khối lượng công
                                                                việc của từng thành viên, phân bổ công việc hợp lý, tránh
                                                                tình trạng quá tải và đảm bảo hiệu suất tối ưu</div>
                                            </div>
                                        </div>
                                        <div class="contact-us">
                                            <button class="contact-btn btn-sz">
                                                <span class="vi">Xem chi tiết</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/project.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-collab6" role="tabpanel" aria-labelledby="pills-collab6-tab">
                                <div class="row block-content">
                                    <div class="col-lg-6">
                                        <div class="d-block-text-vi">
                                            <div class="vi-text-sz20 mb-2">HelpDesk</div>
                                            <div class="vi-text-sz16 mb-4">Quản lý tập trung các yêu cầu hỗ trợ từ nhiều kênh khác nhau tại
                                                                    một trang duy nhất. Theo dõi và hỗ trợ toàn diện mọi quy trình, từ
                                                                    khi tiếp nhận đến khi phản hồi cuối cùng được gửi đến khách hàng.</div>

                                            <div class="vi-text-sz20 mb-2">CRM call</div>
                                            <div class="vi-text-sz16 mb-4">Lưu trữ và phân tích lịch sử cuộc gọi với khách hàng. Cải thiện chất
                                                                    lượng dịch vụ dựa trên thông tin và lịch sử cuộc gọi trước đó.</div>
                                        </div>
                                        <div class="contact-us">
                                            <button class="contact-btn btn-sz">
                                                <span class="vi">Xem chi tiết</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 block-content-top">
                                        <div class="bg-image">
                                            <div class="image-box">
                                                <img src="https://hanbiro.com/assets/images/cloud/crm.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-box background ai-block">
                    <div class="collab-tt mb-5">
                        <div class="vi-text-sz32 mb-4" data-aos="fade-up" data-aos-duration="1100" data-aos-offset="50">
                            Phần mềm cộng tác thông minh với AI
                        </div>
                        <div class="vi-text-sz16" data-aos="fade-up" data-aos-duration="1200" data-aos-offset="50">
                            Nâng cao hiệu quả làm việc nhóm với giải pháp cộng tác AI 
                            được phát triển dựa trên công nghệ LLM mới nhất
                        </div>
                    </div>

                    <div class="row ai-block-row" data-aos="fade-up" data-aos-duration="1300" data-aos-offset="50">
                        <div class="col-lg-4">
                            <div class="ai-box">
                                <div class="vi-text-sz20 mb-3">
                                    Quản lý Email thông minh
                                </div>
                                <div class="vi-text-sz16">
                                    Hỗ trợ các tính năng AI như tóm tắt nội dung, 
                                    dịch thuật, và tự động lọc thư rác
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ai-box">
                                <div class="vi-text-sz20 mb-3">
                                    Quản lý khách hàng
                                </div>
                                <div class="vi-text-sz16">
                                    Xử lý và quản lý các yêu cầu hỗ trợ của khách hàng
                                    thông qua HelpDesk & CRM Call
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="ai-box">
                                <div class="vi-text-sz20 mb-3">
                                    Giao tiếp nội bộ
                                </div>
                                <div class="vi-text-sz16">
                                    Phần mềm nhắn tin nội bộ 
                                    và cuộc họp trực tuyến (online meeting)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-box infrastructure">
                    <div class="row infra-block" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="50">
                        <div class="col-lg-5 infra-image">
                            <div className="image-hover">
                                <img src="https://hanbiro.com/assets/images/cloud/system-container.jpg" width={490} />
                            </div>
                        </div>
                        <div class="col-lg-7 infra-text">
                            <div class="vi-text-sz24 mb-3">
                                Cơ sở hạ tầng container ổn định
                            </div>
                            <div class="vi-text-sz14 mb-4">
                                Xây dựng môi trường container dựa trên Private Cloud với công nghệ Kubernetes mới
                                nhất. Bảo mật và vận hành các dịch vụ của bạn thông qua cơ sở hạ tầng linh hoạt và có
                                khả năng mở rộng.
                            </div>

                            <div class="d-block-text">
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Tối ưu chi phí:</b> Giảm chi phí sử dụng container bằng cách khai thác tối đa tài
                                        nguyên từ đám mây.
                                    </div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Tư vấn chuyên nghiệp:</b> Tư vấn cấu hình và phát triển hệ thống cho các dịch vụ
                                        Microservices.
                                    </div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Hỗ trợ toàn diện:</b> Hỗ trợ xây dựng và vận hành nền tảng đám mây độc lập với chi
                                        phí tối ưu.
                                    </div>
                                </div>
                            </div>

                            <div class="contact-us">
                                <button class="read-more"><span class="vi">Xem thêm</span></button>
                            </div>
                        </div>
                    </div>

                    <div class="row infra-block" data-aos="fade-up" data-aos-duration="1400" data-aos-offset="50">
                        <div class="col-lg-5 infra-image">
                            <div className="image-hover">
                                <img src="https://hanbiro.com/assets/images/cloud/system-engineer-team.jpg" width={490} />
                            </div>
                        </div>
                        <div class="col-lg-7 infra-text">
                            <div class="vi-text-sz24 mb-3">
                                Đội ngũ kỹ sư chuyên nghiệp</div>
                            <div class="vi-text-sz14 mb-4">
                                Chúng tôi cung cấp dịch vụ hỗ trợ kỹ thuật chuyên sâu, được thực hiện bởi đội ngũ kỹ sư
                                giàu kinh nghiệm, trên nhiều hệ điều hành khác nhau, bao gồm Windows và Linux. Đảm
                                bảo hệ thống của bạn hoạt động ổn định từ môi trường đám mây đến các hệ thống hiện
                                tại, giúp doanh nghiệp giảm thiểu gánh nặng vận hành IT
                            </div>

                            <div class="d-block-text">
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Tối ưu hóa máy chủ và điều chỉnh hiệu suất:</b>Phân tích khối lượng công việc của
                                        ứng dụng để tối ưu hóa CPU, bộ nhớ và tài nguyên mạng nhằm đảm bảo hiệu suất
                                        hoạt động ổn định.
                                    </div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Cập nhật về bảo mật và hệ thống: </b>  Ngăn ngừa lỗ hổng bảo mật bằng cách thường
                                        xuyên cập nhật các bản vá lỗi hệ điều hành và phần mềm, chặn đứng các mối đe
                                        dọa bảo mật mới nhất.
                                    </div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Giám sát thời gian thực và phản hồi lỗi:</b> Giám sát hiệu suất máy chủ và mạng
                                        theo thời gian thực, cung cấp phân tích nguyên nhân lỗi và hỗ trợ sửa lỗi tức thì.
                                    </div>
                                </div>
                                <div class="block-li">
                                    <Check className="check" />
                                    <div class="vi-text-sz16">
                                        <b>Thiết lập hệ thống sao lưu và phục hồi dữ liệu:</b>Sao lưu dữ liệu quan trọng
                                        thường xuyên và vận hành quy trình phục hồi có hệ thống, đảm bảo phục hồi
                                        nhanh chóng trong trường hợp khẩn cấp.
                                    </div>
                                </div>
                            </div>

                            <div class="contact-us">
                                <button class="read-more"><span class="vi">Xem thêm</span></button>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="section-box ai dark-block">
                    <div class="collab-tt mb-5">
                        <div class="vi-text-sz32 vi-white">AI hỗ trợ cộng tác như thế nào?</div>
                    </div>

                    <div class="row block-content">
                        <div class="col-lg-6 image-display">
                            <div class="ai-image">
                                <div class="image-box-ai" data-aos="zoom-in" data-aos-duration="800">
                                    <img id="displayed-image" src="https://hanbiro.com/assets/images/cloud/image 615.png" />
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 accordion">
                            <div className="vertical-line">
                                <div className="thumb-scroll"></div>
                            </div>
                            <div>
                                <div class="accordion-item active" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit vi">Email</div>Quản lý Email thông minh</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• <span class="vi-text-bold">Bảo vệ toàn diện:</span> Bộ lọc thư rác thông minh kết hợp AI giúp phát hiện và ngăn chặn các mối đe dọa tiềm ẩn.</span><br />
                                        <span>• <span class="vi-text-bold">Tiết kiệm thời gian:</span> Tóm tắt và dịch thuật nội dung email tự động ngay trên trang danh sách và nội dung.</span><br />
                                        <span>• <span class="vi-text-bold">Soạn thảo thông minh:</span> Gợi ý từ khóa và hỗ trợ soạn thảo giúp tối ưu hóa hiệu quả viết email</span>
                                    </div>
                                </div>
                                <div class="accordion-item" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit vi">CRM Call</div>Quản lý cuộc gọi thông minh</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• <span class="vi-text-bold">Phân tích cuộc gọi chuyên sâu:</span> Tối ưu hóa hỗ trợ khách hàng bằng cách phân tích lịch sử cuộc gọi theo thời gian thực với AI.</span><br />
                                        <span>• <span class="vi-text-bold">Chuyển đổi và tóm tắt cuộc gọi tự động:</span> Chuyển đổi giọng nói thành văn bản, tóm tắt nội dung và dịch thuật đa ngôn ngữ.</span><br />
                                        <span>• <span class="vi-text-bold">Marketing hiệu quả:</span> Xây dựng chiến dịch marketing cá nhân hóa dựa trên phân tích dữ liệu phản hồi của khách hàng.</span><br />
                                        <span>** <b>Yêu cầu bổ sung:</b> Hệ thống ghi âm chuyên dụng hoặc Raspberry Pi do Hanbiro cung cấp.</span>
                                    </div>
                                </div>
                                <div class="accordion-item" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit vi">Help Desk</div>Quản lý yêu cầu hỗ trợ thông minh</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• <span class="vi-text-bold">Quản lý tập trung:</span>Theo dõi và quản lý yêu cầu từ nhiều kênh (email, cuộc gọi) để cải thiện tốc độ xử lý.</span><br />
                                        <span>• <span class="vi-text-bold">Phản hồi tự động:</span>Tối đa hóa hiệu quả thông qua phản hồi thông minh và chuyển giao công việc đến người phụ trách tự động thông qua mô hình LLM</span>
                                    </div>
                                </div>
                                <div class="accordion-item" data-image="https://hanbiro.com/assets/images/cloud/image 615.png">
                                    <div class="accordion-title text-sz20"><div className="accordion-tit vi">Tin Nhắn</div>Giao tiếp thông minh</div>
                                    <div class="accordion-content text-sz16">
                                        <span>• <span class="vi-text-bold">Dịch thuật và tóm tắt hội thoại:</span> Tự động dịch tin nhắn gửi đi và tóm tắt nội dung hội thoại tự động, hỗ trợ giao tiếp đa ngôn ngữ dành riêng cho các công ty đa quốc gia.</span><br />
                                        <span>• <span class="vi-text-bold">Biên bản cuộc họp tự động:</span> Cải thiện hiệu quả giao tiếp với tính năng cuộc họp trực tuyến và tự động tạo biên bản cuộc họp thông qua chức năng nhận dạng giọng nói.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="section-box partner" id="ourcustomer">
                    <div class="partner-head">
                        <div class="vi-text-sz32 mb-4">Khách hàng của Hanbiro</div>
                        <div class="vi-text-sz16">Những khách hàng đã và đang đồng hành cùng chúng tôi</div>
                    </div>

                    <div class="partner-dt">
                        <div class="partner-ct">
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/365MC.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/CJ_4DPLEX.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/FNC로고.png" />
                                    <div></div>
                                </div>
                            </div>
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/JYP.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/KGL.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/tym.png" />
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="partner-ct">
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/강원소방본부.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/금강종합건설.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/대두식품.png" />
                                    <div></div>
                                </div>
                            </div>
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/도이치모터스.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/돌코리아.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/동보항공.png" />
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="partner-ct">
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/비에이치.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/새빛안과병원.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/아이오베드.png" />
                                    <div></div>
                                </div>
                            </div>
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/원광디지털대학교.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/인피니트헬스케어.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/잇츠한불.png" />
                                    <div></div>
                                </div>
                            </div>
                        </div>

                        <div class="partner-ct">
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/카페베네.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/케이피항공산업.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/코스메카코리아.png" />
                                    <div></div>
                                </div>
                            </div>
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/크라운.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/토니모리.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/한국건설.png" />
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div class="partner-ct">
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/한국기능공사.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/한국지역정보개발원.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/한전kps.png" />
                                    <div></div>
                                </div>
                            </div>
                            <div class="partner-box">
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/해피랜드.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/현대LGN해운.png" />
                                    <div></div>
                                </div>
                                <div class="partner-item">
                                    <img src="https://hanbiro.com/groupware/assets/img/partner/휴림로봇.png" />
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    )
}

export default IndexVi;