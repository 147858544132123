

import React from 'react';
import TrafficFeeV1 from './TrafficFeeV1';
import '../../Style/defaultstyle.css'
import '../../Style/defaultstyle.scss'

function TrafficFee({ title }) {
  return (
    <>
      <div >
        <div style={StySpecification.StylesContainer}>
          <div >
            <div >
              <h2 style={StySpecification.Title} className='header'>{title}</h2>
            </div>
          </div>
          <div style={StySpecification.TableScroll}>
            <div style={StySpecification.TableWrap}>
              <div style={StySpecification.ListProduct}>
                <table width="100%">
                  <tbody >
                    <tr style={StySpecification.Header} >
                      <td width="22%" style={StySpecification.BorLeft}><div style={StySpecification.TextTitle}><b className='sub-title'>구분</b></div></td>
                      <td width="17%" ><b className='sub-title'>최소볼륨	</b></td>
                      <td width="17%" ><b className='sub-title'>최대볼륨	</b></td>
                      <td width="22%" ><b className='sub-title'>서비스비용(일)	</b></td>
                      <td width="22%" style={StySpecification.BorRight}><b className='sub-title'>서비스비용(월)</b></td>
                    </tr>
                    <tr>
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}  >블록 Storage</div> </td>
                      <td style={StySpecification.BorAll}><span  >50G	</span></td>
                      <td style={StySpecification.BorAll}><span  >2T	</span></td>
                      <td style={StySpecification.BorAll}><span  >1.5원 (1G)</span></td>
                      <td style={StySpecification.BorAll}><span  >45원 (1G)</span></td>
                    </tr>
                    <tr>
                      <td style={StySpecification.TextLeft}><div style={StySpecification.TextContent}  >트래픽	</div> </td>
                      <td style={StySpecification.BorAll} colSpan={2}><span  >-	</span>	</td>

                      <td style={StySpecification.BorAll} colSpan={2}><span  >무료</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div style={StySpecification.StylesCenter}></div>
          <TrafficFeeV1/>
        </div>
      </div>
    </>

  );
}


const StySpecification = {
  StylesContainer: {
    boxShadow: "0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%)",
    border: "1px solid #E7EAF3",
    borderRadius: "8px",
    padding: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  StylesCenter: {
    display: "flex",
    width: "100%",
  },

  Title: {
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  Header: {
    color: "white",
    background: "#393939",
  },
  TextTitle: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  TextContent: {
    paddingTop: 7,
    paddingBottom: 7,
  },
  BorLeft: {
    borderTopLeftRadius: "8px",
    borderRight: "2px solid #F4F4F4",
  },
  BorRight: {
    borderTopRightRadius: "8px",
    borderLeft: "2px solid #F4F4F4",
  },
  BorAll: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
  },
  TextLeft: {
    borderLeft: "2px solid #DDDDDD",
    borderRight: "2px solid #DDDDDD",
    borderBottom: "2px solid #DDDDDD",
    fontWeight: 900,
    textAlign: "left",
    paddingLeft: "10px"
  },
  TableScroll:{
    position:"relative",
    width: "100%",
    margin:"auto",
    overflow:"hidden", 
    whiteSpace: "nowrap",
  },
  TableWrap:{
    width:"100%",
    overflow:"auto",
  }
}


export default TrafficFee;
