import React from 'react'
import Footer from '../../homepage/footer/Footer';
import NavButton from '../../homepage/header-banner/TopNavButton';
import '../assets/css/style-demo.css';

function CrmCall() {
    return (
        <div class="container-crmcall">
            <NavButton option={{
                // logo: logoKube,
                logo: "https://hanbiro.com/cloud/library/banner/hanbiro.svg",
                link: "https://hanbiro.com/",
                color: { backgroundColor: '#47A7FF' },
                itemNavClass: "item-nav",
                menuListProduct: "menu-list-product-item",
                menuListVora: "vora",
                menuListGroupware: "groupware",
                titleTab: "mb-3",
            }} />
            <div class="crmcall-landing-page">
                <div class="crmcall-herro-section">
                    <div class="crmcall-herro-section-content">
                        <div class="crmcall-herro-section-header">
                            <div class="crm-call">CRM call 이란 ?</div>
                            <div class="crm-call-ai">
                                한비로 CRM Call은 고객과 이루어진 모든 통화 이력을 저장 · 관리할 수
                                있는 솔루션입니다.
                                <br />
                                여기에 AI 솔루션을 추가하여 , 통화이력을 텍스트로 추출하고 이를 번역
                                및 요약하는 기능을 제공합니다.
                            </div>
                        </div>
                        <div class="crmcall-herro-section-button">
                            <div class="crmcall-get-started">
                                <div class="primary-700-14">Get Started</div>
                            </div>
                            <div class="crmcall-learn-more">
                                <div class="white-400-14">Learn more</div>
                            </div>
                        </div>
                    </div>
                    <img class="crmcall-concept" src="https://hanbiro.com/assets/images/cloud/crmcall-concept-1.png" />
                </div>
                <did class="crmcall-intro-wrapper">
                    <div class="crmcall-intro">
                        <div class="crmcall-intro-header">
                            <div class="primary-40-700">CRM call 장점</div>
                        </div>
                        <div class="crmcall-intro-content">
                            <div class="crmcall-intro-left-content">
                                <div class="crmcall-accordion">
                                    <div class="crmcall-accordion-header" id="collapsible-btn">
                                        <img class="ai-icon" src="https://hanbiro.com/assets/images/cloud/ai-icon.png" />
                                        <div class="primary-600-24">통화 내용 텍스트 변환</div>                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.79289 14.7071C8.18342 15.0976 8.81658 15.0976 9.20711 14.7071L12 11.9142L14.7929 14.7071C15.1834 15.0976 15.8166 15.0976 16.2071 14.7071C16.5976 14.3166 16.5976 13.6834 16.2071 13.2929L12.7071 9.79289C12.3166 9.40237 11.6834 9.40237 11.2929 9.79289L7.79289 13.2929C7.40237 13.6834 7.40237 14.3166 7.79289 14.7071Z" fill="#606060"/>
                                        <script xmlns=""/></svg>         
                                    </div>
                                    <div class="crmcall-accordion-content" id="collapsible-content">
                                        <div class="li">
                                            <div class="secondary-400-16">
                                                <ul class="secondary-400-16-span">
                                                    <li>
                                                    AI가 고객과의 통화 녹취 내용을 텍스트로 변환하므로,
                                                    담당자가 음성 파일을 수동으로
                                                    확인할 필요가 없습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="li">
                                            <div class="secondary-400-16">
                                                <ul class="secondary-400-16-span">
                                                    <li>
                                                    통화 내용을 정확하게 기록하여 실수를 방지하고, 중요한
                                                    데이터를 놓치지 않도록 합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="li">
                                            <div class="secondary-400-16">
                                                <ul class="secondary-400-16-span">
                                                    <li>
                                                    텍스트로 변환된 내용은 검색과 필터링이 용이해져, 후속
                                                    업무에서 필요한 정보를 손쉽게
                                                    확보합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="crmcall-accordion">
                                    <div class="crmcall-accordion-header">
                                        <div class="primary-600-24">통화 내용 번역</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z" fill="#606060"/>
                                        <script xmlns=""/></svg>
                                    </div>
                                </div>
                                <div class="crmcall-accordion">
                                    <div class="crmcall-accordion-header">
                                        <div class="primary-600-24">통화 내용 요약</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z" fill="#606060"/>
                                        <script xmlns=""/></svg>
                                    </div>
                                </div>
                            </div>
                            <img class="ai-call" src="https://hanbiro.com/assets/images/cloud/ai-call.png" />
                        </div>
                    </div>
                </did>
                <div  class="crmcall-feature">
                    <div class="crmcall-feature-header">
                        <div class="primary-700-40">CRM call features</div>
                        <div class="crmcall-feature-button">
                            <div class="crmcall-request-demo">
                                <div class="white-700-14">Request a Demo</div>
                            </div>
                            <div class="crmcall-talk-sales">
                                <div class="primary-400-14">Talk to Sales</div>
                            </div>
                        </div>
                    </div>
                    <div class="crmcall-feature-content">
                        <div class="crmcall-feature-card">
                            <div class="crmcall-feature-info">
                                <div class="crmcall-feature-info-content">
                                    <div class="primary-600-24">고객 정보 미리 확인</div>
                                    <div class="divider"></div>
                                    <div class="crmcall-feature-info-mid-content">
                                        <ul class="secondary-400-18">
                                            <li>
                                            전화 수 · 발신 시 담당자의 PC 팝업 창에서 CRM에 저장된
                                            <br />
                                                고객의 간단 정보 및 과거 통화 이력을 미리 확인합니다.
                                            </li>
                                        </ul>
                                        <ul class="secondary-400-18">
                                            <li>
                                            실시간으로 고객의 정보에 접근하여, 불필요한 질문을
                                            줄이고
                                            <br />
                                            맞춤형 서비스를 제공합니다.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <img class="incoming-call" src="https://hanbiro.com/assets/images/cloud/incoming-call.png" />
                            </div>
                        </div>
                        <div class="crmcall-feature-card">
                            <div class="crmcall-feature-info-reverse">
                                <img class="call-history" src="https://hanbiro.com/assets/images/cloud/call-history.png" />
                                <div class="crmcall-feature-info-content">
                                    <div class="primary-600-24">고객별 실시간 이력 저장 · 관리</div>
                                    <div class="divider"></div>
                                    <div class="crmcall-feature-info-mid-content">
                                        <ul class="secondary-400-18">
                                            <li>
                                            모든 통화 내역과 메모는 고객별로 자동 분류하여
                                            <br />
                                            실시간으로 저장됩니다.
                                            </li>
                                        </ul>
                                        <ul class="secondary-400-18">
                                            <li>
                                            녹취 파일은 모바일 앱과 웹에서 확인 및 다운로드 할 수
                                            있습니다.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="crmcall-feature-card">
                            <div class="crmcall-feature-info">
                                <div class="crmcall-feature-info-content">
                                    <div class="primary-600-24">고객 전화번호 자동 수집</div>
                                    <div class="divider"></div>
                                    <div class="crmcall-feature-info-mid-content">
                                        <ul class="secondary-400-18">
                                            <li>
                                            PC 팝업 창에서 미등록된 고객의 전화번호를 확인하고, 기존
                                            고객의 연락처를 업데이트 합니다.
                                            </li>
                                        </ul>
                                        <ul class="secondary-400-18">
                                            <li>
                                            신규 고객 확보 및 기존 고객의 연락처가 최신 상태로
                                            업데이트 됩니다.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <img class="call-log" src="https://hanbiro.com/assets/images/cloud/call-log.png" />
                            </div>
                        </div>
                        <div class="crmcall-feature-card">
                            <div class="crmcall-feature-info-reverse">
                                <img class="missed-call" src="https://hanbiro.com/assets/images/cloud/missed-call.png" />
                                <div class="crmcall-feature-info-content">
                                    <div class="primary-600-24">부재중 통화 관리</div>
                                    <div class="divider"></div>
                                    <div class="crmcall-feature-info-mid-content">
                                        <ul class="secondary-400-18">
                                            <li>
                                            담당자 부재 시 발생한 부재중 통화 이력을 저장하고
                                            관리하여, 고객 응대 누락을 최소화할 수 있습니다.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <Footer/>
        </div>
        

        
    )
}

export default CrmCall;